import classNames from "classnames";

type TModalBackdrop = {
  isVisible: boolean;
};

const ModalBackdrop = ({ isVisible }: TModalBackdrop) => {
  return (
    <div
      className={classNames(
        "transition fixed inset-0 z-30 bg-gray-900 bg-opacity-50 hs-overlay-backdrop",
        {
          " hidden": !isVisible,
          " open": isVisible,
        }
      )}
    />
  );
};

export default ModalBackdrop;
