import { Sidebar } from "@/layouts";
import { Switch } from "@/components";
import DefaultLine from "./defaultLine";
import DefaultLabel from "./defaultLabel";

type TSwitchLine = {
  id: number;
  label: string;
  isLoading?: boolean;
  value?: boolean;
  onChange?: ({ target }: { target: HTMLInputElement }) => void;
};

const SwitchLine = ({
  id,
  label,
  isLoading,
  value = false,
  onChange,
}: TSwitchLine) => (
  <DefaultLine>
    <>
      <DefaultLabel isLoading={isLoading} text={label} />
      <div className="-mt-4">
        <Switch
          checked={value}
          disabled={isLoading}
          id={`${id}`}
          onChange={onChange}
        />
      </div>
    </>
  </DefaultLine>
);

export default SwitchLine;
