import {
  Header,
  Footer,
  Content,
  HeaderSection,
  Sidebar as Menu,
} from "@/structures";
import Container from "@/layouts/Container";
import { TBreadcrumbData } from "@/components/Navigations/Breadcrumb";

type TDefault = {
  isLoading: boolean;
  isAdmin: boolean;
  debug?: boolean;
  form?: JSX.Element | JSX.Element[];
  content?: JSX.Element | JSX.Element[];
  title: string;
  formSubtitle: string;
  breadcrumbData?: TBreadcrumbData[];
  contentSubtitle: string;
};

const FormWithContent = ({
  isLoading,
  isAdmin,
  debug = false,
  title,
  formSubtitle,
  contentSubtitle,
  form,
  content,
  breadcrumbData,
}: TDefault) => {
  return (
    <Container>
      <Header debug={debug} />

      <Content>
        <div className="flex h-full">
          <Menu />
          <div className="layout-with-menu-container py-4 px-4 sm:px-6 lg:px-8">
            <HeaderSection
              isLoading={isLoading}
              align="left"
              title={title}
              subtitle={formSubtitle}
              breadcrumbData={breadcrumbData}
            />
            {isAdmin && (
              <div className="border-b border-gray-300 pb-5">{form}</div>
            )}
            <div className="pt-5 md:text-lg text-tertiary">
              {isLoading ? (
                <div className="skeleton w-1/2 h-5" />
              ) : (
                contentSubtitle
              )}
            </div>
            <div className="pb-5">{content}</div>
          </div>
        </div>
      </Content>

      <Footer />
    </Container>
  );
};

export default FormWithContent;
