type TUseHTTPSprotocol = {
  isHttps: boolean
  apiUrl: string
  siteLocation: string
  isProd: boolean
}

const useHTTPSprotocol = () => {
  const location = window.location.href.split("//");
  const protocol = location[0];

  const isProd = !import.meta.env.DEV;
  const domain = import.meta.env.VITE_API_URL;

  const isHttps = protocol === "https:";
  const port = isHttps ? `8443` : `3333`;

  const apiUrl = `${protocol}//${domain}:${port}`;
  const siteLocation = `https://${location[1]}`;

  return { isHttps, apiUrl, isProd, siteLocation } as TUseHTTPSprotocol;
}

export default useHTTPSprotocol;