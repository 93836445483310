import { useFormikContext } from "formik";

import { onlyNumbers } from "@/utils";
import { Input, Select } from "@/components";
import { monthOptions, frequencyOptions } from "@/utils/enuns";
import SaveContainer from "./saveContainer";
import { TSchema } from "./schema";

const BatchRegistration = ({ isLoading }: { isLoading: boolean }) => {
  const { values, setFieldValue, isSubmitting, errors } =
    useFormikContext<TSchema>();
  const { flagBatchRequest } = values;

  return (
    <>
      {flagBatchRequest && (
        <>
          <h3 className="font-semibold text-primary py-5">Cadastro em Lote</h3>
          <div className="pt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-x-5">
            <>
              <Input
                required
                type="number"
                label="Quantidade"
                isLoading={isLoading}
                value={values.quantidade as number}
                error={isSubmitting && !!errors?.quantidade}
                onChange={({ target }) =>
                  setFieldValue("quantidade", Number(target.value))
                }
              />
              <Input
                required
                label="Preço"
                type="number"
                isLoading={isLoading}
                value={values.preco as number}
                error={isSubmitting && !!errors?.preco}
                onChange={({ target }) =>
                  setFieldValue("preco", onlyNumbers(target.value))
                }
              />
              <Input
                required
                type="number"
                label="Páginas"
                isLoading={isLoading}
                value={values.paginas as number}
                error={isSubmitting && !!errors?.paginas}
                onChange={({ target }) =>
                  setFieldValue("paginas", onlyNumbers(target.value))
                }
              />
              <Select
                required
                label="Mês"
                isLoading={isLoading}
                options={monthOptions?.map(({ id, label }) => ({
                  label,
                  value: id < 10 ? `0${id}` : `${id}`,
                }))}
                value={values.mes as string}
                error={isSubmitting && !!errors?.mes}
                onChange={({ target }) => setFieldValue("mes", target.value)}
              />
              <Input
                required
                label="Ano"
                maxLength={4}
                isLoading={isLoading}
                value={values.ano as string}
                error={isSubmitting && !!errors?.ano}
                onChange={({ target }) =>
                  setFieldValue("ano", onlyNumbers(target.value))
                }
              />
              <Select
                required
                label="Periodicidade"
                isLoading={isLoading}
                options={frequencyOptions?.map(({ id, label }) => ({
                  label,
                  value: id.toString(),
                }))}
                value={values.periodicidade as number}
                error={isSubmitting && !!errors?.periodicidade}
                onChange={({ target }) =>
                  setFieldValue("periodicidade", Number(target.value))
                }
              />
            </>
            <SaveContainer type="batch" isLoading={isLoading} />
          </div>
        </>
      )}
    </>
  );
};

export default BatchRegistration;
