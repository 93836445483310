import { useFormikContext } from "formik";

import { getUserInfoHooks } from "@/hooks/Auth";
import { isObjectEmpty } from "@/utils";
import { Button } from "@/components";
import { useToast } from "@/hooks";
import { TSchema } from "./schema";

type TSaveContainer = {
  isLoading: boolean;
};

const SaveContainer = ({ isLoading }: TSaveContainer) => {
  const { config } = getUserInfoHooks();

  const {
    resetForm,
    setFieldValue,
    validateForm,
    handleSubmit,
    setSubmitting,
  } = useFormikContext<TSchema>();

  const handleSave = async () => {
    setSubmitting(true);
    const error = await validateForm();

    if (isObjectEmpty(error)) handleSubmit();
    else
      useToast({
        type: "error",
        msg: "Favor checar os campos obrigatórios.",
        time: 3000,
        showNotification: config.geral.exibirNotificacoes,
      });
  };

  const handleClose = () => {
    resetForm();
    setFieldValue("flagBugfix", false);
    setFieldValue("flagSugestions", false);
    setFieldValue("flagNewManga", false);
  };

  return (
    <div className="flex items-center justify-end gap-x-5 py-0 col-span-2 sm:col-span-3 md:col-span-2 xl:col-span-6">
      <Button
        isLoading={isLoading}
        small
        label="Cancelar"
        outline
        onClick={handleClose}
      />
      <Button isLoading={isLoading} small label="Salvar" onClick={handleSave} />
    </div>
  );
};

export default SaveContainer;
