import { useSelector } from "react-redux";
import { getAllTabelas } from "@/store/Tabelas/selectors";

export const getFaixaEtariaNameHooks = (faixaEtariaId: number): string => {
  const { allFaixasEtarias } = useSelector(getAllTabelas);
  
  const FaixaEtaria = allFaixasEtarias?.find(({ id }) => Number(faixaEtariaId) === id)?.nome;

  return FaixaEtaria ?? '';
};
