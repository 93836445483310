import { useFormikContext } from "formik";
import { Select, Button } from "@/components";
import { getAllArtistasHooks } from "@/hooks/Tabelas";
import { TSchema } from "./schema";

const StepC = ({ isLoading }: { isLoading: boolean }) => {
  const { values, errors, isSubmitting, setFieldValue } =
    useFormikContext<TSchema>();
  const { allArtistas } = getAllArtistasHooks();

  return (
    <div className="grid grid-cols-2 gap-5 py-5">
      <Select
        required
        label="Artista 1"
        isLoading={isLoading}
        value={values.roteiro[0]}
        error={isSubmitting && !!errors.roteiro}
        onChange={({ target }) =>
          setFieldValue("roteiro.0", Number(target.value))
        }
        options={allArtistas?.map(({ id, nome }) => ({
          label: nome,
          value: id.toString(),
        }))}
      />
      <Select
        label="Artista 2"
        isLoading={isLoading}
        value={values.roteiro[1]}
        error={isSubmitting && !!errors.roteiro}
        onChange={({ target }) =>
          setFieldValue("roteiro.1", Number(target.value))
        }
        options={allArtistas?.map(({ id, nome }) => ({
          label: nome,
          value: id.toString(),
        }))}
      />
      <Select
        required
        label="Roteirista 1"
        isLoading={isLoading}
        value={values.arte[0]}
        error={isSubmitting && !!errors.arte}
        onChange={({ target }) => setFieldValue("arte.0", Number(target.value))}
        options={allArtistas?.map(({ id, nome }) => ({
          label: nome,
          value: id.toString(),
        }))}
      />
      <Select
        label="Roteirista 2"
        isLoading={isLoading}
        value={values.arte[1]}
        error={isSubmitting && !!errors.arte}
        onChange={({ target }) => setFieldValue("arte.1", Number(target.value))}
        options={allArtistas?.map(({ id, nome }) => ({
          label: nome,
          value: id.toString(),
        }))}
      />
    </div>
  );
};

export default StepC;
