import { createAsyncThunk } from '@reduxjs/toolkit';
import { TSchema } from '@/pages/Cadastro/Reprint/add/schema';
import { normalizeAuth } from "@/store/utils";
import * as T from '@/store/Cadastro/types';
import { useToast } from '@/hooks';
import api from "@/services/api";
import { TUserAdminConfig } from '@/store/Auth/types';

export const fetchAllVolumesReprints = createAsyncThunk(
	'cadastro/reimpressao/list',
	async ({ id }: {id: number}) => {
		const controller = new AbortController();

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/list-volumes-reprint/${id}`,
		});	
		return data as T.TAllVolumesReprintsPayload;
	}
);

export const fetchSaveVolumeReprint = createAsyncThunk(
	'tabelas/reimpressao/add',
	async ({ values, method, token, config }: { values: TSchema, method: 'POST' | 'PUT', token: string, config: TUserAdminConfig }) => {
		try {
			const url = method === 'POST' ? `v2/add-volume-reprint` : `v2/edt-volume-reprint`;
			const controller = new AbortController();

			await api({
				url,
				method,	
				signal: controller.signal,
				data: normalizeAuth(values),
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Cadastrado com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes,
			});
		} catch (error) {
			useToast({ 
				msg: 'Falha no cadastro. Tente novamente mais tarde.', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);

export const fetchDeleteVolumesReprint = createAsyncThunk(
	'tabelas/reimpressao/delete',
	async ({ id, token, config }: { id: number, token: string, config: TUserAdminConfig }) => {
		try {
			const controller = new AbortController();

			await api({
				method: 'DELETE',
				signal: controller.signal,
				url: `v2/del-volume-reprint/${id}`,
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	

			useToast({ 
				msg: 'Excluído com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		} catch (error) {
			useToast({ 
				msg: 'Informação não encontrada', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);


