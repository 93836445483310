import React from "react";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  fetchAllVolumesReprints,
  fetchDeleteVolumesReprint,
} from "@/store/Cadastro/thunks";

import store from "@/store";
import { Sidebar } from "@/layouts";
import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import { ReprintCard, Modal } from "@/components";
import { getAllVolumesReprintsHooks } from "@/hooks/Cadastro";
import { TMangaInfo } from "@/store/Cadastro/types";
import { getUserInfoHooks } from "@/hooks/Auth";
import { isObjectEmpty } from "@/utils";
import { useToast } from "@/hooks";

import breadcrumbData from "./breadcrumbData.json";
import { TSchema } from "./schema";
import ModalForm from "./form";

const ReprintAdd = () => {
  const { id } = useParams();
  const mangaId = id;

  const [isVisible, setIsVisible] = React.useState(false);

  const { isLoading: isFakeLoading } = useFakeLoading();

  const { token, config, isAdmin } = getUserInfoHooks();
  const { allVolumesReprints, allVolumesReprintsStatus } =
    getAllVolumesReprintsHooks({ id: Number(id) });

  const isLoading = allVolumesReprintsStatus !== "success" || isFakeLoading;

  const {
    handleSubmit,
    setFieldValue,
    resetForm,
    validateForm,
    setSubmitting,
  } = useFormikContext<TSchema>();

  const handleSave = async () => {
    if (!isAdmin) return;

    setSubmitting(true);

    const error = await validateForm();
    if (isObjectEmpty(error)) {
      handleSubmit();
      setIsVisible(false);
    } else
      useToast({
        type: "error",
        msg: "Favor checar os campos obrigatórios.",
        time: 3000,
        showNotification: config.geral.exibirNotificacoes,
      });
  };

  const handleOpenModal = (id: number) => {
    if (!isAdmin) return;

    setFieldValue("volumeId", id);
    setIsVisible(true);
  };

  const handleUpdate = (id: number, id_volume: number, publicacao: string) => {
    setFieldValue("id", id);
    setFieldValue("mes", publicacao?.substring(4, 6));
    setFieldValue("ano", publicacao?.substring(0, 4));
    setFieldValue("volumeId", id_volume);
    setFieldValue("oldValue", publicacao);
    setFieldValue("isUpdate", true);
    setIsVisible(true);
  };

  const handleDelete = async (id: number) => {
    await store.dispatch(fetchDeleteVolumesReprint({ id, token, config }));
    await store.dispatch(fetchAllVolumesReprints({ id: Number(mangaId) }));
    resetForm();
  };

  const handleClose = () => {
    setIsVisible(false);
    resetForm();
  };

  return (
    <>
      <Modal
        title="Cadastrar Reimpressão"
        isCenter
        isVisible={isVisible}
        onSave={handleSave}
        onClose={handleClose}
      >
        <ModalForm />
      </Modal>
      <Sidebar>
        <HeaderSection
          isLoading={isLoading}
          align="left"
          title="Reimpressões"
          breadcrumbData={breadcrumbData}
          subtitle="Insira uma nova data de reimpressão de um volume já existente."
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 py-5">
          {allVolumesReprints?.volumes?.map((volume) => (
            <ReprintCard
              isLoading={isLoading}
              volume={volume}
              manga={allVolumesReprints?.manga as TMangaInfo}
              fnOpen={handleOpenModal}
              fnUpdate={handleUpdate}
              fnDelete={handleDelete}
            />
          ))}
        </div>
      </Sidebar>
    </>
  );
};

export default ReprintAdd;
