import * as yup from "yup";

export type TSchema = {
  id: number;
  editora: string;
  site: string;
  userId: number;
  isUpdate: boolean;
};

export const validationSchema = yup.object().shape({
  id: yup.number(),
  editora: yup.string().required().max(255),
  site: yup.string().max(255),
  userId: yup.number().min(0),
  isUpdate: yup.boolean(),
});
