import { TSchema } from '@/pages/Cadastro/Volume/add/schema';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalizeAuth } from "@/store/utils";
import * as T from '@/store/Cadastro/types';
import { useToast } from '@/hooks';
import api from "@/services/api";
import { TUserAdminConfig } from '@/store/Auth/types';

export const fetchSearchAllVolumes = createAsyncThunk(
	'cadastro/volumes/search',
	async ({ id }: {id: number}) => {
		const controller = new AbortController();

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/search-all-volumes/${id}`,
		});	
		return data as T.TSearchAllVolumesPayload;
	}
);

export const fetchSaveVolume = createAsyncThunk(
	'cadastro/volume/add',
	async ({ values, method, token, config }: { values: TSchema, method: 'POST' | 'PUT', token: string, config: TUserAdminConfig }) => {
		try {
			const url = method === 'POST' ? `v2/add-new-volume` : `v2/edt-volume`;
			const controller = new AbortController();

			const normalized = {
				...values,
				ano: `${values.ano}` // requer string
			}

			await api({
				url,
				method,	
				signal: controller.signal,
				data: normalizeAuth(normalized),
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Cadastrado com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes,
			});
		} catch (error) {
			useToast({ 
				msg: 'Falha no cadastro. Tente novamente mais tarde.', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);

export const fetchDeleteVolume = createAsyncThunk(
	'cadastro/volume/add',
	async ({ id, token, config }: { id: number, token: string, config: TUserAdminConfig }) => {
		try {
			const controller = new AbortController();

			await api({
				url: `v2/del-volume/${id}`,
				method: 'DELETE',	
				signal: controller.signal,
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Excluído com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		} catch (error) {
			useToast({ 
				msg: 'Informação não encontrada', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);
