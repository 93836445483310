import { useFormikContext } from "formik";
import { Input, Button } from "@/components";
import { TSchema } from "./schema";

const Form = ({ isLoading }: { isLoading: boolean }) => {
  const { values, errors, setFieldValue, resetForm, handleSubmit } =
    useFormikContext<TSchema>();

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 pt-5">
        <div className="lg:col-span-2">
          <Input
            required
            label="Nome"
            isLoading={isLoading}
            value={values.nome}
            error={!!errors.nome}
            onChange={({ target }) => setFieldValue("nome", target.value)}
          />
        </div>
        <div className="col-span-1 flex gap-x-5 items-center justify-end">
          <Button
            small
            label="Reset"
            outline
            isLoading={isLoading}
            onClick={() => resetForm()}
          />
          <Button
            small
            label="Salvar"
            isLoading={isLoading}
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
    </>
  );
};

export default Form;
