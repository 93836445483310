import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import store from "@/store";
import { resetSearch } from "@/utils";
import { getUserInfoHooks } from "@/hooks/Auth";
import { fetchSaveManga } from "@/store/Cadastro/thunks";

import { validationSchema, TSchema } from "./schema";
import initialValues from "./initialValues.json";
import Content from "./content";

const Manga = () => {
  const navigate = useNavigate();
  const { token, config } = getUserInfoHooks();

  const sendRequest = async (values: TSchema) => {
    const { isUpdate } = values;
    const method = !isUpdate ? "POST" : "PUT";
    resetSearch();
    await store.dispatch(fetchSaveManga({ values, method, token, config }));
    navigate("/manga/visualizar");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await sendRequest(values);
      }}
    >
      {() => <Content />}
    </Formik>
  );
};

export default Manga;
