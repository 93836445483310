import React from "react";
import { useFormikContext } from "formik";

import store from "@/store";
import { useFakeLoading } from "@/hooks";
import { FormWithContent } from "@/layouts";
import { getUserInfoHooks } from "@/hooks/Auth";
import { Table, FormikTableIcon } from "@/components";
import { getNacionalidadesListHooks } from "@/hooks/Tabelas";
import { TNacionalidades, TNacionalidadesPayload } from "@/store/Tabelas/types";
import {
  fetchDeleteNacionalidade,
  fetchGetNacionalidadesList,
} from "@/store/Tabelas/thunks";

import breadcrumbData from "./breadcrumbData.json";
import tableColumns from "./tableColumns.json";
import Form from "./form";

const Content = () => {
  const [tableData, setTableData] = React.useState<Object[]>([]);

  const { setValues } = useFormikContext();

  const { token, config, isAdmin } = getUserInfoHooks();
  const { isLoading: isFakeLoading } = useFakeLoading();
  const { nacionalidades, nacionalidadesStatus } = getNacionalidadesListHooks({
    page: 1,
    limit: config.tabelas.totalItens,
  });

  const isLoading = nacionalidadesStatus !== "success" || isFakeLoading;

  const handleDelete = async (id: number) => {
    await store.dispatch(fetchDeleteNacionalidade({ id, token, config }));
    await store.dispatch(
      fetchGetNacionalidadesList({ page: 1, limit: config.tabelas.totalItens })
    );
  };

  const handleUpdate = (nacionalidade: TNacionalidades) => {
    setValues({
      id: nacionalidade?.id,
      nome: nacionalidade?.nome,
      isUpdate: true,
    });
  };

  const handlePagination = (id: number) => {
    store.dispatch(
      fetchGetNacionalidadesList({ page: id, limit: config.tabelas.totalItens })
    );
  };

  const handleSearch = (p: string) => {
    store.dispatch(
      fetchGetNacionalidadesList({
        page: 1,
        limit: config.tabelas.totalItens,
        search: p,
      })
    );
  };

  const processTableData = (data: TNacionalidadesPayload) => {
    return data?.itens.map((item) => ({
      nome: item?.nome,
      actions: (
        <div className="flex gap-x-4 justify-end w-full">
          <FormikTableIcon
            disabled={!isAdmin}
            type="update"
            onClick={() => handleUpdate(item)}
          />
          <FormikTableIcon
            disabled={!isAdmin}
            type="delete"
            onClick={() => handleDelete(item?.id)}
          />
        </div>
      ),
    }));
  };

  React.useEffect(() => {
    if (!isLoading) setTableData(processTableData(nacionalidades));
  }, [isLoading, nacionalidades]);

  return (
    <FormWithContent
      isAdmin={isAdmin}
      isLoading={isLoading}
      title="Nacionalidades"
      breadcrumbData={breadcrumbData}
      formSubtitle="Cadastro de uma nova nacionalidade na plataforma."
      contentSubtitle="Nacionalidades cadastrados."
      form={<Form isLoading={isLoading} />}
      content={
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          onSearch={handleSearch}
          pagination={{
            actualPage: nacionalidades?.actualPage,
            pagesTotal: nacionalidades?.pagesTotal,
            handlePagination,
          }}
        />
      }
    />
  );
};

export default Content;
