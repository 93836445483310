const InfoContainer = ({
  children,
  column = 2,
  breakline = true,
}: {
  children: JSX.Element | JSX.Element[];
  column?: number;
  breakline?: boolean;
}) => (
  <>
    <div
      className={`grid grid-cols-1 text-center sm:text-left sm:grid-cols-${column} gap-5 py-5`}
    >
      {children}
    </div>
    {breakline && <hr className="border-b border-gray-300" />}
  </>
);

export default InfoContainer;
