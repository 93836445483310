import { useSelector } from "react-redux";
import { getAllTabelas } from "@/store/Tabelas/selectors";

export const getEditoraNameHooks = (editoraId: number): string => {
  const { allEditoras } = useSelector(getAllTabelas);
  
  const Editora = allEditoras?.find(({ id }) => Number(editoraId) === id)?.nome;

  return Editora ?? '';
};
