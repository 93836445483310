import { Formik } from "formik";
import { useParams } from "react-router-dom";

import store from "@/store";
import {
  fetchSaveVolume,
  fetchSearchAllVolumes,
} from "@/store/Cadastro/thunks";
import { getUserInfoHooks } from "@/hooks/Auth";

import { validationSchema, TSchema } from "./schema";
import initialValues from "./initialValues.json";
import Content from "./content";

const Volume = () => {
  const { id } = useParams();
  const { token, config } = getUserInfoHooks();

  const sendRequest = async (values: TSchema) => {
    const { isUpdate } = values;
    const method = !isUpdate ? "POST" : "PUT";
    await store.dispatch(fetchSaveVolume({ values, method, token, config }));
    await store.dispatch(fetchSearchAllVolumes({ id: Number(id) }));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await sendRequest(values);
        resetForm();
      }}
    >
      {() => <Content />}
    </Formik>
  );
};

export default Volume;
