import {
  BookOpenIcon,
  ChatBubbleLeftIcon,
  BugAntIcon,
} from "@heroicons/react/20/solid";
import { TBadgeColor } from "@/components/BaseComponents/Badge";
import { pageOptionsAdmin, pageOptionsPublic } from "@/utils/enuns";

export const suportTypeIcon: JSX.Element[] = [
  <></>,
  <BugAntIcon className="h-5 text-red-600 hover:text-red-800" />,
  <ChatBubbleLeftIcon className="h-5 text-green-600 hover:text-green-800" />,
  <BookOpenIcon className="h-5 text-blue-600 hover:text-blue-800" />,
];

export const statusList: string[] = [
  "",
  "Aberto",
  "Backlog",
  "Desenvolvimento",
  "Concluído",
  "Cancelado",
];

export const suportType: string[] = [
  "",
  "Bug fix",
  "Críticas / Sugestões",
  "Cadastro de Mangás",
];

export const suportStatusOptions = [
  { value: 1, label: "Aberto" },
  { value: 2, label: "Backlog" },
  { value: 3, label: "Desenvolvimento" },
  { value: 4, label: "Concluído" },
  { value: 5, label: "Cancelado" },
];

export const statusColorList: TBadgeColor[] = [
  "gray",
  "yellow",
  "pink",
  "blue",
  "green",
  "red",
];

export const handleSiteName = (site: string) => {
  return site === "P"
    ? "meus mangás"
    : site === "A"
    ? "painel administrativo"
    : "";
};

export const handlePages = (site: string) => {
  if (site === "P") return pageOptionsPublic;
  if (site === "A") return pageOptionsAdmin;
  return [];
};

export const handlePageName = (site: string, pagina: string) => {
  const list = handlePages(site);
  return list?.find(({ value }) => value === pagina)?.label;
};
