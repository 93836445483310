import React from "react";
import { useFormikContext } from "formik";

import { Sidebar } from "@/layouts";
import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import { TUserAdminConfig } from "@/store/Auth/types";

import breadcrumbData from "./breadcrumbData.json";
import FormCadastro from "./forms/cadastro";
import FormTabela from "./forms/tabela";
import FormGeral from "./forms/geral";
import FormSidebar from "./forms/sidebar";

const Configuracoes = () => {
  const { isLoading } = useFakeLoading();
  const { values } = useFormikContext<TUserAdminConfig>();

  React.useEffect(() => {
    localStorage.setItem("adminConfig", JSON.stringify(values));
  }, [values]);

  return (
    <Sidebar>
      <HeaderSection
        breadcrumbData={breadcrumbData}
        isLoading={isLoading}
        align="left"
        title="Configurações"
        subtitle="Personalize sua experiência na plataforma."
      />
      <div className="pt-4 pb-8 grid grid-cols-2">
        <div className="border-r border-gray-300">
          <FormCadastro isLoading={isLoading} />
          <FormTabela isLoading={isLoading} />
        </div>
        <div className="pl-6">
          <FormSidebar isLoading={isLoading} />
          <FormGeral isLoading={isLoading} />
        </div>
      </div>
    </Sidebar>
  );
};

export default Configuracoes;
