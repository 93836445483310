import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export type TBreadcrumbData = {
  id: number;
  label: string;
  route?: string;
  selected?: boolean;
};

type TBreadcrumb = {
  isLoading: boolean;
  data: TBreadcrumbData[] | undefined;
};

const Breadcrumb = ({ isLoading, data }: TBreadcrumb) => {
  const navigate = useNavigate();

  const ArrowRight = () => (
    <svg
      className="flex-shrink-0 mx-3 overflow-visible h-2.5 w-2.5 text-gray-400"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );

  return (
    <ol
      className="flex items-center whitespace-nowrap min-w-0 mb-5 "
      aria-label="Breadcrumb"
    >
      {isLoading && (
        <>
          <div className="h-5 w-16 skeleton mt-1" />
          <ArrowRight />
          <div className="h-5 w-16 skeleton mt-1" />
        </>
      )}

      {!isLoading &&
        data?.map(({ id, label, route, selected }) => (
          <li key={id} className="text-sm">
            <a
              className={classNames("tracking-wider flex items-baseline", {
                "font-semibold text-tertiary": selected,
                "cursor-pointer text-gray-400 hover:text-link": !selected,
              })}
              onClick={route && !selected ? () => navigate(route) : undefined}
            >
              {label}
              {!selected && <ArrowRight />}
            </a>
          </li>
        ))}
    </ol>
  );
};

export default Breadcrumb;
