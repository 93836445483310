import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import auth from "./Auth";
import tabelas from "./Tabelas";
import cadastro from "./Cadastro";
import suporte from "./Suporte";

export const reducer = combineReducers({
  auth: auth.reducer,
  tabelas: tabelas.reducer,
  cadastro: cadastro.reducer,
  suporte: suporte.reducer,
});

const store = configureStore({
  reducer,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
