import React from "react";
import classnames from "classnames";
import {
  ChatBubbleLeftEllipsisIcon,
  DocumentTextIcon,
  CalendarDaysIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { Modal } from "@/components";
import { getUserInfoHooks } from "@/hooks/Auth";

type TFormikTableIcon = {
  disabled?: boolean;
  type: "chat" | "document" | "calendar" | "update" | "delete";
  onClick?: () => void;
};

const FormikTableIcon = ({
  disabled = true,
  type,
  onClick,
}: TFormikTableIcon): JSX.Element => {
  const { config } = getUserInfoHooks();
  const [isVisible, setIsVisible] = React.useState(false);

  const handleSave = () => {
    if (disabled) return;
    setIsVisible(false);
    onClick?.();
  };

  const handleDelete = () => {
    if (disabled) return;
    config.geral.confirmarAoExcluir ? setIsVisible(true) : onClick?.();
  };

  if (type === "chat")
    return (
      <ChatBubbleLeftEllipsisIcon
        className={classnames("cursor-pointer h-[18px]", {
          "text-gray-400": !disabled,
          "text-gray-300": disabled,
        })}
        onClick={!disabled ? onClick : undefined}
      />
    );

  if (type === "document")
    return (
      <DocumentTextIcon
        className={classnames("cursor-pointer h-[18px]", {
          "text-gray-500": !disabled,
          "text-gray-300": disabled,
        })}
        onClick={!disabled ? onClick : undefined}
      />
    );

  if (type === "calendar")
    return (
      <CalendarDaysIcon
        className={classnames("cursor-pointer h-5", {
          " ": !disabled,
          "text-gray-300": disabled,
        })}
        onClick={!disabled ? onClick : undefined}
      />
    );

  if (type === "update")
    return (
      <PencilSquareIcon
        className={classnames("cursor-pointer h-[18px]", {
          "text-link": !disabled,
          "text-gray-300": disabled,
        })}
        onClick={!disabled ? onClick : undefined}
      />
    );

  if (type === "delete")
    return (
      <>
        {config.geral.confirmarAoExcluir && (
          <Modal
            title="Tem certeza que gostaria excluir?"
            isCenter
            noChildren
            size="small"
            isVisible={isVisible}
            labels={["Cancelar", "Sair"]}
            onSave={handleSave}
            onClose={() => setIsVisible(false)}
          />
        )}
        <TrashIcon
          className={classnames("cursor-pointer h-[18px]", {
            "text-red-500": !disabled,
            "text-gray-300": disabled,
          })}
          onClick={handleDelete}
        />
      </>
    );

  return <></>;
};

export default FormikTableIcon;
