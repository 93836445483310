import { useSelector } from "react-redux";
import { getAllTabelas } from "@/store/Tabelas/selectors";

export const getArtistaNameHooks = (artistaId: number): string => {
  const { allArtistas } = useSelector(getAllTabelas);
  
  const Artista = allArtistas?.find(({ id }) => Number(artistaId) === id)?.nome;

  return Artista ?? '';
};
