import { Button } from "@/components";
import classNames from "classnames";

type TModalFooter = {
  onClose: () => void;
  onSave?: () => void;
  noChildren?: boolean;
  labels?: string[];
};

const ModalFooter = ({
  onClose,
  onSave,
  noChildren = false,
  labels = ["Cancelar", "Salvar"],
}: TModalFooter) => {
  return (
    <div
      className={classNames("flex justify-end items-center gap-x-5 py-4 px-5", {
        "border-t border-gray-300": !noChildren,
      })}
    >
      <Button
        small
        outline
        isLoading={false}
        label={labels[0]}
        onClick={onClose}
      />
      {onSave && (
        <Button small isLoading={false} label={labels[1]} onClick={onSave} />
      )}
    </div>
  );
};

export default ModalFooter;
