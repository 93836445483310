import { useFormikContext } from "formik";

import { Select } from "@/components";
import { totalPaginationOptions } from "@/utils/enuns";
import { TUserAdminConfig } from "@/store/Auth/types";

import DefaultLine from "../defaultLine";
import DefaultTitle from "../defaultTitle";
import DefaultLabel from "../defaultLabel";

import { TableCellsIcon } from "@heroicons/react/20/solid";

const FormTabela = ({ isLoading }: { isLoading: boolean }) => {
  const { values, setFieldValue } = useFormikContext<TUserAdminConfig>();

  return (
    <div>
      <DefaultTitle
        isLoading={isLoading}
        text="Tabelas"
        icon={<TableCellsIcon className="h-7" />}
      />
      <DefaultLine>
        <div className="flex items-center gap-5">
          <DefaultLabel isLoading={isLoading} text="Total de itens exibidos" />
          <Select
            className="mt-8"
            small
            isLoading={isLoading}
            value={values?.tabelas?.totalItens}
            onChange={({ target }) =>
              setFieldValue("tabelas.totalItens", Number(target.value))
            }
            options={totalPaginationOptions}
          />
        </div>
      </DefaultLine>
    </div>
  );
};

export default FormTabela;
