import { createSelector } from '@reduxjs/toolkit';
import { TStoreReducers } from '@/store/types'
import * as T from './types';

const state = (store: TStoreReducers) => store.tabelas;

export const getAllTabelas = createSelector(
	[state], ({ 
		allArtistas, 
		allEditoras, 
		allCategorias, 
		allFaixasEtarias, 
		allFormatos, 
		allGeneros, 
		allNacionalidades 
	}: T.TTabelasStore) => 
	({ 
		allArtistas, 
		allEditoras, 
		allCategorias, 
		allFaixasEtarias, 
		allFormatos, 
		allGeneros, 
		allNacionalidades 
	})
);

export const getArtistasList = createSelector(
	[state], ({ artistas, artistasStatus }: T.TTabelasStore) => ({ artistas, artistasStatus })
);

export const getAllArtistas = createSelector(
	[state], ({ allArtistas, allArtistasStatus }: T.TTabelasStore) => ({ allArtistas, allArtistasStatus })
);

export const getEditorasList = createSelector(
	[state], ({ editoras, editorasStatus }: T.TTabelasStore) => ({ editoras, editorasStatus })
);

export const getAllEditoras = createSelector(
	[state], ({ allEditoras, allEditorasStatus }: T.TTabelasStore) => ({ allEditoras, allEditorasStatus })
);

export const getAllCategorias = createSelector(
	[state], ({ allCategorias, allCategoriasStatus }: T.TTabelasStore) => ({ allCategorias, allCategoriasStatus })
);

export const getFaixasEtariasList = createSelector(
	[state], ({ faixasEtarias, faixasEtariasStatus }: T.TTabelasStore) => ({ faixasEtarias, faixasEtariasStatus })
);

export const getAllFaixasEtarias = createSelector(
	[state], ({ allFaixasEtarias, allFaixasEtariasStatus }: T.TTabelasStore) => ({ allFaixasEtarias, allFaixasEtariasStatus })
);

export const getFormatosList = createSelector(
	[state], ({ formatos, formatosStatus }: T.TTabelasStore) => ({ formatos, formatosStatus })
);

export const getAllFormatos = createSelector(
	[state], ({ allFormatos, allFormatosStatus }: T.TTabelasStore) => ({ allFormatos, allFormatosStatus })
);

export const getGenerosList = createSelector(
	[state], ({ generos, generosStatus }: T.TTabelasStore) => ({ generos, generosStatus })
);

export const getAllGeneros = createSelector(
	[state], ({ allGeneros, allGenerosStatus }: T.TTabelasStore) => ({ allGeneros, allGenerosStatus })
);

export const getNacionalidadesList = createSelector(
	[state], ({ nacionalidades, nacionalidadesStatus }: T.TTabelasStore) => ({ nacionalidades, nacionalidadesStatus })
);

export const getAllNacionalidades = createSelector(
	[state], ({ allNacionalidades, allNacionalidadesStatus }: T.TTabelasStore) => ({ allNacionalidades, allNacionalidadesStatus })
);
