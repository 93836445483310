import store from "@/store";
import { Sidebar } from "@/layouts";
import { ImageGrid } from "@/components";
import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import { getUserInfoHooks } from "@/hooks/Auth";
import { getSearchAllMangasHooks } from "@/hooks/Cadastro";
import { fetchSearchAllMangas } from "@/store/Cadastro/thunks";
import breadcrumbData from "./breadcrumbData.json";

const ReprintView = () => {
  const { config } = getUserInfoHooks();
  const { isLoading: isFakeLoading } = useFakeLoading();

  const limit = config?.cadastro?.totalItens;
  const carregarMangas = config?.cadastro?.carregarMangas;

  const { searchAllMangas, searchAllMangasStatus } = getSearchAllMangasHooks({
    page: 1,
    limit,
    initialStart: carregarMangas,
  });

  const isLoading =
    (searchAllMangasStatus !== "success" || isFakeLoading) && carregarMangas;

  const handleSearch = (p: string) => {
    store.dispatch(fetchSearchAllMangas({ page: 1, limit, search: p }));
  };

  return (
    <Sidebar>
      <HeaderSection
        align="left"
        title="Reimpressões"
        breadcrumbData={breadcrumbData}
        subtitle="Qual mangá você gostaria de cadastrar/editar uma reimpressão?"
        isLoading={isLoading}
      />

      <ImageGrid
        isLoading={isLoading}
        data={searchAllMangas?.itens}
        search
        onSearch={handleSearch}
        route="/reimpressao/cadastro"
      />
    </Sidebar>
  );
};

export default ReprintView;
