import { useFormikContext } from "formik";
import { Input, Select } from "@/components";
import { monthOptions } from "@/utils/enuns";
import { onlyNumbers } from "@/utils";
import { TSchema } from "@/pages/Cadastro/Reprint/add/schema";

const ReprintForm = () => {
  const { values, errors, isSubmitting, setFieldValue } =
    useFormikContext<TSchema>();

  return (
    <div className="grid grid-cols-2 gap-5 px-1 py-3">
      <Select
        required
        label="Mês"
        isLoading={false}
        value={values.mes}
        options={monthOptions?.map(({ id, label }) => ({
          label,
          value: id < 10 ? `0${id}` : `${id}`,
        }))}
        error={isSubmitting && !!errors.mes}
        onChange={({ target }) =>
          setFieldValue("mes", target.value?.toString())
        }
      />
      <Input
        required
        label="Ano"
        maxLength={4}
        isLoading={false}
        value={values.ano}
        error={isSubmitting && !!errors?.ano}
        onChange={({ target }) =>
          setFieldValue("ano", onlyNumbers(target.value))
        }
      />
    </div>
  );
};

export default ReprintForm;
