import { useFormikContext } from "formik";

import { onlyNumbers } from "@/utils";
import { monthOptions } from "@/utils/enuns";
import { Input, Select, ImageCover } from "@/components";
import { getUserInfoHooks } from "@/hooks/Auth";
import SaveContainer from "./saveContainer";
import { TSchema } from "./schema";
import VolumeCover from "./volumeCover";

const IndividualRegistration = ({ isLoading }: { isLoading: boolean }) => {
  const { user } = getUserInfoHooks();

  const { values, errors, setFieldValue, isSubmitting } =
    useFormikContext<TSchema>();

  const { flagIndividualRequest } = values;

  return (
    <>
      {flagIndividualRequest && (
        <>
          <h3 className="font-semibold text-primary py-5">
            Cadastro individual
          </h3>
          <div className="sm:flex sm:justify-between">
            <VolumeCover />
            <div className="py-5 w-full sm:w-2/3 md:w-3/4 xl:w-4/5 flex flex-col justify-between">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-x-5">
                <Input
                  required
                  disabled
                  label="Id"
                  type="number"
                  isLoading={isLoading}
                  value={values.id}
                  error={isSubmitting && !!errors?.id}
                  onChange={({ target }) => setFieldValue("id", target.value)}
                />
                <Input
                  required
                  type="number"
                  label="Número"
                  isLoading={isLoading}
                  value={values.numero as number}
                  error={isSubmitting && !!errors?.numero}
                  onChange={({ target }) =>
                    setFieldValue("numero", onlyNumbers(target.value))
                  }
                />
                <Input
                  required
                  label="Preço"
                  type="number"
                  isLoading={isLoading}
                  value={values.preco as number}
                  error={isSubmitting && !!errors?.preco}
                  onChange={({ target }) =>
                    setFieldValue("preco", onlyNumbers(target.value))
                  }
                />
                <Input
                  required
                  type="number"
                  label="Páginas"
                  isLoading={isLoading}
                  value={values.paginas as number}
                  error={isSubmitting && !!errors?.paginas}
                  onChange={({ target }) =>
                    setFieldValue("paginas", onlyNumbers(target.value))
                  }
                />
                <Select
                  required
                  label="Mês"
                  isLoading={isLoading}
                  options={monthOptions?.map(({ id, label }) => ({
                    label,
                    value: id < 10 ? `0${id}` : `${id}`,
                  }))}
                  value={values.mes as string}
                  error={isSubmitting && !!errors?.mes}
                  onChange={({ target }) => setFieldValue("mes", target.value)}
                />
                <Input
                  required
                  label="Ano"
                  maxLength={4}
                  isLoading={isLoading}
                  value={values.ano as string}
                  error={isSubmitting && !!errors?.ano}
                  onChange={({ target }) =>
                    setFieldValue("ano", onlyNumbers(target.value))
                  }
                />
                {user?.id === 1 && (
                  <div className="col-span-3">
                    <Input
                      isLoading={isLoading}
                      label="Amazon"
                      value={values.amazon as string}
                      error={isSubmitting && !!errors?.amazon}
                      onChange={({ target }) =>
                        setFieldValue("amazon", target.value)
                      }
                    />
                  </div>
                )}
              </div>
              <SaveContainer type="individual" isLoading={isLoading} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IndividualRegistration;
