import { useFormikContext } from "formik";
import { statusOptions, demographyOptions } from "@/utils";
import { Select } from "@/components";
import * as H from "@/hooks/Tabelas";
import { TSchema } from "./schema";

const StepB = ({ isLoading }: { isLoading: boolean }) => {
  const { values, errors, isSubmitting, setFieldValue } =
    useFormikContext<TSchema>();

  const { allEditoras } = H.getAllEditorasHooks();
  const { allCategorias } = H.getAllCategoriasHooks();
  const { allNacionalidades } = H.getAllNacionalidadesHooks();
  const { allFormatos } = H.getAllFormatosHooks();
  const { allFaixasEtarias } = H.getAllFaixasEtariasHooks();

  return (
    <>
      <div className="grid grid-cols-3 gap-5 pt-5">
        <Select
          required
          label="Editora"
          isLoading={isLoading}
          value={values.editoraId?.toString()}
          error={isSubmitting && !!errors.editoraId}
          onChange={({ target }) =>
            setFieldValue("editoraId", Number(target.value))
          }
          options={allEditoras?.map(({ id, nome }) => ({
            label: nome,
            value: id.toString(),
          }))}
        />
        <Select
          required
          label="Categoria"
          isLoading={isLoading}
          value={values.categoriaId?.toString()}
          error={isSubmitting && !!errors.categoriaId}
          onChange={({ target }) =>
            setFieldValue("categoriaId", Number(target.value))
          }
          options={allCategorias?.map(({ id, nome }) => ({
            label: nome,
            value: id.toString(),
          }))}
        />
        <Select
          required
          label="Status"
          isLoading={isLoading}
          value={values.statuId?.toString()}
          error={isSubmitting && !!errors.statuId}
          onChange={({ target }) =>
            setFieldValue("statuId", Number(target.value))
          }
          options={statusOptions?.map(({ id, label }) => ({
            label,
            value: id.toString(),
          }))}
        />
      </div>
      <div className="grid grid-cols-2 gap-5 pb-5">
        <Select
          label="Nacionalidade"
          isLoading={isLoading}
          value={values.nacionalidadeId}
          error={isSubmitting && !!errors.nacionalidadeId}
          onChange={({ target }) =>
            setFieldValue("nacionalidadeId", Number(target.value))
          }
          options={allNacionalidades?.map(({ id, nome }) => ({
            label: nome,
            value: id.toString(),
          }))}
        />
        <Select
          label="Demografia"
          isLoading={isLoading}
          value={values.demografiaId}
          error={isSubmitting && !!errors.demografiaId}
          onChange={({ target }) =>
            setFieldValue("demografiaId", Number(target.value))
          }
          options={demographyOptions?.map(({ id, label }) => ({
            label,
            value: id.toString(),
          }))}
        />
        <Select
          label="Formato"
          isLoading={isLoading}
          value={values.formatoId}
          error={isSubmitting && !!errors.formatoId}
          onChange={({ target }) =>
            setFieldValue("formatoId", Number(target.value))
          }
          options={allFormatos?.map(({ id, nome }) => ({
            label: nome,
            value: id.toString(),
          }))}
        />
        <Select
          label="Faixa-Etária"
          isLoading={isLoading}
          value={values.faixaEtariaId}
          error={isSubmitting && !!errors.faixaEtariaId}
          onChange={({ target }) =>
            setFieldValue("faixaEtariaId", Number(target.value))
          }
          options={allFaixasEtarias?.map(({ id, nome }) => ({
            label: nome,
            value: id.toString(),
          }))}
        />
      </div>
    </>
  );
};

export default StepB;
