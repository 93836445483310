import { Header, Footer, Content, Sidebar as Menu } from "@/structures";
import Container from "@/layouts/Container";

type TDefault = {
  debug?: boolean;
  children: JSX.Element | JSX.Element[];
};

const Sidebar = ({ debug = false, children }: TDefault) => {
  return (
    <Container>
      <Header debug={debug} />

      <Content>
        <div className="flex">
          <Menu />
          <div className="layout-with-menu-container py-4 px-4 sm:px-6 lg:px-8">
            {children}
          </div>
        </div>
      </Content>

      <Footer />
    </Container>
  );
};

export default Sidebar;
