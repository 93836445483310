import React from 'react';
import { useSelector } from "react-redux";

import { getCountSearchAllVolumes } from "@/store/Cadastro/selectors";
import { fetchSearchAllVolumes } from "@/store/Cadastro/thunks";
import { TSearchAllVolumesPayload } from "@/store/Cadastro/types";

import store from '@/store';
import { TStatus } from '@/store/types';

export type TSearchAllVolumesHooks = {
  searchAllVolumes: TSearchAllVolumesPayload
  searchAllVolumesStatus: TStatus
}

export const getSearchAllVolumesHooks = ({ id }: { id: number }): TSearchAllVolumesHooks => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { searchAllVolumes, searchAllVolumesStatus } = useSelector(getCountSearchAllVolumes);
  
  React.useEffect(() => {
    if (isLoading) {
      store.dispatch(fetchSearchAllVolumes({ id }));
      setIsLoading(false);
    }
  },[isLoading, id])

  return { searchAllVolumes, searchAllVolumesStatus };
};
