import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

type TSimpleCardLink = {
  isLoading: boolean;
  title: string;
  subtitle: string;
  route?: string;
  onClick?: () => void;
  icon?: JSX.Element;
};

const SimpleCardLink = ({
  isLoading,
  title,
  subtitle,
  route,
  onClick,
  icon,
}: TSimpleCardLink) => {
  const navigate = useNavigate();
  const handleNavigate = () => navigate(route as string);

  return (
    <a
      className="cursor-pointer group flex flex-col bg-white border border-gray-300 shadow-sm rounded-xl hover:shadow-md transition"
      onClick={!isLoading ? onClick ?? handleNavigate : undefined}
    >
      <div className="p-4 md:p-5 rounded-xl bg-transparent">
        <div className="flex justify-between items-center gap-4 ">
          {icon && isLoading && <div className="w-12 h-9 my-1 skeleton" />}
          {icon && !isLoading && <div className="text-tertiary">{icon}</div>}
          <div className="w-full m-0 ">
            {isLoading ? (
              <div className="w-1/5 h-4 my-1 skeleton" />
            ) : (
              <h3 className="group-hover:text-link font-semibold text-primary">
                {title}
              </h3>
            )}

            {isLoading ? (
              <div className="w-1/2 h-4 mb-1 skeleton" />
            ) : (
              <p className="text-sm text-gray-400 group-hover:text-link">
                {subtitle}
              </p>
            )}
          </div>
          <div>
            <svg
              className={classNames("w-4 h-4 text-tertiary", {
                "group-hover:text-link": !isLoading,
              })}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </a>
  );
};

export default SimpleCardLink;
