import React from 'react';
import { useSelector } from "react-redux";
import { getGenerosList } from "@/store/Tabelas/selectors";
import { fetchGetGenerosList } from "@/store/Tabelas/thunks";
import { TGenerosPayload } from "@/store/Tabelas/types";
import store from '@/store';
import { TStatus, TDefaultPagination } from '@/store/types';

export type TGenerosListHooks = {
  generos: TGenerosPayload
  generosStatus: TStatus
}

export const getGenerosListHooks = ({ page, limit }: TDefaultPagination): TGenerosListHooks => {
  const { generos, generosStatus } = useSelector(getGenerosList);
  
  React.useEffect(() => {
    store.dispatch(fetchGetGenerosList({ page, limit }));
  }, []);

  return { generos, generosStatus };
};
