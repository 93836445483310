import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import Kaneda from "@/assets/kaneda-ytb.png";

const Banner = () => {
  const { isLoading } = useFakeLoading(750);
  return (
    <>
      <div className="p-5">
        <HeaderSection
          noborder
          isLoading={isLoading}
          align="center"
          title="Seja muito bem-vindo!"
          subtitle="Assista o tutorial completo da plataforma. Rápido e fácil."
        />
      </div>
      <div className="w-full h-auto flex justify-center bg-primary">
        <div className="w-full h-[290px] md:w-[808px] md:h-auto p-5">
          <iframe
            className="w-full h-full md:w-[500px] md:h-[280px] border-4 border-secondary rounded-xl"
            src={`https://www.youtube.com/embed/Wzn9_Vwnz50`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <img
          src={Kaneda}
          alt=""
          className="h-[290px] w-auto absolute ml-[472px] mt-[30px] hidden md:block"
        />
      </div>
    </>
  );
};

export default Banner;
