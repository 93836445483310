import { createSlice, createAction } from '@reduxjs/toolkit';
import { caseDefaultRegister }from '@/store/utils';
import * as T from './types';
import { fetchLogin } from './thunks';

const initialUser = {
	id: 0,
	nome: "",
	sobrenome: "",
	email: "",
	apelido: "",
	shareId: "",
	avatar: "",
	admin: false,
	
	configAutoLogin: false,
	configMail: false,
	configShowMsg: false,
	configReimpressao: false,
	configColecaoFiltro: false,
	configColecaoTabela: false,
	colecaoFiltro: "",
	
	totalMangas: 0,
	totalVolumes: 0,
	totalReviews: 0,
	totalComentarios: 0,
	valorColecao: 0,
	token: ""
}

const initialState: T.TAuthStore = {
	user: initialUser,
	userStatus: 'empty'
};

const resetAuth = createAction('reset/auth');

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
	},
	extraReducers: ({ addCase }) => {
		addCase(resetAuth, (state) => state = initialState),
		caseDefaultRegister({addCase, fetch: fetchLogin, fields: ['userStatus', 'user']});		
	},
});

export default slice;
