import * as yup from "yup";

export type TSchema = {
  email: string;
  senha: string;
};

export const validationSchema = yup.object().shape({
  email: yup.string().required(),
  senha: yup.string().required(),
});
