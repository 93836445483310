import React from "react";
import classnames from "classnames";
import Label from "../Label";
import { string } from "yup";

type TOption = {
  label: string;
  value: string;
};

type TSelect = {
  isLoading: boolean;
  label?: string;
  small?: boolean;
  large?: boolean;
  error?: boolean | undefined;
  required?: boolean;
  options: TOption[];
  className?: string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

const Select = ({
  isLoading,
  label,
  small = false,
  large = false,
  required,
  error,
  options,
  className,
  ...params
}: TSelect): JSX.Element => {
  return (
    <div className={`mb-4 sm:mb-8 ${className}`}>
      {label && (
        <Label
          isLoading={isLoading}
          label={label}
          error={error}
          id={params.id}
          required={required}
        />
      )}
      <select
        {...params}
        placeholder="Selecione..."
        className={classnames(
          "py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500",
          {
            "disabled-input": isLoading,
            "border border-gray-500": required,
            "border border-gray-300": !required,
            "border-2 border-red-400": !isLoading && required && error,
            "size-small pt-2": small,
            "size-large": large,
            "size-default": !small && !large,
          }
        )}
      >
        <option value="0">Selecione...</option>
        {options?.map(({ label, value }) => (
          <option
            selected={value?.toString() === params?.value?.toString()}
            value={value}
          >
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
