import React from "react";
import { useFormikContext } from "formik";

import {
  fetchDeleteVolume,
  fetchSearchAllVolumes,
} from "@/store/Cadastro/thunks";
import { getUserInfoHooks } from "@/hooks/Auth";

import store from "@/store";
import { Button, Modal } from "@/components";
import { isObjectEmpty } from "@/utils";
import { useToast } from "@/hooks";

import { TSchema } from "./schema";

type TSaveContainer = {
  isLoading: boolean;
  type: "individual" | "batch";
};

const SaveContainer = ({ type, isLoading }: TSaveContainer) => {
  const { token, config } = getUserInfoHooks();

  const [isVisible, setIsVisible] = React.useState(false);

  const {
    values,
    setFieldValue,
    resetForm,
    validateForm,
    handleSubmit,
    setSubmitting,
  } = useFormikContext<TSchema>();

  const handleSave = async () => {
    setSubmitting(true);
    const error = await validateForm();
    if (isObjectEmpty(error)) handleSubmit();
    else
      useToast({
        type: "error",
        msg: "Favor checar os campos obrigatórios.",
        time: 3000,
        showNotification: config.geral.exibirNotificacoes,
      });
  };

  const handleDelete = async () => {
    await store.dispatch(
      fetchDeleteVolume({ id: Number(values.id), token, config })
    );
    await store.dispatch(fetchSearchAllVolumes({ id: Number(values.mangaId) }));
    setFieldValue("flagIndividualRequest", false);
  };

  const handleModal = () => {
    config.geral.confirmarAoExcluir ? setIsVisible(true) : handleDelete();
  };

  if (type === "individual")
    return (
      <div className="flex items-center justify-end gap-x-5">
        {config.geral.confirmarAoExcluir && (
          <Modal
            title="Tem certeza que gostaria excluir?"
            isCenter
            noChildren
            size="small"
            isVisible={isVisible}
            labels={["Cancelar", "Sair"]}
            onSave={handleDelete}
            onClose={() => setIsVisible(false)}
          />
        )}
        {values.isUpdate && (
          <Button
            danger
            isLoading={isLoading}
            small
            label="Excluir volume"
            onClick={handleModal}
          />
        )}
        <Button
          isLoading={isLoading}
          small
          label="Cancelar"
          outline
          onClick={() => {
            resetForm();
            setFieldValue("flagIndividualRequest", false);
          }}
        />
        <Button
          isLoading={isLoading}
          small
          label="Salvar"
          onClick={handleSave}
        />
      </div>
    );

  if (type === "batch")
    return (
      <div className="flex items-center justify-end gap-x-5 py-0 col-span-2 sm:col-span-3 md:col-span-2 xl:col-span-6">
        <Button
          isLoading={isLoading}
          small
          label="Cancelar"
          outline
          onClick={() => setFieldValue("flagBatchRequest", false)}
        />
        <Button
          isLoading={isLoading}
          small
          label="Salvar"
          onClick={handleSave}
        />
      </div>
    );

  return null;
};

export default SaveContainer;
