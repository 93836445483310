import React from 'react';
import { useSelector } from "react-redux";
import { getFormatosList } from "@/store/Tabelas/selectors";
import { fetchGetFormatosList } from "@/store/Tabelas/thunks";
import { TFormatosPayload } from "@/store/Tabelas/types";
import store from '@/store';
import { TStatus, TDefaultPagination } from '@/store/types';

export type TFormatosListHooks = {
  formatos: TFormatosPayload
  formatosStatus: TStatus
}

export const getFormatosListHooks = ({ page, limit }: TDefaultPagination): TFormatosListHooks => {
  const { formatos, formatosStatus } = useSelector(getFormatosList);
  
  React.useEffect(() => {
    store.dispatch(fetchGetFormatosList({ page, limit }));
  }, []);

  return { formatos, formatosStatus };
};
