import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import moment from "moment";
import("preline");

import store from "@/store";
import { Router } from "@/router";
import "react-toastify/dist/ReactToastify.min.css";
import "./main.css";

moment.locale("pt-br");

const root = document.getElementById("root") as Element;

ReactDOM.createRoot(root).render(
  <Provider store={store}>
    <ToastContainer />
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Provider>
);
