import React from 'react';
import { useSelector } from "react-redux";
import { getAllArtistas } from "@/store/Tabelas/selectors";
import { fetchGetAllArtistas } from "@/store/Tabelas/thunks";
import { TStatus, TDefaultItem } from '@/store/types';
import store from '@/store';

export type TAllArtistasHooks = {
  allArtistas: TDefaultItem[]
  allArtistasStatus: TStatus
}

export const getAllArtistasHooks = (): TAllArtistasHooks => {
  const { allArtistas, allArtistasStatus } = useSelector(getAllArtistas);
  
  React.useEffect(() => {
    store.dispatch(fetchGetAllArtistas());
  },[]);

  return { allArtistas, allArtistasStatus };
};
