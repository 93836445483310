import { createAsyncThunk } from '@reduxjs/toolkit';
import { useToast } from "@/hooks";
import api from "@/services/api";
import * as T from './types';

export const fetchLogin = createAsyncThunk(
	'cadastro/auth/login',
	async ({ body, type }: { 
		body: T.TGoogleRequest | T.TEmailRequest, 
		type: "google" | "email"
	}) => {
		try {
			const controller = new AbortController();

			const res: any = await api({
				method: 'POST',
				signal: controller.signal,
				url: `v2/login-${type}`,
				data: body,
			});	

			if (!res?.error) {
				useToast({
					type: "success",
					msg: `Bem-vindo ${res?.data?.nome} ${res?.data?.sobrenome}!`,
					time: 3000,
					showNotification: true,
				});
			}
			return res?.data as T.TUserInfo;
		} catch (error) {
			useToast({
        type: "error",
        msg: "Ops, ocorreu um erro. Favor tentar novamente.",
        time: 5000,
        showNotification: true,
      });
		}
	}
);
