import * as yup from "yup";

export type TSchema = {
  id: number;
  volumeId: number;

  mes: string;
  ano: string;
  oldValue: string;

  userId: number;
  isUpdate: boolean;
};

export const validationSchema = yup.object().shape({
  id: yup.number(),
  volumeId: yup.number(),

  mes: yup.string().required().length(2),
  ano: yup.string().required().length(4),
  oldValue: yup.string().length(6),

  userId: yup.number().min(0),
  isUpdate: yup.boolean(),
});
