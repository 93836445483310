import { useFormikContext } from "formik";
import { SimpleCardLink } from "@/components";
import Sugestoes from "./sugestoes";
import { TSchema } from "./schema";
import Bugfix from "./bugfix";
import Manga from "./manga";

import {
  BookOpenIcon,
  ChatBubbleLeftIcon,
  BugAntIcon,
} from "@heroicons/react/20/solid";

const Form = ({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick: (p: number) => void;
}) => {
  const { values } = useFormikContext<TSchema>();
  const { flagBugfix, flagSugestions, flagNewManga } = values;
  const showForm = flagBugfix || flagSugestions || flagNewManga;

  return (
    <>
      {!showForm && (
        <div className="py-5 grid grid-cols-3 gap-5">
          <SimpleCardLink
            isLoading={isLoading}
            title="Bug fix"
            subtitle="Caso encontre algum erro, favor reportar por aqui."
            onClick={() => onClick(1)}
            icon={<BugAntIcon className="h-9 text-red-600" />}
          />
          <SimpleCardLink
            isLoading={isLoading}
            title="Criticas / Sugestões"
            subtitle="Quer propor uma melhoria para a plataforma?"
            onClick={() => onClick(2)}
            icon={<ChatBubbleLeftIcon className="h-9 text-green-600" />}
          />
          <SimpleCardLink
            isLoading={isLoading}
            title="Cadastro de Mangás"
            subtitle="Não achou algum mangá? Faça o seu pedido aqui."
            onClick={() => onClick(3)}
            icon={<BookOpenIcon className="h-9 text-blue-600" />}
          />
        </div>
      )}
      <Bugfix isLoading={isLoading} />
      <Sugestoes isLoading={isLoading} />
      <Manga isLoading={isLoading} />
    </>
  );
};

export default Form;
