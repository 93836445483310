const InfoLine = ({
  title,
  children,
  label,
}: {
  title?: string;
  children?: JSX.Element;
  label?: string | undefined | null;
}) => (
  <div className="w-full text-primary first-line:tracking-wide pb-4 sm:pb-2">
    {title && <h3 className="text-sm font-semibold pb-1">{title}</h3>}
    <div className="text-xl font-light">{label ?? children}</div>
  </div>
);

export default InfoLine;
