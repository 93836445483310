import React from "react";
import classNames from "classnames";

type TData = {
  id: number;
  title: string;
  content: JSX.Element | JSX.Element[];
};

const TabsWithUnderlineHeader = ({
  data,
  activeTab,
  isLoading,
  debug = false,
  align = "left",
  onClick,
}: {
  data: TData[];
  activeTab: number;
  isLoading: boolean;
  debug?: boolean;
  align?: "left" | "center" | "right";
  onClick: (id: number) => void;
}) => (
  <div
    className={classNames("border-b mt-5", {
      "border-green-500": debug,
      "border-gray-300": !debug,
    })}
  >
    <nav
      className={classNames("flex gap-x-3", {
        "justify-start": align === "left",
        "justify-center": align === "center",
        "justify-end": align === "right",
      })}
      aria-label="Tabs"
      role="tablist"
    >
      {data?.map(({ id, title }) => {
        if (isLoading) return <div className="skeleton w-1/2 h-5 mb-3 mt-6" />;

        return (
          <button
            type="button"
            className={classNames(
              "hs-tab-active:border-link hs-tab-active:text-link text-tertiary hover:text-link py-4 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap min-w-[80px]",
              {
                "border border-green-500": debug,
                "pr-12": align === "left",
                "px-6": align === "center",
                "pl-12": align === "right",
                " active": activeTab === id,
              }
            )}
            id={`tabs-with-underline-item-${id}`}
            data-hs-tab={`#tabs-with-underline-${id}`}
            aria-controls={`tabs-with-underline-${id}`}
            onClick={() => onClick(id)}
            role="tab"
          >
            {title}
          </button>
        );
      })}
    </nav>
  </div>
);

export default TabsWithUnderlineHeader;
