import { useFormikContext } from "formik";
import { TUserAdminConfig } from "@/store/Auth/types";

import SwitchLine from "../switchLine";
import DefaultTitle from "../defaultTitle";

import { QueueListIcon } from "@heroicons/react/20/solid";

const FormGeral = ({ isLoading }: { isLoading: boolean }) => {
  const { values, setFieldValue } = useFormikContext<TUserAdminConfig>();

  return (
    <div>
      <DefaultTitle
        isLoading={isLoading}
        text="Sidebar"
        icon={<QueueListIcon className="h-7" />}
      />

      <SwitchLine
        isLoading={isLoading}
        id={1}
        label="Menu expandido"
        value={values?.sidebar?.menuExpandido}
        onChange={({ target }) =>
          setFieldValue("sidebar.menuExpandido", target.checked)
        }
      />
    </div>
  );
};

export default FormGeral;
