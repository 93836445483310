import React from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { CheckboxWithBox } from "@/components";
import { getAllGenerosHooks } from "@/hooks/Tabelas";
import { TSchema } from "./schema";

const StepD = ({ isLoading }: { isLoading: boolean }) => {
  const { values, setFieldValue } = useFormikContext<TSchema>();
  const { allGeneros } = getAllGenerosHooks();

  React.useEffect(() => {
    setFieldValue("genero", []);
  }, []);

  const handleClick = React.useCallback(
    (id: number) => {
      const actualTags = values?.genero as number[];

      const hasID =
        actualTags.filter((actual: number) => actual === id).length > 0;

      if (!hasID) actualTags.push(id);

      if (hasID) {
        const index = actualTags.indexOf(id);
        actualTags.splice(index, 1);
      }

      setFieldValue("genero", [...new Set(actualTags)]);
    },
    [values.genero]
  );

  const totalTags: number = values.genero.filter((tag) => tag > 0).length;

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-5 pb-5">
      <div className="col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-5 xl:col-span-6 py-1">
        <p className="text-tertiary">
          Selecione no mínimo duas tags:
          <span
            className={classNames("text-lg font-bold tracking-wider", {
              "text-red-500": totalTags < 2,
              "text-green-500": totalTags >= 2,
            })}
          >{` ${totalTags}/2`}</span>
        </p>
      </div>
      {allGeneros?.map(({ id, nome }) => (
        <CheckboxWithBox
          onClick={() => handleClick(id)}
          isChecked={
            values.genero.filter((tag: number) => tag === id).length > 0
          }
          isLoading={isLoading}
          id={id.toString()}
          label={nome}
        />
      ))}
    </div>
  );
};

export default StepD;
