import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import { SimpleCardLink, Grid } from "@/components";
import {
  Cog6ToothIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/20/solid";

const Outros = () => {
  const { isLoading } = useFakeLoading(1250);

  return (
    <>
      <HeaderSection
        isLoading={isLoading}
        align="center"
        title="Outras opções"
        subtitle="Solicite ajuda através do nosso painel de suporte ou personalize sua experiência na plataforma."
      />

      <div className="grid sm:grid-cols-2 gap-6 md:gap-8 p-8 mb-5">
        <SimpleCardLink
          icon={<ChatBubbleLeftEllipsisIcon className="h-7" />}
          isLoading={isLoading}
          title="Suporte"
          subtitle="Como podemos te ajudar?"
          route="/suporte"
        />
        <SimpleCardLink
          icon={<Cog6ToothIcon className="h-7" />}
          isLoading={isLoading}
          title="Configurações"
          subtitle="Personalize sua experiência na plataforma."
          route="/configuracoes"
        />
      </div>
    </>
  );
};

export default Outros;
