import React from 'react';
import { useSelector } from "react-redux";
import { getAllFaixasEtarias } from "@/store/Tabelas/selectors";
import { fetchGetAllFaixasEtarias } from "@/store/Tabelas/thunks";
import { TStatus, TDefaultItem } from '@/store/types';
import store from '@/store';

export type TAllFaixasEtariasHooks = {
  allFaixasEtarias: TDefaultItem[]
  allFaixasEtariasStatus: TStatus
}

export const getAllFaixasEtariasHooks = (): TAllFaixasEtariasHooks => {
  const { allFaixasEtarias, allFaixasEtariasStatus } = useSelector(getAllFaixasEtarias);
  
  React.useEffect(() => {
    store.dispatch(fetchGetAllFaixasEtarias());
  },[]);

  return { allFaixasEtarias, allFaixasEtariasStatus };
};
