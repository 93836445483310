export const genderOptions = [
  { id: 1, label: 'Masculino' },
  { id: 2, label: 'Feminino' },
];

export const statusOptions = [
  { id: 1, label: 'Em Publicação' },
  { id: 2, label: 'Completo' },
  { id: 3, label: 'Cancelado' },
];

export const demographyOptions = [
  { id: 1, label: 'Kodomo' },
  { id: 2, label: 'Shounen' },
  { id: 3, label: 'Shoujo' },
  { id: 4, label: 'Seinen' },
  { id: 5, label: 'Josei' },
  { id: 6, label: 'Seijin' },
];

export const mangaTypeOptions = [
  { id: 1, label: 'Mangá' },
  { id: 2, label: 'Novel' },
  { id: 3, label: 'Databook' },
  { id: 4, label: 'Artbook' },
];

export const monthOptions = [
  { id: 1, label: 'Janeiro' },
  { id: 2, label: 'Fevereiro' },
  { id: 3, label: 'Março' },
  { id: 4, label: 'Abril' },
  { id: 5, label: 'Maio' },
  { id: 6, label: 'Junho' },
  { id: 7, label: 'Julho' },
  { id: 8, label: 'Agosto' },
  { id: 9, label: 'Setembro' },
  { id: 10, label: 'Outubro' },
  { id: 11, label: 'Novembro' },
  { id: 12, label: 'Dezembro' },
]

export const frequencyOptions = [
  { id: 1, label: 'Mensal' },
  { id: 2, label: 'Bimestral' },
  { id: 3, label: 'Trimestral' },
  { id: 6, label: 'Semestral' },
  { id: 12, label: 'Anual' },
]

export const totalImageGridPaginationOptions = [
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '75', label: '75' },
  { value: '100', label: '100' },
];

export const totalPaginationOptions = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },  
];

export const pageOptionsPublic = [
  { value: 'P01', label: 'Home' },
  { value: 'P02', label: 'Checklist' },
  { value: 'P03', label: 'Coleção' },
  { value: 'P04', label: 'Catálogo' },
  { value: 'P05', label: 'Ofertas Amazon' },
  { value: 'P06', label: 'Info' },
  { value: 'P07', label: 'Reviews' },
  { value: 'P08', label: 'Outros' },
];

export const pageOptionsAdmin = [
  { value: 'A01', label: 'Home' },
  { value: 'A02', label: 'Cadastro - Mangás' },
  { value: 'A03', label: 'Cadastro - Volumes' },
  { value: 'A04', label: 'Cadastro - Reimpressões' },
  { value: 'A05', label: 'Tabelas - Artistas' },
  { value: 'A06', label: 'Tabelas - Editoras' },
  { value: 'A07', label: 'Tabelas - Faixas-Etárias' },
  { value: 'A08', label: 'Tabelas - Formatos' },
  { value: 'A09', label: 'Tabelas - Gêneros' },
  { value: 'A10', label: 'Tabelas - Nacionalidades' },
  { value: 'A11', label: 'Sistema - Roadmap' },
  { value: 'A12', label: 'Sistema - Equipe' },
  { value: 'A13', label: 'Sistema - Suporte' },
  { value: 'A14', label: 'Sistema - Configurações' },
  { value: 'A15', label: 'Outros' },
];