import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Modal } from "@/components";
import { getUserInfoHooks } from "@/hooks/Auth";
import { Item as NavbarItem } from "..";

const NavbarUser = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { config } = getUserInfoHooks();

  const [isVisible, setIsVisible] = React.useState(false);

  const handleSave = () => {
    setIsVisible(false);
    dispatch({ type: "reset/auth" });
  };

  const handleExit = () => {
    config.geral.confirmarAoSair
      ? setIsVisible(true)
      : dispatch({ type: "reset/auth" });
  };

  return (
    <>
      <Modal
        title="Tem certeza que gostaria de sair?"
        isCenter
        noChildren
        size="small"
        isVisible={isVisible}
        labels={["Cancelar", "Sair"]}
        onSave={handleSave}
        onClose={() => setIsVisible(false)}
      />
      <div className="flex flex-col sm:flex-row sm:items-start gap-5">
        <NavbarItem
          label="Configurações"
          onClick={() => navigate("/configuracoes")}
        />
        <NavbarItem label="Suporte" onClick={() => navigate("/suporte")} />
        <NavbarItem label="Sair" link onClick={handleExit} />
      </div>
    </>
  );
};

export default NavbarUser;
