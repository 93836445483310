import { useFormikContext } from "formik";
import { Input, Select, Button } from "@/components";
import { TSchema } from "./schema";

const Form = ({ isLoading }: { isLoading: boolean }) => {
  const { values, errors, setFieldValue, resetForm, handleSubmit } =
    useFormikContext<TSchema>();

  return (
    <>
      <div className="grid grid-cols-2 gap-5 pt-5">
        <Input
          required
          label="Nome"
          isLoading={isLoading}
          value={values.nome}
          error={!!errors.nome}
          onChange={({ target }) => setFieldValue("nome", target.value)}
        />
        <div className="grid grid-cols-2 gap-5">
          <Select
            label="Gênero"
            isLoading={isLoading}
            value={values.genero}
            error={!!errors.genero}
            onChange={({ target }) => setFieldValue("genero", target.value)}
            options={[
              { label: "Masculino", value: "1" },
              { label: "Feminino", value: "2" },
            ]}
          />
          <Input
            type="date"
            label="Data nascimento"
            isLoading={isLoading}
            value={values.dataNascimento}
            error={!!errors.dataNascimento}
            onChange={({ target }) =>
              setFieldValue("dataNascimento", target.value)
            }
          />
        </div>
      </div>
      <div className="flex gap-5 pb-5 justify-end">
        <Button
          small
          label="Reset"
          outline
          isLoading={isLoading}
          onClick={() => resetForm()}
        />
        <Button
          small
          label="Salvar"
          isLoading={isLoading}
          onClick={() => handleSubmit()}
        />
      </div>
    </>
  );
};

export default Form;
