import React, { RefObject } from "react";
import classNames from "classnames";
import { useKeyPress } from "@/hooks";

type TInputSearch = {
  isLoading: boolean;
  onSearch?: (p: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputSearch = ({
  isLoading,
  onSearch,
  disabled,
  ...params
}: TInputSearch): JSX.Element => {
  const searchRef: React.MutableRefObject<HTMLInputElement | null> =
    React.useRef(null);

  const handleReset = () => {
    // @ts-ignore
    searchRef.current.value = null;
    onSearch?.("");
  };

  const SearchIcon = () => (
    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
      <svg
        className={classNames("h-4 w-4 ", {
          "text-gray-600": isLoading,
          "text-link": !isLoading,
        })}
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
      </svg>
    </div>
  );

  const SearchButton = () => (
    <div
      className={classNames(
        "absolute cursor-pointer mt-[-0px] border inset-y-0 right-0 px-4 pt-[9px] h-[46px] rounded-r-md",
        {
          "skeleton-button": isLoading,
          "hover:bg-tertiary hover:border-link border-link bg-link text-white":
            !isLoading,
        }
      )}
      onClick={
        disabled || isLoading
          ? undefined
          : () => onSearch?.(searchRef?.current?.value as string)
      }
    >
      Buscar
    </div>
  );

  const ResetButton = () => (
    <div
      onClick={() => handleReset()}
      className="absolute cursor-pointer mt-[-0px] inset-y-0 right-[80px] px-4 pt-[9px] h-[46px] border-t border-b border-transparent hover:bg-slate-100  hover:border-gray-300"
    >
      X
    </div>
  );

  if (useKeyPress("Enter")) onSearch?.(searchRef?.current?.value as string);
  if (useKeyPress("Escape")) handleReset();

  return (
    <div className="py-1 px-0">
      <div className="relative w-full border-none">
        <input
          {...params}
          autoComplete="off"
          disabled={isLoading}
          ref={searchRef}
          type="text"
          name="hs-table-with-pagination-search"
          id="hs-table-with-pagination-search"
          className={classNames(
            "p-3 pl-12 block w-full border border-gray-300 rounded-md text-sm",
            {
              "disabled-input": isLoading,
              "focus:outline-link focus:ring-link": !isLoading,
            }
          )}
          placeholder="Search for items"
        />
        {searchRef?.current?.value && searchRef?.current?.value.length > 0 && (
          <ResetButton />
        )}
        <SearchIcon />
        <SearchButton />
      </div>
    </div>
  );
};

export default InputSearch;
