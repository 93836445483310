import React from "react";
import classnames from "classnames";

type TCheckboxWithBox = {
  isLoading: boolean;
  label?: string;
  isChecked: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CheckboxWithBox = ({
  isLoading,
  id,
  label,
  value,
  isChecked,
  ...params
}: TCheckboxWithBox): JSX.Element => {
  return (
    <label
      htmlFor={`checkbox-in-form-${id}`}
      className={classnames(
        "flex items-center p-3 w-full border rounded-md text-sm focus:border-link focus:ring-link",
        {
          "bg-blue-100 border-link ": isChecked,
          "bg-white border-gray-300": !isChecked,
        }
      )}
    >
      <input
        {...params}
        type="checkbox"
        checked={isChecked}
        disabled={isLoading}
        className={classnames(
          "shrink-0 mt-0.5 border-gray-300 rounded pointer-events-none ",
          {
            "focus:ring-link focus:outline-link": !isLoading,
          }
        )}
        id={`checkbox-in-form-${id}`}
      />
      {isLoading ? (
        <div className="skeleton w-1/2 h-3 ml-2 my-1" />
      ) : (
        <span
          className={classnames("text-sm ml-3 tracking-wide", {
            "text-link font-semibold": !isLoading && isChecked,
            "text-tertiary": !isLoading && !isChecked,
          })}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default CheckboxWithBox;
