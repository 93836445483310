import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import * as H from "@/hooks/Tabelas";
import { InfoContainer, InfoLine } from "@/components";
import { getAllTabelas } from "@/store/Tabelas/selectors";
import { TSchema } from "./schema";

const StepE = () => {
  const { values } = useFormikContext<TSchema>();
  const {
    tipo,
    editoraId,
    categoriaId,
    statuId,
    nacionalidadeId,
    demografiaId,
    formatoId,
    faixaEtariaId,
    arte,
    roteiro,
    genero,
  } = values;

  const { allGeneros } = useSelector(getAllTabelas);

  const Tipo = H.getTipoNameHooks(tipo as number);
  const Editora = H.getEditoraNameHooks(editoraId as number);
  const Categoria = H.getCategoriaNameHooks(categoriaId as number);
  const Status = H.getStatusNameHooks(statuId as number);
  const Nacionalidade = H.getNacionalidadeNameHooks(nacionalidadeId as number);
  const Demografia = H.getDemografiaNameHooks(demografiaId as number);
  const Formato = H.getFormatoNameHooks(formatoId as number);
  const FaixEtaria = H.getFaixaEtariaNameHooks(faixaEtariaId as number);

  const Arte_0 = H.getArtistaNameHooks(arte[0] as number);
  const Arte_1 = H.getArtistaNameHooks(arte[1] as number);
  const Roteiro_0 = H.getArtistaNameHooks(roteiro[0] as number);
  const Roteiro_1 = H.getArtistaNameHooks(roteiro[1] as number);

  return (
    <div className="grid grid-cols-4">
      <div className="col-span-3 border-r pr-5 border-gray-300">
        <InfoContainer>
          <InfoLine title="Manga" label={values?.nome} />
          <InfoLine title="Tipo" label={Tipo} />
          <InfoLine title="Subtitulo" label={values?.subtitulo} />
          <InfoLine title="Original" label={values?.original} />
        </InfoContainer>
        <InfoContainer column={3}>
          <InfoLine title="Editora" label={Editora} />
          <InfoLine title="Categoria" label={Categoria} />
          <InfoLine title="Status" label={Status} />
        </InfoContainer>
        <InfoContainer>
          <InfoLine title="Nacionalidade" label={Nacionalidade} />
          <InfoLine title="Demografia" label={Demografia} />
          <InfoLine title="Formato" label={Formato} />
          <InfoLine title="Faixa-Etária" label={FaixEtaria} />
        </InfoContainer>
        <InfoContainer breakline={false}>
          <InfoLine title="Artista 1" label={Arte_0} />
          <InfoLine title="Artista 2" label={Arte_1} />
          <InfoLine title="Roteirista 1" label={Roteiro_0} />
          <InfoLine title="Roteirista 2" label={Roteiro_1} />
        </InfoContainer>
      </div>
      <div className="px-3 grid grid-cols-2 gap-3 h-fit pt-5">
        <h3 className="text-sm font-semibold pb-1 col-span-2 h-fit">Tags</h3>
        {genero?.map((generoId) => (
          <p className="h-fit text-center  gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-link text-white">
            {allGeneros?.find(({ id }) => Number(generoId) === id)?.nome}
          </p>
        ))}
      </div>
    </div>
  );
};

export default StepE;
