import { useFormikContext } from "formik";

import { Button, Select } from "@/components";
import { getUserInfoHooks } from "@/hooks/Auth";
import { useToast } from "@/hooks";
import { TUserAdminConfig } from "@/store/Auth/types";
import initialValues from "@/hooks/Auth/getUserInfo/initialValues.json";

import SwitchLine from "../switchLine";
import DefaultLine from "../defaultLine";
import DefaultTitle from "../defaultTitle";

import { Cog6ToothIcon } from "@heroicons/react/20/solid";

const FormGeral = ({ isLoading }: { isLoading: boolean }) => {
  const { isAdmin } = getUserInfoHooks();
  const { values, setFieldValue, setValues } =
    useFormikContext<TUserAdminConfig>();

  const handleResetCache = () => {
    localStorage.clear();
    setValues(initialValues);
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    useToast({ msg: "Limpeza de cache concluída!", showNotification: true });
  };

  return (
    <div>
      <DefaultTitle
        isLoading={isLoading}
        text="Geral"
        icon={<Cog6ToothIcon className="h-7" />}
      />

      <SwitchLine
        isLoading={isLoading}
        id={1}
        label="Exibir notificações"
        value={values?.geral?.exibirNotificacoes}
        onChange={({ target }) =>
          setFieldValue("geral.exibirNotificacoes", target.checked)
        }
      />
      <SwitchLine
        isLoading={isLoading}
        id={2}
        label="Confirmar ao sair"
        value={values?.geral?.confirmarAoSair}
        onChange={({ target }) =>
          setFieldValue("geral.confirmarAoSair", target.checked)
        }
      />

      {isAdmin && (
        <SwitchLine
          isLoading={isLoading}
          id={3}
          label="Confirmar ao excluir"
          value={values?.geral?.confirmarAoExcluir}
          onChange={({ target }) =>
            setFieldValue("geral.confirmarAoExcluir", target.checked)
          }
        />
      )}

      <DefaultLine>
        <Button
          isLoading={isLoading}
          label="Limpar cache"
          soft
          onClick={handleResetCache}
        />
      </DefaultLine>
    </div>
  );
};

export default FormGeral;
