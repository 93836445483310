import React from 'react';
import { useSelector } from "react-redux";
import { getAllFormatos } from "@/store/Tabelas/selectors";
import { fetchGetAllFormatos } from "@/store/Tabelas/thunks";
import { TStatus, TDefaultItem } from '@/store/types';
import store from '@/store';

export type TAllFormatosHooks = {
  allFormatos: TDefaultItem[]
  allFormatosStatus: TStatus
}

export const getAllFormatosHooks = (): TAllFormatosHooks => {
  const { allFormatos, allFormatosStatus } = useSelector(getAllFormatos);
  
  React.useEffect(() => {
    store.dispatch(fetchGetAllFormatos());
  },[]);

  return { allFormatos, allFormatosStatus };
};
