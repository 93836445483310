import store from "@/store";
import { ImageGrid } from "@/components";
import { useFakeLoading } from "@/hooks";
import { FormWithContent } from "@/layouts";
import { getUserInfoHooks } from "@/hooks/Auth";
import { fetchSearchAllMangas } from "@/store/Cadastro/thunks";
import { getSearchAllMangasHooks } from "@/hooks/Cadastro";
import breadcrumbData from "./breadcrumbData.json";
import Form from "./form";

const Manga = () => {
  const { config, isAdmin } = getUserInfoHooks();
  const { isLoading: isFakeLoading } = useFakeLoading();

  const limit = config?.cadastro?.totalItens;
  const carregarMangas = config?.cadastro?.carregarMangas;

  const { searchAllMangas, searchAllMangasStatus } = getSearchAllMangasHooks({
    page: 1,
    limit,
    initialStart: carregarMangas,
  });

  const isLoading =
    (searchAllMangasStatus !== "success" || isFakeLoading) && carregarMangas;

  const handleSearch = (p: string) => {
    store.dispatch(fetchSearchAllMangas({ page: 1, limit, search: p }));
  };

  return (
    <FormWithContent
      isAdmin={isAdmin}
      isLoading={isLoading}
      title="Cadastro de mangás"
      breadcrumbData={breadcrumbData}
      formSubtitle="O que você gostaria de fazer?"
      contentSubtitle="Ou escolha um mangá abaixo para alterar seus dados."
      form={<Form isLoading={isLoading} />}
      content={
        <ImageGrid
          disabledClick={!isAdmin}
          isLoading={isLoading}
          data={searchAllMangas?.itens}
          search
          onSearch={handleSearch}
          route="/manga/cadastro"
        />
      }
    />
  );
};

export default Manga;
