import React from "react";
import classNames from "classnames";
import Mangas from "@/assets/mangas.jpg";

type TCardWithStretchedButtons = {
  isLoading: boolean;
  label?: string;
  labelColor?:
    | "red"
    | "green"
    | "blue"
    | "orange"
    | "yellow"
    | "cyan"
    | "gray"
    | "stone";
  title: string;
  subtitle: string;
  totalButtons: 1 | 2;
  labelButtonOne: string | JSX.Element;
  fnButtonOne?: () => void;
  labelButtonTwo?: string | JSX.Element;
  fnButtonTwo?: () => void;
};

const CardWithStretchedButtons = ({
  isLoading,
  label,
  labelColor = "orange",
  title,
  subtitle,
  totalButtons,
  labelButtonOne,
  fnButtonOne,
  labelButtonTwo,
  fnButtonTwo,
}: TCardWithStretchedButtons) => {
  return (
    <div className="group flex flex-col h-full border border-gray-300 shadow-sm rounded-xl">
      {/* <div className="aspect-w-16 aspect-h-8">
        <img
          className="w-full object-cover rounded-t-xl"
          src={Mangas}
          alt="Image Description"
        />
      </div> */}

      <div className="w-full h-5 bg-white rounded-t-xl" />

      <div className="p-4 md:p-6 bg-white h-full">
        {label && (
          <span
            className={classNames(
              "block mb-1 text-xs font-semibold uppercase",
              {
                "text-red-600": labelColor === "red",
                "text-green-600": labelColor === "green",
                "text-link": labelColor === "blue",
                "text-orange-600": labelColor === "orange",
                "text-yellow-600": labelColor === "yellow",
                "text-cyan-600": labelColor === "cyan",
                "text-gray-600": labelColor === "gray",
                "text-stone-600": labelColor === "stone",
              }
            )}
          >
            {isLoading ? <div className="skeleton w-1/2 h-4" /> : label}
          </span>
        )}
        <h3 className="text-xl font-semibold text-primary">
          {isLoading ? <div className="skeleton w-1/2 h-6 mt-1" /> : title}
        </h3>
        <div className="mt-3 text-gray-400">
          {isLoading ? <div className="skeleton w-full h-4 mt-4" /> : subtitle}
        </div>
      </div>

      <div className="mt-auto flex border-t border-gray-300 divide-x divide-gray-300">
        <a
          className={classNames(
            "cursor-pointer w-full py-3 px-4 inline-flex justify-center items-center gap-2 font-medium bg-primary text-gray-400 hover:text-gray-200 focus:outline-none shadow-sm align-middle transition-all text-sm sm:p-4",
            {
              "rounded-br-xl rounded-bl-xl": totalButtons === 1,
              "rounded-bl-xl": totalButtons === 2,
            }
          )}
          onClick={isLoading ? undefined : fnButtonOne}
        >
          {isLoading ? (
            <div className="skeleton w-1/2 h-4 bg-tertiary" />
          ) : (
            labelButtonOne
          )}
        </a>

        {totalButtons === 2 && (
          <a
            className="cursor-pointer w-full py-3 px-4 inline-flex justify-center items-center gap-2 rounded-br-xl font-medium  shadow-sm align-middle bg-primary text-gray-400 hover:text-gray-200 focus:outline-none transition-all text-sm sm:p-4"
            onClick={isLoading ? undefined : fnButtonTwo}
          >
            {isLoading ? (
              <div className="skeleton w-1/2 h-4 bg-tertiary" />
            ) : (
              labelButtonTwo
            )}
          </a>
        )}
      </div>
    </div>
  );
};

export default CardWithStretchedButtons;
