import { useNavigate } from "react-router-dom";

import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import { CardWithStretchedButtons, Grid } from "@/components";

const Cadastro = () => {
  const navigate = useNavigate();
  const { isLoading } = useFakeLoading(1000);

  const Icon = () => (
    <svg
      className="w-4 h-4"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );

  const LabelButton = () => (
    <div className="w-full h-full flex justify-between items-center px-2">
      Novo Cadastro <Icon />
    </div>
  );

  return (
    <>
      <HeaderSection
        isLoading={isLoading}
        align="center"
        title="O que deseja cadastrar?"
        subtitle="Acesse rapidamente as opções mais utilizadas no sistema."
      />

      <Grid>
        <CardWithStretchedButtons
          isLoading={isLoading}
          title="Mangás"
          subtitle="Cadastro de um novo mangá na plataforma."
          totalButtons={1}
          labelButtonOne={<LabelButton />}
          fnButtonOne={() => navigate("/manga/visualizar")}
        />
        <CardWithStretchedButtons
          isLoading={isLoading}
          title="Volumes"
          subtitle="Adicione um novo volume a um mangá já existente."
          totalButtons={1}
          labelButtonOne={<LabelButton />}
          fnButtonOne={() => navigate("/volume/visualizar")}
        />
        <CardWithStretchedButtons
          isLoading={isLoading}
          title="Reimpressões"
          subtitle="Cadastre reimpressões de volumes já publicados anteriormente."
          totalButtons={1}
          labelButtonOne={<LabelButton />}
          fnButtonOne={() => navigate("/reimpressao/visualizar")}
        />
      </Grid>
    </>
  );
};

export default Cadastro;
