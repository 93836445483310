import React from 'react';
import { useSelector } from "react-redux";
import { getNacionalidadesList } from "@/store/Tabelas/selectors";
import { fetchGetNacionalidadesList } from "@/store/Tabelas/thunks";
import { TNacionalidadesPayload } from "@/store/Tabelas/types";
import store from '@/store';
import { TStatus, TDefaultPagination } from '@/store/types';

export type TNacionalidadesListHooks = {
  nacionalidades: TNacionalidadesPayload
  nacionalidadesStatus: TStatus
}

export const getNacionalidadesListHooks = ({ page, limit }: TDefaultPagination): TNacionalidadesListHooks => {
  const { nacionalidades, nacionalidadesStatus } = useSelector(getNacionalidadesList);

  React.useEffect(() => {
    store.dispatch(fetchGetNacionalidadesList({ page, limit }));
  }, []);

  return { nacionalidades, nacionalidadesStatus };
};
