import { SimpleCardLink } from "@/components";

const Form = ({ isLoading }: { isLoading: boolean }) => (
  <>
    <div className="py-5 w-full flex justify-center">
      <div className="w-1/2">
        <SimpleCardLink
          isLoading={isLoading}
          title="Cadastrar novo"
          subtitle="Inicie o processo de cadastro de mangás na plataforma."
          route="/manga/cadastro/0"
        />
      </div>
    </div>
  </>
);

export default Form;
