import { List } from "@/components";

type TSwipeColumn = {
  title: string;
  subtitle?: string;
  itens: string[];
};

const SwipeColumn = ({ title, subtitle, itens }: TSwipeColumn) => (
  <div className="border-r border-gray-300 p-5 h-full text-tertiary">
    <h3 className="text-sm mb-10">
      <span className="font-semibold text-lg text-link mr-1">{title} -</span>
      {subtitle}
    </h3>
    <div className="px-5">
      <List itens={itens} />
    </div>
  </div>
);

export default SwipeColumn;
