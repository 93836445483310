import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import store from "@/store";
import { fetchLogin } from "@/store/Auth/thunks";
import { TGoogleRequest } from "@/store/Auth/types";
import { getUserInfo } from "@/store/Auth/selectors";

const GoogleAuth = (): JSX.Element => {
  const navigate = useNavigate();
  const { user } = useSelector(getUserInfo);

  const handleCallbackResponse = async (body: TGoogleRequest) => {
    await store.dispatch(fetchLogin({ body, type: "google" }));
  };

  React.useEffect(() => {
    const client_id = import.meta.env.VITE_GOOGLE_ID;

    /* global google */
    /* @ts-ignore */
    google?.accounts?.id?.initialize({
      client_id,
      callback: handleCallbackResponse,
    });

    /* global google */
    /* @ts-ignore */
    google?.accounts?.id?.renderButton(
      document.getElementById("signInGoogle"),
      {
        // theme: "outline",
        theme: "filled_blue",
        size: "large",
      }
    );

    /* global google */
    /* @ts-ignore */
    google?.accounts?.id?.prompt();
  }, []);

  React.useEffect(() => {
    if (user?.id > 0) navigate("/home");
  }, [user]);

  return (
    <div className="w-full flex flex-col items-center justify-center py-6 border-t border-gray-500">
      <p className="font-semibold text-gray-400 text-center">
        Ou faça login através da sua conta google
      </p>
      <div id="signInGoogle" className="mt-6 mb-1" />
    </div>
  );
};

export default GoogleAuth;
