import React from "react";
import { TVolumeReprint, TMangaInfo } from "@/store/Cadastro/types";
import { getUserInfoHooks } from "@/hooks/Auth";
import { Modal } from "@/components";

import ReprintFooter from "./footer";
import ReprintHeader from "./header";
import ReprintCover from "./cover";
import ListItem from "./listItem";

const ReprintAdd = ({
  isLoading,
  manga,
  volume,
  fnOpen,
  fnUpdate,
  fnDelete,
}: {
  isLoading: boolean;
  manga: TMangaInfo;
  volume: TVolumeReprint;
  fnOpen: (id: number) => void;
  fnUpdate: (id: number, id_volume: number, publicacao: string) => void;
  fnDelete: (id: number) => void;
}) => {
  const { config } = getUserInfoHooks();

  const [reprintId, setReprintId] = React.useState(0);
  const [isVisible, setIsVisible] = React.useState(false);

  const handleDelete = (id: number) => {
    if (config.geral.confirmarAoExcluir) {
      setReprintId(id);
      setIsVisible(true);
    } else {
      fnDelete(id);
    }
  };

  return (
    <>
      {config.geral.confirmarAoExcluir && (
        <Modal
          title="Tem certeza que gostaria excluir?"
          isCenter
          noChildren
          size="small"
          isVisible={isVisible}
          labels={["Cancelar", "Sair"]}
          onSave={() => fnDelete(reprintId)}
          onClose={() => setIsVisible(false)}
        />
      )}
      <div className="bg-white border border-gray-300 shadow-sm rounded-xl flex">
        <ReprintCover isLoading={isLoading} url={volume?.url} />
        <div className="w-full flex flex-wrap">
          <div className="p-5 sm:p-6 flex flex-col w-full justify-between">
            <ReprintHeader
              isLoading={isLoading}
              onClick={() => fnOpen(volume?.id)}
            />
            <ul className="w-full h-full flex flex-col divide-y divide-gray-200 py-2">
              {volume?.reimpressoes?.map(({ id, id_volume, publicacao }) => (
                <ListItem
                  isLoading={isLoading}
                  label={publicacao}
                  fnEdit={() => fnUpdate(id, id_volume, publicacao)}
                  fnDelete={() => handleDelete(id)}
                />
              ))}
            </ul>
            <ReprintFooter
              isLoading={isLoading}
              nome={manga?.nome as string}
              numero={volume?.numero}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReprintAdd;
