import { createSelector } from '@reduxjs/toolkit';
import { TStoreReducers } from '@/store/types'
import * as T from './types';

const state = (store: TStoreReducers) => store.cadastro;

export const getSearchAllMangas = createSelector(
	[state], ({ searchAllMangas, searchAllMangasStatus }: T.TCadastroStore) => ({ searchAllMangas, searchAllMangasStatus })
);

export const getGetMangaInfo = createSelector(
	[state], ({ mangaInfo, mangaInfoStatus }: T.TCadastroStore) => ({ mangaInfo, mangaInfoStatus })
);

export const getCountSearchAllVolumes = createSelector(
	[state], ({ searchAllVolumes, searchAllVolumesStatus }: T.TCadastroStore) => ({ searchAllVolumes, searchAllVolumesStatus })
);

export const getAllVolumesReprints = createSelector(
	[state], ({ allVolumesReprints, allVolumesReprintsStatus }: T.TCadastroStore) => ({ allVolumesReprints, allVolumesReprintsStatus })
);