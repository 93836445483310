import { Formik } from "formik";

import { fetchSaveSuporte, fetchSuporteList } from "@/store/Suporte/thunks";
import { getUserInfoHooks } from "@/hooks/Auth";
import store from "@/store";

import { validationSchema, TSchema } from "./schema";
import initialValues from "./initialValues.json";
import Content from "./content";

const Suporte = () => {
  const { token, config } = getUserInfoHooks();

  const sendRequest = async (values: TSchema) => {
    const { isUpdate } = values;
    const method = !isUpdate ? "POST" : "PUT";
    await store.dispatch(fetchSaveSuporte({ values, method, token, config }));
    await store.dispatch(
      fetchSuporteList({ page: 1, limit: config.tabelas.totalItens })
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await sendRequest(values);
        resetForm();
      }}
    >
      {() => <Content />}
    </Formik>
  );
};

export default Suporte;
