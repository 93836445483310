import React from "react";
import TabsWithUnderlineHeader from "./header";
import TabsWithUnderlineContent from "./content";

type TData = {
  id: number;
  title: string;
  content: JSX.Element | JSX.Element[];
};

type TTabsWithUnderline = {
  isLoading: boolean;
  debug?: boolean;
  data: TData[];
  align?: "left" | "center" | "right";
};

const TabsWithUnderline = ({
  isLoading,
  debug = false,
  data,
  align = "left",
}: TTabsWithUnderline) => {
  const [activeTab, setActiveTab] = React.useState(1);

  return (
    <>
      <TabsWithUnderlineHeader
        debug={debug}
        align={align}
        data={data}
        activeTab={activeTab}
        isLoading={isLoading}
        onClick={setActiveTab}
      />
      <TabsWithUnderlineContent
        debug={debug}
        data={data}
        activeTab={activeTab}
      />
    </>
  );
};

export default TabsWithUnderline;
