import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";

import { SimpleCardLink } from "@/components";
import IndividualRegistration from "./individualRegistration";
import BatchRegistration from "./batchRegistration";
import { TSchema } from "./schema";

const Form = ({ isLoading }: { isLoading: boolean }) => {
  const { id } = useParams();

  const { values, setFieldValue, resetForm, errors } =
    useFormikContext<TSchema>();

  const { flagBatchRequest, flagIndividualRequest } = values;

  const showForm = flagIndividualRequest || flagBatchRequest;

  const handleShowIndividualRegistration = () => {
    resetForm();

    setFieldValue("mangaId", Number(id));
    setFieldValue("periodicidade", 0);
    setFieldValue("quantidade", 0);

    setFieldValue("flagIndividualRequest", true);
    setFieldValue("flagBatchRequest", false);
  };

  const handleShowBatchRegistration = () => {
    resetForm();

    setFieldValue("mangaId", Number(id));
    setFieldValue("numero", 0);

    setFieldValue("flagIndividualRequest", false);
    setFieldValue("flagBatchRequest", true);
  };

  return (
    <>
      {!showForm && (
        <div className="py-5 grid grid-cols-3 gap-5">
          <SimpleCardLink
            isLoading={isLoading}
            title="Cadastro em Lote"
            subtitle="Adicione vários volumes rapidamente no sistema de forma simplificada."
            onClick={handleShowBatchRegistration}
          />
          <SimpleCardLink
            isLoading={isLoading}
            title="Cadastro Individual"
            subtitle="Adicione um volume com acesso ao cadastro completo de informações."
            onClick={handleShowIndividualRegistration}
          />
          <SimpleCardLink
            isLoading={isLoading}
            title="Reimpressões"
            subtitle="Insira uma nova data de reimpressão de um volume já existente."
            route={`/reimpressao/cadastro/${id}`}
          />
        </div>
      )}
      <BatchRegistration isLoading={isLoading} />
      <IndividualRegistration isLoading={isLoading} />
    </>
  );
};

export default Form;
