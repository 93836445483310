import React from "react";
import classNames from "classnames";

type TGrid = {
  debug?: boolean;
  children: JSX.Element[];
};

const Grid = ({ debug = false, children }: TGrid) => {
  return (
    <div
      className={classNames(
        "max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto bg-transparent",
        {
          "border border-green-500": debug,
        }
      )}
    >
      <div
        className={classNames(
          "grid sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 bg-transparent",
          {
            "border border-red-500": debug,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Grid;
