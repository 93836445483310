import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import {
  BookOpenIcon,
  ChatBubbleLeftIcon,
  BugAntIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import store from "@/store";
import { Avatar } from "@/components";
import { getUserInfoHooks } from "@/hooks/Auth";
import { getUserInfo } from "@/store/Auth/selectors";
import { fetchSuporteList } from "@/store/Suporte/thunks";

const SearchFilters = () => {
  const [userId, setUserId] = React.useState(0);
  const [type, setType] = React.useState(0);
  const [status, setStatus] = React.useState(0);

  const { user } = useSelector(getUserInfo);

  const RenderButton = ({
    children,
    bg,
    disabled,
    onClick,
  }: {
    children?: JSX.Element;
    bg?: number;
    disabled?: boolean;
    onClick: (p: number) => void;
  }) => (
    <div
      // @ts-ignore
      onClick={onClick}
      className={classNames(
        "cursor-pointer rounded-md w-8 h-8 flex items-center justify-center",
        {
          "bg-yellow-500 hover:bg-yellow-600": bg === 1,
          "bg-blue-500 hover:bg-blue-600": bg === 3,
          "bg-green-500 hover:bg-green-600": bg === 4,
          "opacity-100": !disabled,
          "opacity-25 hover:opacity-50": disabled,
        }
      )}
    >
      {children}
    </div>
  );

  const { config } = getUserInfoHooks();

  const handleUser = (newValue: number) => {
    setUserId(userId === newValue ? 0 : newValue);
  };

  const handleType = (newValue: number) => {
    setType(type === newValue ? 0 : newValue);
  };

  const handleStatus = (newValue: number) => {
    setStatus(status === newValue ? 0 : newValue);
  };

  const handleReset = () => {
    setUserId(0);
    setType(0);
    setStatus(0);
  };

  React.useEffect(() => {
    store.dispatch(
      fetchSuporteList({
        page: 1,
        limit: config.tabelas.totalItens,
        userId,
        type,
        status,
      })
    );
  }, [userId, type, status]);

  return (
    <div className="flex justify-end items-center gap-5">
      <RenderButton
        disabled={userId === 0}
        onClick={() => handleUser(user?.id)}
      >
        <Avatar src={user?.avatar} rounded />
      </RenderButton>
      <RenderButton disabled={type !== 1} onClick={() => handleType(1)}>
        <BugAntIcon className="h-7 text-red-600 hover:text-red-800" />
      </RenderButton>
      <RenderButton disabled={type !== 2} onClick={() => handleType(2)}>
        <ChatBubbleLeftIcon className="h-7 text-green-600 hover:text-green-800" />
      </RenderButton>
      <RenderButton disabled={type !== 3} onClick={() => handleType(3)}>
        <BookOpenIcon className="h-7 text-blue-600 hover:text-blue-800" />
      </RenderButton>
      <RenderButton
        disabled={status !== 1}
        onClick={() => handleStatus(1)}
        bg={1}
      />
      <RenderButton
        disabled={status !== 3}
        onClick={() => handleStatus(3)}
        bg={3}
      />
      <RenderButton
        disabled={status !== 4}
        onClick={() => handleStatus(4)}
        bg={4}
      />
      <RenderButton disabled={type !== 3} onClick={handleReset}>
        <XMarkIcon className="h-8 text-gray-600 hover:gray-blue-800" />
      </RenderButton>
    </div>
  );
};

export default SearchFilters;
