import { useSelector } from "react-redux";
import { getAllTabelas } from "@/store/Tabelas/selectors";

export const getNacionalidadeNameHooks = (nacionalidadeId: number): string => {
  const { allNacionalidades } = useSelector(getAllTabelas);
  
  const Nacionalidade = allNacionalidades?.find(({ id }) => Number(nacionalidadeId) === id)?.nome;

  return Nacionalidade ?? '';
};
