import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import React from "react";

import { fetchSuporteList, fetchDeleteSuporte } from "@/store/Suporte/thunks";
import { Table, Avatar, FormikTableIcon, Badge, Modal } from "@/components";
import { getSuporteList } from "@/store/Suporte/selectors";
import { TSuportList } from "@/store/Suporte/types";
import { getUserInfoHooks } from "@/hooks/Auth";
import { FormWithContent } from "@/layouts";
import { useFakeLoading } from "@/hooks";
import store from "@/store";

import { suportTypeIcon, statusList, statusColorList } from "./utils";
import breadcrumbData from "./breadcrumbData.json";
import tableColumns from "./tableColumns.json";
import { TSchema } from "./schema";

import Form from "./form";
import ModalInfo from "./modalInfo";
import ModalResposta from "./modalResposta";
import SearchFilters from "./searchFilters";

const Content = () => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [isAnswerVisible, setIsAnswerVisible] = React.useState<boolean>(false);

  const [isLoaded, setLoaded] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<Object[]>([]);

  const { values, setFieldValue, setValues, resetForm } =
    useFormikContext<TSchema>();
  const { isLoading: isFakeLoading } = useFakeLoading();

  const { user, token, config } = getUserInfoHooks();

  React.useEffect(() => {
    if (!isLoaded) {
      store.dispatch(
        fetchSuporteList({ page: 1, limit: config.tabelas.totalItens })
      );
      setLoaded(true);
    }
  }, [isLoaded]);

  const { suportes, suportesStatus } = useSelector(getSuporteList);

  const isLoading = suportesStatus !== "success" || isFakeLoading;

  const handleClick = (step: number) => {
    resetForm();

    switch (step) {
      case 1:
        setFieldValue("flagBugfix", true);
        setFieldValue("flagSugestions", false);
        setFieldValue("flagNewManga", false);
        setFieldValue("manga", "");
        setFieldValue("editora", "");
        break;
      case 2:
        setFieldValue("flagBugfix", false);
        setFieldValue("flagSugestions", true);
        setFieldValue("flagNewManga", false);
        setFieldValue("pagina", "");
        setFieldValue("manga", "");
        setFieldValue("editora", "");
        break;
      case 3:
        setFieldValue("flagBugfix", false);
        setFieldValue("flagSugestions", false);
        setFieldValue("flagNewManga", true);
        setFieldValue("descricao", "");
        setFieldValue("site", "");
        setFieldValue("pagina", "");
        break;
      default:
    }

    setFieldValue("tipo", step);
  };

  const handleDelete = async (id: number) => {
    await store.dispatch(fetchDeleteSuporte({ id, token, config }));
    await store.dispatch(
      fetchSuporteList({ page: 1, limit: config.tabelas.totalItens })
    );
  };

  const handleUpdate = (item: TSchema) => {
    handleClick(item.tipo);

    switch (item.tipo) {
      case 1:
        setFieldValue("descricao", item.descricao);
        setFieldValue("site", item.site);
        setFieldValue("pagina", item.pagina);
        break;
      case 2:
        setFieldValue("descricao", item.descricao);
        setFieldValue("site", item.site);
        break;
      case 3:
        setFieldValue("manga", item.manga);
        setFieldValue("editora", `${item.editoraId}`);
        break;
      default:
    }

    setFieldValue("id", item.id);
    setFieldValue("isUpdate", true);

    setFieldValue("userId", item.userAddId);
    setFieldValue("status", item.status);
  };

  const processTableData = (data: TSuportList) => {
    return data?.itens.map((item: TSchema) => ({
      Tipo: suportTypeIcon[item.tipo],
      Solicitante: (
        <div className="inline-flex gap-x-4 items-center">
          <Avatar rounded src={item.userAddAvatar} />
          {item.userAdd}
        </div>
      ),
      Descricao:
        item.tipo === 3
          ? `Mangá: ${item.manga} ${
              item.editora ? `- Editora: ${item.editora}` : ``
            }`
          : item.descricao,
      Status: (
        <div className="flex gap-x-4 justify-end w-full">
          <Badge
            text={`${statusList[item.status as number]}`}
            color={`${statusColorList[item.status as number]}`}
          />
        </div>
      ),
      Ações: (
        <div className="flex gap-x-4 justify-end w-full">
          <FormikTableIcon
            disabled={false}
            type="document"
            onClick={() => {
              setValues(item);
              setIsVisible(true);
              setIsAnswerVisible(false);
            }}
          />
          <FormikTableIcon
            disabled={item.userAddId !== user.id || (item.status as number) > 1}
            type="update"
            onClick={() => handleUpdate(item)}
          />
          <FormikTableIcon
            disabled={item.userAddId !== user.id || (item.status as number) > 1}
            type="delete"
            onClick={() => handleDelete(item?.id)}
          />
          {user.admin && (
            <FormikTableIcon
              disabled={false}
              type="chat"
              onClick={() => {
                setValues(item);
                setIsVisible(false);
                setIsAnswerVisible(true);
              }}
            />
          )}
        </div>
      ),
    }));
  };

  React.useEffect(() => {
    if (!isLoading) setTableData(processTableData(suportes));
  }, [isLoading, suportes]);

  return (
    <FormWithContent
      isAdmin={true}
      isLoading={isLoading}
      breadcrumbData={breadcrumbData}
      title="Suporte"
      formSubtitle="Como podemos te ajudar?"
      contentSubtitle="Veja as solicitações realizadas até o momento."
      form={<Form isLoading={isLoading} onClick={handleClick} />}
      content={
        <>
          <ModalResposta
            data={values}
            isVisible={isAnswerVisible}
            setIsVisible={setIsAnswerVisible}
          />
          <ModalInfo
            data={values}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />
          <Table
            columns={tableColumns}
            data={tableData}
            isLoading={false}
            searchFilters={<SearchFilters />}
          />
        </>
      }
    />
  );
};

export default Content;
