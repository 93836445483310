import { useFormikContext } from "formik";
import { Switch } from "@/components";
import { TSchema } from "./schema";

const SwitchVariante = () => {
  const { values, setFieldValue } = useFormikContext<TSchema>();

  return (
    <Switch
      label="Capa variante"
      checked={values?.flagCapaVariante}
      onChange={({ target }) =>
        setFieldValue("flagCapaVariante", target.checked)
      }
    />
  );
};

export default SwitchVariante;
