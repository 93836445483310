import { createSlice, createAction } from '@reduxjs/toolkit';
import { caseDefaultRegister }from '@/store/utils';
import * as T from './types';
import * as F from './thunks';

const initialDefaultList = {
	actualPage: 0,
	pageSize: 0,
	pagesTotal: 0,
	itens: [],
}

const initialState: T.TTabelasStore = {
	artistas: initialDefaultList,
	artistasStatus: 'empty',
	allArtistas: [],
  allArtistasStatus: 'empty',

	editoras: initialDefaultList,
	editorasStatus: 'empty',
	allEditoras: [],
  allEditorasStatus: 'empty',

  allCategorias: [],
  allCategoriasStatus: 'empty',
	
	faixasEtarias: initialDefaultList,
	faixasEtariasStatus: 'empty',
	allFaixasEtarias: [],
  allFaixasEtariasStatus: 'empty',
	
	formatos: initialDefaultList,
	formatosStatus: 'empty',
	allFormatos: [],
  allFormatosStatus: 'empty',
	
	generos: initialDefaultList,
	generosStatus: 'empty',
	allGeneros: [],
  allGenerosStatus: 'empty',
	
	nacionalidades: initialDefaultList,
	nacionalidadesStatus: 'empty',
	allNacionalidades: [],
  allNacionalidadesStatus: 'empty',
};

const slice = createSlice({
	name: 'tabelas',
	initialState,
	reducers: {
	},
	extraReducers: ({ addCase }) => {
		caseDefaultRegister({addCase, fetch: F.fetchGetArtistasList, fields: ['artistasStatus', 'artistas']});
		caseDefaultRegister({addCase, fetch: F.fetchGetAllArtistas, fields: ['allArtistasStatus', 'allArtistas']});

		caseDefaultRegister({addCase, fetch: F.fetchGetEditorasList, fields: ['editorasStatus', 'editoras']});
		caseDefaultRegister({addCase, fetch: F.fetchGetAllEditoras, fields: ['allEditorasStatus', 'allEditoras']});

		caseDefaultRegister({addCase, fetch: F.fetchGetAllCategorias, fields: ['allCategoriasStatus', 'allCategorias']});

		caseDefaultRegister({addCase, fetch: F.fetchGetFaixasEtariasList, fields: ['faixasEtariasStatus', 'faixasEtarias']});
		caseDefaultRegister({addCase, fetch: F.fetchGetAllFaixasEtarias, fields: ['allFaixasEtariasStatus', 'allFaixasEtarias']});

		caseDefaultRegister({addCase, fetch: F.fetchGetFormatosList, fields: ['formatosStatus', 'formatos']});
		caseDefaultRegister({addCase, fetch: F.fetchGetAllFormatos, fields: ['allFormatosStatus', 'allFormatos']});

		caseDefaultRegister({addCase, fetch: F.fetchGetGenerosList, fields: ['generosStatus', 'generos']});
		caseDefaultRegister({addCase, fetch: F.fetchGetAllGeneros, fields: ['allGenerosStatus', 'allGeneros']});

		caseDefaultRegister({addCase, fetch: F.fetchGetNacionalidadesList, fields: ['nacionalidadesStatus', 'nacionalidades']});
		caseDefaultRegister({addCase, fetch: F.fetchGetAllNacionalidades, fields: ['allNacionalidadesStatus', 'allNacionalidades']});
	},
});

export default slice;
