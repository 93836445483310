import React from "react";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";

import { Sidebar } from "@/layouts";
import { HeaderSection } from "@/structures";
import { useFakeLoading, useToast } from "@/hooks";
import { getMangaInfoHooks } from "@/hooks/Cadastro";
import { TabsWithUnderline, Button } from "@/components";

import breadcrumbData from "./breadcrumbData.json";
import SaveContainer from "./saveContainer";
import { TSchema } from "./schema";
import StepA from "./stepA";
import StepB from "./stepB";
import StepC from "./stepC";
import StepD from "./stepD";
import StepE from "./stepE";

const Content = () => {
  const { id } = useParams();
  const isEditing = Number(id) > 0;

  const { isLoading: isFakeLoading } = useFakeLoading();

  const { setValues } = useFormikContext<TSchema>();
  const { mangaInfo, mangaInfoStatus } = getMangaInfoHooks(Number(id));

  const isLoading = isEditing ? mangaInfoStatus !== "success" : isFakeLoading;

  const handleUpdate = React.useCallback(() => {
    const genero: number[] = [];
    const roteiro: number[] = [];
    const arte: number[] = [];

    mangaInfo?.genero?.map(({ id }) => genero.push(id));
    mangaInfo?.roteiro?.map(({ id }) => roteiro.push(id));
    mangaInfo?.arte?.map(({ id }) => arte.push(id));

    setValues({
      id: mangaInfo?.id,
      nome: mangaInfo?.nome,
      tipo: mangaInfo?.tipo,

      subtitulo: mangaInfo?.subtitulo,
      original: mangaInfo?.original,

      editoraId: mangaInfo?.editora?.id,
      categoriaId: mangaInfo?.categoria?.id,
      statuId: mangaInfo?.status?.id,

      nacionalidadeId: mangaInfo?.nacionalidade?.id,
      demografiaId: mangaInfo?.demografia,
      formatoId: mangaInfo?.formato?.id,
      faixaEtariaId: mangaInfo?.faixaEtaria?.id,

      roteiro,
      arte,
      genero,

      userId: 0,
      isUpdate: true,
    });
  }, [mangaInfo]);

  React.useEffect(() => {
    if (!isLoading && isEditing) handleUpdate();
  }, [isLoading, isEditing]);

  return (
    <Sidebar>
      <HeaderSection
        breadcrumbData={breadcrumbData}
        isLoading={isLoading}
        align="left"
        title="Cadastro de mangás"
        subtitle="Inicie o processo de cadastro de mangás na plataforma."
      />
      <TabsWithUnderline
        isLoading={isLoading}
        align="left"
        data={[
          {
            id: 1,
            title: "01. Dados do mangá",
            content: <StepA isLoading={isLoading} />,
          },
          {
            id: 2,
            title: "02. Editora",
            content: <StepB isLoading={isLoading} />,
          },
          {
            id: 3,
            title: "03. Mangakás",
            content: <StepC isLoading={isLoading} />,
          },
          {
            id: 4,
            title: "04. Tags",
            content: <StepD isLoading={isLoading} />,
          },
          {
            id: 5,
            title: "05. Resumo",
            content: <StepE />,
          },
        ]}
      />

      <SaveContainer isLoading={isLoading} />
    </Sidebar>
  );
};

export default Content;
