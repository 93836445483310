import store from "@/store";

export const caseDefaultRegister = ({ addCase, fetch, fields }: any) => {
	addCase(fetch.pending, (state: any) => {
		state[fields[0]] = 'fetching';
		state[fields[1]] = null;
	});

	addCase(fetch.fulfilled, (state: any, action: any) => {
    if (action.payload === undefined) {
      state[fields[0]] = 'failure';
			state[fields[1]] = null;
    } else {
      state[fields[0]] = 'success';
      state[fields[1]] = action.payload;
    }
	});

	addCase(fetch.rejected, (state: any) => {
		state[fields[0]] = 'failure';
		state[fields[1]] = null;
	});
}

export const normalizeAuth = (body: any) => {
	const userId = store.getState().auth.user.id;

	return {
    ...body,
    userId,
  };
}