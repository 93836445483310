import React from "react";

type TSwitch = {
  id?: string;
  label?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Switch = ({ id, label, ...params }: TSwitch) => {
  return (
    <div className="flex items-center justify-center pt-5">
      <input
        {...params}
        type="checkbox"
        id={`hs-small-switch-${id}`}
        className="relative shrink-0 w-11 h-6 bg-gray-200 checked:bg-none checked:bg-link 
  border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200
  ring-1 ring-transparent focus:outline-none appearance-none before:inline-block before:w-5 before:h-5 before:bg-white checked:before:bg-blue-200 before:translate-x-0 
  checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition 
  before:ease-in-out before:duration-200"
      />
      <label
        htmlFor={`hs-small-switch-${id}`}
        className="text-sm text-tertiary ml-3"
      >
        {label}
      </label>
    </div>
  );
};

export default Switch;
