import React from 'react';
import { useSelector } from "react-redux";

import { getAllVolumesReprints } from "@/store/Cadastro/selectors";
import { fetchAllVolumesReprints } from "@/store/Cadastro/thunks";
import { TAllVolumesReprintsPayload } from "@/store/Cadastro/types";

import store from '@/store';
import { TStatus } from '@/store/types';

export type TAllVolumesReprintsHooks = {
  allVolumesReprints: TAllVolumesReprintsPayload
  allVolumesReprintsStatus: TStatus
}

export const getAllVolumesReprintsHooks = ({ id }: { id: number }): TAllVolumesReprintsHooks => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { allVolumesReprints, allVolumesReprintsStatus } = useSelector(getAllVolumesReprints);
  
  React.useEffect(() => {
    if (isLoading) {
      store.dispatch(fetchAllVolumesReprints({ id }));
      setIsLoading(false);
    }
  },[isLoading, id])

  return { allVolumesReprints, allVolumesReprintsStatus };
};
