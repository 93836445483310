type TDefaultTitle = {
  isLoading: boolean;
  text: string;
  icon?: JSX.Element;
};

const DefaultTitle = ({ isLoading, icon, text }: TDefaultTitle) => {
  if (isLoading)
    return (
      <div className="h-14">
        <div className="w-1/5 h-8 mt-1 mb-4 skeleton" />
      </div>
    );

  return (
    <h2 className="text-xl h-16 font-bold sm:text-2xl md:text-3xl text-tertiary flex items-center gap-4">
      {icon}
      {text}
    </h2>
  );
};

export default DefaultTitle;
