import { useSelector } from "react-redux";
import { getAllTabelas } from "@/store/Tabelas/selectors";

export const getFormatoNameHooks = (formatoId: number): string => {
  const { allFormatos } = useSelector(getAllTabelas);
  
  const Formato = allFormatos?.find(({ id }) => Number(formatoId) === id)?.nome;

  return Formato ?? '';
};
