import React from "react";
import { useFormikContext } from "formik";

import store from "@/store";
import { useFakeLoading } from "@/hooks";
import { FormWithContent } from "@/layouts";
import { getUserInfoHooks } from "@/hooks/Auth";
import { formatDate, formatGender } from "@/utils";
import { Table, FormikTableIcon } from "@/components";
import { getArtistasListHooks } from "@/hooks/Tabelas";
import { TArtistas, TArtistasPayload } from "@/store/Tabelas/types";
import {
  fetchDeleteArtista,
  fetchGetArtistasList,
} from "@/store/Tabelas/thunks";

import breadcrumbData from "./breadcrumbData.json";
import tableColumns from "./tableColumns.json";
import Form from "./form";

const Content = () => {
  const [tableData, setTableData] = React.useState<Object[]>([]);

  const { setValues } = useFormikContext();

  const { token, config, isAdmin } = getUserInfoHooks();
  const { isLoading: isFakeLoading } = useFakeLoading();
  const { artistas, artistasStatus } = getArtistasListHooks({
    page: 1,
    limit: config.tabelas.totalItens,
  });

  const isLoading = artistasStatus !== "success" || isFakeLoading;

  const handleDelete = async (id: number) => {
    await store.dispatch(fetchDeleteArtista({ id, token, config }));
    await store.dispatch(
      fetchGetArtistasList({ page: 1, limit: config.tabelas.totalItens })
    );
  };

  const handleUpdate = (artista: TArtistas) => {
    setValues({
      id: artista?.id,
      nome: artista?.nome,
      genero: artista?.genero ?? 0,
      dataNascimento: artista?.dataNascimento ?? undefined,
      isUpdate: true,
    });
  };

  const handlePagination = (id: number) => {
    store.dispatch(
      fetchGetArtistasList({ page: id, limit: config.tabelas.totalItens })
    );
  };

  const handleSearch = (p: string) => {
    store.dispatch(
      fetchGetArtistasList({
        page: 1,
        limit: config.tabelas.totalItens,
        search: p,
      })
    );
  };

  const processTableData = (data: TArtistasPayload) => {
    return data?.itens.map((item) => ({
      name: item?.nome,
      gender: formatGender(item?.genero),
      date: formatDate(item?.dataNascimento),
      actions: (
        <div className="flex gap-x-4 justify-end w-full">
          <FormikTableIcon
            disabled={!isAdmin}
            type="update"
            onClick={() => handleUpdate(item)}
          />
          <FormikTableIcon
            disabled={!isAdmin}
            type="delete"
            onClick={() => handleDelete(item?.id)}
          />
        </div>
      ),
    }));
  };

  React.useEffect(() => {
    if (!isLoading) setTableData(processTableData(artistas));
  }, [isLoading, artistas]);

  return (
    <FormWithContent
      isAdmin={isAdmin}
      isLoading={isLoading}
      title="Artistas"
      breadcrumbData={breadcrumbData}
      formSubtitle="Cadastro de um novo artista na plataforma."
      contentSubtitle="Artistas cadastrados."
      form={<Form isLoading={isLoading} />}
      content={
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={isLoading}
          onSearch={handleSearch}
          pagination={{
            actualPage: artistas?.actualPage,
            pagesTotal: artistas?.pagesTotal,
            handlePagination,
          }}
        />
      }
    />
  );
};

export default Content;
