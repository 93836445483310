import { Formik } from "formik";

import store from "@/store";
import {
  fetchSaveNacionalidade,
  fetchGetNacionalidadesList,
} from "@/store/Tabelas/thunks";
import { getUserInfoHooks } from "@/hooks/Auth";

import { validationSchema, TSchema } from "./schema";
import initialValues from "./initialValues.json";
import Content from "./content";

const Nacionalidade = () => {
  const { token, config } = getUserInfoHooks();

  const sendRequest = async (values: TSchema) => {
    const { isUpdate } = values;
    const method = !isUpdate ? "POST" : "PUT";
    await store.dispatch(
      fetchSaveNacionalidade({ values, method, token, config })
    );
    await store.dispatch(
      fetchGetNacionalidadesList({ page: 1, limit: config.tabelas.totalItens })
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await sendRequest(values);
        resetForm();
      }}
    >
      {() => <Content />}
    </Formik>
  );
};

export default Nacionalidade;
