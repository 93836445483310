import { BookOpenIcon } from "@heroicons/react/20/solid";
import { useFormikContext } from "formik";

import { Input, Select } from "@/components";
import SaveContainer from "./saveContainer";
import { TSchema } from "./schema";

import { getAllEditorasHooks } from "@/hooks/Tabelas";

const NewMangaForm = ({ isLoading }: { isLoading: boolean }) => {
  const { allEditoras } = getAllEditorasHooks();

  const { values, errors, setFieldValue, isSubmitting } =
    useFormikContext<TSchema>();

  const { flagNewManga } = values;

  return (
    <>
      {flagNewManga && (
        <>
          <h3 className="flex gap-x-3 items-center font-semibold text-primary py-5">
            <BookOpenIcon className="h-6 text-blue-600" /> Solicitação de novo
            Mangá
          </h3>
          <div className="sm:flex sm:justify-between">
            <div className="py-5 w-full flex flex-col justify-between">
              <div className="grid grid-cols-4 gap-x-5">
                <div className="col-span-3">
                  <Input
                    required
                    label="Nome do Mangá"
                    isLoading={isLoading}
                    value={values?.manga ?? undefined}
                    error={isSubmitting && !!errors?.manga}
                    onChange={({ target }) =>
                      setFieldValue("manga", target.value)
                    }
                  />
                </div>
                <Select
                  label="Editora"
                  isLoading={isLoading}
                  options={allEditoras?.map(({ id, nome }) => ({
                    label: nome,
                    value: id.toString(),
                  }))}
                  value={values?.editoraId?.toString() ?? undefined}
                  error={isSubmitting && !!errors?.editoraId}
                  onChange={({ target }) =>
                    setFieldValue("editoraId", Number(target.value))
                  }
                />
              </div>
              <SaveContainer isLoading={isLoading} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewMangaForm;
