import { createAsyncThunk } from '@reduxjs/toolkit';

import { TDefaultItem, TDefaultPagination } from '@/store/types';
import { TSchema } from '@/pages/Tabelas/Artista/schema';
import { normalizeAuth } from "@/store/utils";
import * as T from '@/store/Tabelas/types';
import { useToast } from '@/hooks';
import api from "@/services/api";
import { TUserAdminConfig } from '@/store/Auth/types';

export const fetchGetArtistasList = createAsyncThunk(
	'tabelas/artistas/list',
	async ({ page, limit, search }: TDefaultPagination) => {
		const controller = new AbortController();

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/list-artistas/${page ?? 1}${limit?`/${limit}`:``}${search?`/${search}`:``}`,
		});	
		return data as T.TArtistasPayload;
	}
);

export const fetchGetAllArtistas = createAsyncThunk(
	'tabelas/artistas/listAll',
	async () => {
		const controller = new AbortController();

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/list-all-artistas`,
		});	
		return data as TDefaultItem[];
	}
);

export const fetchSaveArtista = createAsyncThunk(
	'tabelas/artistas/save',
	async ({ values, method, token, config }: { values: TSchema, method: 'POST' | 'PUT', token: string, config: TUserAdminConfig }) => {
		try {
			const url = method === 'POST' ? `v2/add-new-artista` : `v2/edt-artista`;
			const controller = new AbortController();

			await api({
				url,
				method,	
				signal: controller.signal,
				data: normalizeAuth(values),
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Cadastrado com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes,
			});
		} catch (error) {
			useToast({ 
				msg: 'Falha no cadastro. Tente novamente mais tarde.', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);

export const fetchDeleteArtista = createAsyncThunk(
	'tabelas/artistas/delete',
	async ({ id, token, config }: { id: number, token: string, config: TUserAdminConfig }) => {
		try {
			const controller = new AbortController();

			await api({
				method: 'DELETE',
				signal: controller.signal,
				url: `v2/del-artista/${id}`,
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	

			useToast({ 
				msg: 'Excluído com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		} catch (error) {
			useToast({ 
				msg: 'Informação não encontrada', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);
