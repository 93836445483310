import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwipeGrid from "./swipeGrid";
import SwipeColumn from "./swipeColumn";

const Roadmap = () => (
  <>
    <Swiper
      slidesPerView={1}
      centeredSlides={true}
      grabCursor={true}
      pagination={{ clickable: true }}
      modules={[Pagination]}
      className="h-[520px] w-full pt-5"
    >
      <SwiperSlide className="w-full text-center tracking-wide">
        <SwipeGrid>
          <SwipeColumn
            title="Fase 01"
            subtitle="2021"
            itens={[
              "Portal Meus Mangás v.1.0",
              "Painel Administrativo v.1.0",
              "API Meus Mangás v.1.0",
            ]}
          />
          <SwipeColumn
            title="Fase 01"
            subtitle="2021"
            itens={[
              "Portal Meus Mangás v.1.0",
              "Painel Administrativo v.1.0",
              "API Meus Mangás v.1.0",
            ]}
          />
        </SwipeGrid>
      </SwiperSlide>
      <SwiperSlide className="w-full text-center tracking-wide">
        <SwipeGrid>
          <SwipeColumn
            title="Fase 01"
            subtitle="2021"
            itens={[
              "Portal Meus Mangás v.1.0",
              "Painel Administrativo v.1.0",
              "API Meus Mangás v.1.0",
            ]}
          />
          <SwipeColumn
            title="Fase 01"
            subtitle="2021"
            itens={[
              "Portal Meus Mangás v.1.0",
              "Painel Administrativo v.1.0",
              "API Meus Mangás v.1.0",
            ]}
          />
        </SwipeGrid>
      </SwiperSlide>
    </Swiper>
  </>
);

export default Roadmap;
