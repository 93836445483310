import React from "react";
import classNames from "classnames";
import { Breadcrumb } from "@/components";
import { TBreadcrumbData } from "@/components/Navigations/Breadcrumb";

type THeaderSection = {
  isLoading: boolean;
  debug?: boolean;
  title: string;
  subtitle: string;
  breadcrumbData?: TBreadcrumbData[];
  align?: "center" | "left" | "right";
  noborder?: boolean;
};

const HeaderSection = ({
  isLoading,
  debug = false,
  title,
  subtitle,
  align,
  breadcrumbData,
  noborder = false,
}: THeaderSection) => {
  return (
    <div
      className={classNames("mx-auto py-5 px-0 ", {
        "border border-green-500": debug,
        "text-left": align === "left",
        "text-center": align === "center",
        "text-right": align === "right",
        "border-b border-gray-300": !noborder,
      })}
    >
      {breadcrumbData && (
        <Breadcrumb isLoading={isLoading} data={breadcrumbData} />
      )}

      {isLoading ? (
        <div
          className={classNames("w-full flex flex-col", {
            "items-left": align === "left",
            "items-center": align === "center",
            "items-right": align === "right",
          })}
        >
          <div className="w-1/4 h-9 mt-1 mb-4 skeleton" />
          <div className="w-1/2 h-6 mb-1 skeleton" />
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold sm:text-3xl md:text-4xl text-tertiary">
            {title}
          </h2>
          <p className="mt-4 md:text-lg text-tertiary">{subtitle}</p>
        </>
      )}
    </div>
  );
};

export default HeaderSection;
