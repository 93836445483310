import React from 'react';
import { useSelector } from "react-redux";
import { getAllNacionalidades } from "@/store/Tabelas/selectors";
import { fetchGetAllNacionalidades } from "@/store/Tabelas/thunks";
import { TStatus, TDefaultItem } from '@/store/types';
import store from '@/store';

export type TAllNacionalidadesHooks = {
  allNacionalidades: TDefaultItem[]
  allNacionalidadesStatus: TStatus
}

export const getAllNacionalidadesHooks = (): TAllNacionalidadesHooks => {
  const { allNacionalidades, allNacionalidadesStatus } = useSelector(getAllNacionalidades);
  
  React.useEffect(() => {
    store.dispatch(fetchGetAllNacionalidades());
  },[]);

  return { allNacionalidades, allNacionalidadesStatus };
};
