import React from 'react';
import { useSelector } from "react-redux";
import { getFaixasEtariasList } from "@/store/Tabelas/selectors";
import { fetchGetFaixasEtariasList } from "@/store/Tabelas/thunks";
import { TFaixasEtariasPayload } from "@/store/Tabelas/types";
import store from '@/store';
import { TStatus, TDefaultPagination } from '@/store/types';

export type TFaixasEtariasListHooks = {
  faixasEtarias: TFaixasEtariasPayload
  faixasEtariasStatus: TStatus
}

export const getFaixasEtariasListHooks = ({ page, limit }: TDefaultPagination): TFaixasEtariasListHooks => {
  const { faixasEtarias, faixasEtariasStatus } = useSelector(getFaixasEtariasList);
  
  React.useEffect(() => {
    store.dispatch(fetchGetFaixasEtariasList({ page, limit }));
  }, []);

  return { faixasEtarias, faixasEtariasStatus };
};
