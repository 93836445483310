import { useNavigate } from "react-router-dom";

type TSingleItem = {
  sidebarKey: string;
  label: string;
  icon: JSX.Element;
  route?: string;
  onClick?: () => void;
};

const SingleItem = ({ sidebarKey, icon, label, route }: TSingleItem) => {
  const navigate = useNavigate();

  return (
    <li key={sidebarKey}>
      <a
        className="cursor-pointer flex items-center gap-x-3.5 py-2 px-2.5 text-sm rounded-md text-primary hover:text-link hover:bg-gray-200"
        onClick={() => navigate((route as string) ?? undefined)}
      >
        {icon}
        {label}
      </a>
    </li>
  );
};

export default SingleItem;
