import { useFormikContext } from "formik";
import { Input, Button } from "@/components";
import { TSchema } from "./schema";

const Form = ({ isLoading }: { isLoading: boolean }) => {
  const { values, errors, setFieldValue, resetForm, handleSubmit } =
    useFormikContext<TSchema>();

  return (
    <>
      <div className="grid grid-cols-4 gap-5 pt-5">
        <div className="col-span-1 bg-gray-200 rounded-xl flex items-center justify-center">
          <Button small label="Upload logo" isLoading={isLoading} />
        </div>
        <div className="col-span-3">
          <div className="grid grid-cols-2 gap-5">
            <Input
              required
              label="Editora"
              isLoading={isLoading}
              value={values.editora}
              error={!!errors.editora}
              onChange={({ target }) => setFieldValue("editora", target.value)}
            />
            <Input
              label="Site"
              isLoading={isLoading}
              value={values.site}
              error={!!errors.site}
              onChange={({ target }) => setFieldValue("site", target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-5 pb-5 justify-end">
        <Button
          small
          label="Reset"
          outline
          isLoading={isLoading}
          onClick={() => resetForm()}
        />
        <Button
          small
          label="Salvar"
          isLoading={isLoading}
          onClick={() => handleSubmit()}
        />
      </div>
    </>
  );
};

export default Form;
