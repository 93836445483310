import React from 'react';
import { useSelector } from "react-redux";
import { getAllGeneros } from "@/store/Tabelas/selectors";
import { fetchGetAllGeneros } from "@/store/Tabelas/thunks";
import { TStatus, TDefaultItem } from '@/store/types';
import store from '@/store';

export type TAllGenerosHooks = {
  allGeneros: TDefaultItem[]
  allGenerosStatus: TStatus
}

export const getAllGenerosHooks = (): TAllGenerosHooks => {
  const { allGeneros, allGenerosStatus } = useSelector(getAllGeneros);
  
  React.useEffect(() => {
    store.dispatch(fetchGetAllGeneros());
  },[]);

  return { allGeneros, allGenerosStatus };
};
