import { useSelector } from "react-redux";

import { getUserInfo } from "@/store/Auth/selectors";
import * as Navbar from "@/structures/Navbar";
import { Avatar } from "@/components";
import classNames from "classnames";

type THeader = {
  debug?: boolean;
};

const Header = ({ debug = false }: THeader): JSX.Element => {
  const { user } = useSelector(getUserInfo);

  return (
    <header
      id="header-meus-mangas"
      className="flex flex-wrap lg:justify-start lg:flex-nowrap w-full bg-primary text-sm py-4"
    >
      <nav
        className={classNames(
          "max-w-[85rem] w-full mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between",
          {
            "border border-green-500": debug,
          }
        )}
        aria-label="Global"
      >
        <Navbar.Logo />

        <div className="flex items-start gap-x-5">
          <div
            className={classNames(
              "flex-col text-right sm:flex-row sm:items-center w-full h-full",
              {
                "border border-green-500": debug,
              }
            )}
          >
            <Navbar.Button />
            <Navbar.List />
          </div>
          <Avatar src={user?.avatar} rounded />
        </div>
      </nav>
    </header>
  );
};

export default Header;
