import React from 'react';

type TUseFakeLoading = {
  isLoading: boolean
}

const useFakeLoading = (time: number = 600) => {
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => setLoading(false), time)
  },[time]);

  return {isLoading} as TUseFakeLoading;
}

export default useFakeLoading;