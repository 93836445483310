import { TSchema } from '@/pages/Cadastro/Manga/add/schema';
import { TUserAdminConfig } from '@/store/Auth/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TDefaultPagination } from '@/store/types';
import { normalizeAuth } from "@/store/utils";
import * as T from '@/store/Cadastro/types';
import { useToast } from '@/hooks';
import api from "@/services/api";

export const fetchSearchAllMangas = createAsyncThunk(
	'cadastro/mangas/search',
	async ({ page, limit, search }: TDefaultPagination) => {
		const controller = new AbortController();

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/search-all-mangas/${page ?? 1}${limit?`/${limit}`:``}${search?`/${search}`:``}`,
		});	
		return data as T.TSearchAllMangasPayload;
	}
);

export const fetchGetMangaInfo = createAsyncThunk(
	'cadastro/mangas/info',
	async (id: number) => {
		const controller = new AbortController();

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/manga/${id}`,
		});	
		return data as T.TSearchAllMangasPayload;
	}
);

export const fetchSaveManga = createAsyncThunk(
	'cadastro/mangas/add',
	async ({ values, method, token, config }: { values: TSchema, method: 'POST' | 'PUT', token: string, config: TUserAdminConfig }) => {
		try {
			const url = method === 'POST' ? `v2/add-new-manga` : `v2/edt-manga`;
			const controller = new AbortController();

			await api({
				url,
				method,	
				signal: controller.signal,
				data: normalizeAuth(values),
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Cadastrado com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes,
			});
		} catch (error) {
			useToast({ 
				msg: 'Falha no cadastro. Tente novamente mais tarde.', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);

export const fetchDeleteManga = createAsyncThunk(
	'cadastro/mangas/add',
	async ({ id, token, config }: { id: number, token: string, config: TUserAdminConfig }) => {
		try {
			const controller = new AbortController();

			await api({
				url: `v2/del-manga/${id}`,
				method: 'DELETE',	
				signal: controller.signal,
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Excluído com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		} catch (error) {
			useToast({ 
				msg: 'Informação não encontrada', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);
