type TDefaultLabel = {
  text: string;
  isLoading?: boolean;
};

const DefaultLabel = ({ text, isLoading = false }: TDefaultLabel) => {
  if (isLoading) return <div className="w-[125px] h-7 mt-1 skeleton" />;

  return <p className="text-base text-tertiary">{text}</p>;
};

export default DefaultLabel;
