type TFile = {
  base64: string; 
  size: number; 
  filename: string; 
  filetype: string;
}

export function base64(file: any, callback: (p: TFile) => void) {
  const coolFile: TFile = {
    base64: '',
    size: 0,
    filename: '',
    filetype: ''
  };

  function readerOnload(e: any) {
    const base64 = btoa(e.target.result);
    coolFile.base64 = base64;
    callback(coolFile);
  }

  const reader = new FileReader();
  reader.onload = readerOnload;

  const newFile = file?.[0];
  /// return fname?.slice((fname?.lastIndexOf(".") - 1 >>> 0) + 2);
  /// https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript
  /// coolFile?.filetype = newFile.type; /// OLD
  coolFile.size = newFile.size;
  coolFile.filename = newFile.name;

  let fname = coolFile.filename;
  // @ts-ignore
  fname = fname.slice((fname.lastIndexOf('.') - 1 > 0) + 2);

  coolFile.filetype = `.${fname}`;
  reader.readAsBinaryString(newFile);
}
