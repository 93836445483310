import { createSlice, createAction } from '@reduxjs/toolkit';
import { caseDefaultRegister }from '@/store/utils';
import * as T from './types';
import * as F from './thunks';

const initialDefaultList = {
	actualPage: 0,
	pageSize: 0,
	pagesTotal: 0,
	itens: [],
}

const initialState: T.TSuporteStore = {
	suportes: initialDefaultList,
	suportesStatus: 'empty'
};

const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
	},
	extraReducers: ({ addCase }) => {
		caseDefaultRegister({addCase, fetch: F.fetchSuporteList, fields: ['suportesStatus', 'suportes']});
	},
});

export default slice;
