import { suportTypeIcon } from "@/pages/Suporte/utils";

type TModalInfo = {
  id: number;
  tipo: number;
  colspan?: number;
  text: string;
  align?: "start" | "center" | "end";
};

const TicketLine = ({
  id = 0,
  tipo = 1,
  text,
  colspan = 1,
  align = "end",
}: TModalInfo) => (
  <p
    className={`col-span-${colspan} flex al justify-${align} items-center gap-2 text-sm text-gray-400 py-2`}
  >
    {suportTypeIcon[tipo]} {text} - Ticket #{id}
  </p>
);

export default TicketLine;
