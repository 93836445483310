import React from "react";
import classNames from "classnames";
import "./style.css";

type TLabel = {
  labelColor?: "default" | "white";
  isLoading: boolean;
  id?: string;
  label: string;
  error?: boolean;
  required?: boolean;
};

const Label = ({
  labelColor = "default",
  isLoading,
  id,
  label,
  error,
  required,
}: TLabel): JSX.Element => {
  if (isLoading) return <div className="h-5 w-1/5 skeleton mb-3" />;

  return (
    <div className="flex justify-between">
      <label
        htmlFor={id}
        className={classNames("component-label", {
          "font-semibold text-primary": required && labelColor === "default",
          "text-tertiary": !required && labelColor === "default",
          "text-slate-200": labelColor === "white",
        })}
      >
        {required && !isLoading ? `* ${label}` : isLoading ? "" : label}
      </label>
      {error && (
        <span className="component-label-error">* campo obrigatório</span>
      )}
    </div>
  );
};

export default Label;
