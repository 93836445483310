import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getUserInfoHooks } from "@/hooks/Auth";

type TSubitens = {
  id: number;
  label: string;
  route?: string;
};

type TDropdownItem = {
  sidebarKey: string;
  icon: JSX.Element;
  label: string;
  subitens: TSubitens[];
};

const DropdownItem = ({ sidebarKey, icon, label, subitens }: TDropdownItem) => {
  const { config } = getUserInfoHooks();
  const navigate = useNavigate();

  const IconUp = () => (
    <svg
      className="hs-accordion-active:block ml-auto hidden w-3 h-3 text-gray-600 group-hover:text-gray-500"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
    </svg>
  );

  const IconDown = () => (
    <svg
      className="hs-accordion-active:hidden ml-auto block w-3 h-3 text-gray-600 group-hover:text-gray-500"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      ></path>
    </svg>
  );

  return (
    <li
      key={sidebarKey}
      className={classNames("", {
        "hs-accordion": !config?.sidebar?.menuExpandido,
      })}
      id={sidebarKey}
    >
      <a
        className={classNames(
          "cursor-pointer flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-link hs-accordion-active:bg-gray-200 text-sm text-primary hover:text-link rounded-md hover:bg-gray-200",
          {
            "text-link bg-gray-200": config?.sidebar?.menuExpandido,
            "hs-accordion-toggle": !config?.sidebar?.menuExpandido,
          }
        )}
      >
        {icon}
        {label}
        <IconUp />
        <IconDown />
      </a>
      <div
        id={sidebarKey}
        className={classNames("w-full", {
          "hs-accordion-content overflow-hidden transition-[height] duration-300":
            !config?.sidebar?.menuExpandido,
        })}
        style={{ height: !config?.sidebar?.menuExpandido ? 0 : "auto" }}
      >
        <ul className="pt-2">
          {subitens?.map(({ label, route }, index) => (
            <li key={`sidebar-subitem-${index}`}>
              <a
                className="cursor-pointer flex items-center gap-x-3.5 py-2 px-2.5 pl-11 text-sm rounded-md text-primary hover:text-link hover:bg-gray-200"
                onClick={() => navigate((route as string) ?? "/")}
              >
                {label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default DropdownItem;
