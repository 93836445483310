import React from 'react';
import { useSelector } from "react-redux";
import { getAllEditoras } from "@/store/Tabelas/selectors";
import { fetchGetAllEditoras } from "@/store/Tabelas/thunks";
import { TStatus, TDefaultItem } from '@/store/types';
import store from '@/store';

export type TAllEditorasHooks = {
  allEditoras: TDefaultItem[]
  allEditorasStatus: TStatus
}

export const getAllEditorasHooks = (): TAllEditorasHooks => {
  const { allEditoras, allEditorasStatus } = useSelector(getAllEditoras);
  
  React.useEffect(() => {
    store.dispatch(fetchGetAllEditoras());
  },[]);

  return { allEditoras, allEditorasStatus };
};
