const MobileButton = (): JSX.Element => (
  <div className="lg:hidden">
    <button
      type="button"
      className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border border-secondary font-medium bg-primary text-gray-400 shadow-sm align-middle hover:bg-gray-700/[.25] focus:outline-none   transition-all text-sm"
      data-hs-collapse="#navbar-dark"
      aria-controls="navbar-dark"
      aria-label="Toggle navigation"
    >
      <svg
        className="hs-collapse-open:hidden w-4 h-4 text-secondary"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
      <svg
        className="hs-collapse-open:block hidden w-4 h-4 text-secondary"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </button>
  </div>
);

export default MobileButton;
