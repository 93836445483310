import React from 'react';
import { useSelector } from "react-redux";
import { getGetMangaInfo } from "@/store/Cadastro/selectors";
import { fetchGetMangaInfo } from "@/store/Cadastro/thunks";
import { TMangaInfo } from "@/store/Cadastro/types";
import { TStatus } from '@/store/types';
import store from '@/store';

export type TMangaInfoHooks = {
  mangaInfo: TMangaInfo
  mangaInfoStatus: TStatus
}

export const getMangaInfoHooks = (id: number): TMangaInfoHooks => {
  const [lastID, setLastID] = React.useState(0)

  const { mangaInfo, mangaInfoStatus } = useSelector(getGetMangaInfo);
  
  if ((mangaInfoStatus === 'empty' || id !== lastID) && id > 0) {
    setLastID(id);
    store.dispatch(fetchGetMangaInfo(id));
  }

  return { mangaInfo, mangaInfoStatus };
};
