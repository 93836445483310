import React, { RefObject } from "react";
import { useFormikContext } from "formik";

import { base64 } from "@/utils";
import { ImageCover } from "@/components";
import SwitchVariante from "./switchFlag";
import { TSchema } from "./schema";

const VolumeCover = () => {
  const { setFieldValue } = useFormikContext<TSchema>();
  const inputRef: React.MutableRefObject<HTMLInputElement | null> =
    React.useRef(null);

  const [src, setSrc] = React.useState("");
  const [isLoadingCover, setisLoadingCover] = React.useState(false);

  const loadImagem = React.useCallback(() => {
    /* @ts-ignore */
    const file = inputRef?.current?.files;
    if (file?.length === 0) return;

    setisLoadingCover(true);
    setSrc("");

    base64(file, ({ filetype, base64 }: any) => {
      const image = `data:image/${filetype.replace(".", "")};base64,${base64}`;

      setSrc(image);
      setFieldValue("image", base64);
      setTimeout(() => setisLoadingCover(false), 2000);
    });
  }, [inputRef, base64]);

  return (
    <div className="py-5 w-full sm:w-1/3 md:w-1/4 xl:w-1/5 h-[310px]">
      <input
        ref={inputRef}
        className="hidden"
        type="file"
        id="doc-file"
        onChange={() => loadImagem()}
      />

      <ImageCover
        onClick={() => inputRef?.current?.click()}
        isLoading={isLoadingCover}
        src={src}
      />

      <SwitchVariante />
    </div>
  );
};

export default VolumeCover;
