import { useFormikContext } from "formik";

import { Select, Textarea } from "@/components";
import SaveContainer from "./saveContainer";
import { TSchema } from "./schema";

import { ChatBubbleLeftIcon } from "@heroicons/react/20/solid";

const SugestionsForm = ({ isLoading }: { isLoading: boolean }) => {
  const { values, errors, setFieldValue, isSubmitting } =
    useFormikContext<TSchema>();

  const { flagSugestions } = values;

  return (
    <>
      {flagSugestions && (
        <>
          <h3 className="flex gap-x-3 items-center font-semibold text-primary py-5">
            <ChatBubbleLeftIcon className="h-6 text-green-600" /> Cadastro
            Críticas / Sugestões
          </h3>
          <div className="sm:flex sm:justify-between">
            <div className="py-5 w-full flex flex-col justify-between">
              <div className="grid grid-cols-4 gap-x-5">
                <div className="col-span-3">
                  <Textarea
                    required
                    label="Descrição"
                    isLoading={isLoading}
                    numberOfRows={6}
                    value={values?.descricao ?? undefined}
                    error={isSubmitting && !!errors?.descricao}
                    onChange={({ target }) =>
                      setFieldValue("descricao", target.value)
                    }
                  />
                </div>
                <div className="col-span-1 flex flex-col">
                  <Select
                    required
                    label="Site"
                    isLoading={isLoading}
                    options={[
                      { label: "Meus Mangás", value: "P" },
                      { label: "painel Administrativo", value: "A" },
                    ]}
                    value={values?.site ?? undefined}
                    error={isSubmitting && !!errors?.site}
                    onChange={({ target }) =>
                      setFieldValue("site", target.value)
                    }
                  />
                </div>
              </div>
              <SaveContainer isLoading={isLoading} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SugestionsForm;
