import * as yup from "yup";

export type TSchema = {
  id: number;
  nome: string;
  userId: number;
  isUpdate: boolean;
};

export const validationSchema = yup.object().shape({
  id: yup.number(),
  nome: yup.string().required().max(255),
  userId: yup.number().min(0),
  isUpdate: yup.boolean(),
});
