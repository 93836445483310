import React from "react";
import classnames from "classnames";
import { InputSearch } from "@/components";

type TColumn = {
  label: string;
  align: string;
  width?: number;
};

type TTable = {
  isLoading: boolean;
  columns: TColumn[];
  data: Object[];
  search?: boolean;
  searchFilters?: JSX.Element;
  onSearch?: (p: string) => void;
  pagination?: {
    actualPage: number;
    pagesTotal: number;
    handlePagination?: (p: number) => void | undefined;
  };
} & React.TableHTMLAttributes<HTMLTableElement>;

const Table = ({
  isLoading,
  columns,
  data,
  search = true,
  onSearch,
  searchFilters,
  pagination = {
    actualPage: 1,
    pagesTotal: 1,
  },
}: TTable): JSX.Element => {
  const { actualPage, pagesTotal, handlePagination } = pagination;

  const PaginationIcon = ({
    isLoading,
    active,
    label,
    onClick,
  }: {
    isLoading: boolean;
    active?: boolean;
    label: number | string;
    onClick: () => void;
  }) => {
    if (isLoading) return <div className="h-8 w-8 skeleton" />;

    return (
      <a
        className={classnames(
          "cursor-pointer w-8 h-8 rounded-md flex items-center justify-center text-sm font-medium transition",
          {
            "text-tertiary hover:bg-blue-100":
              Number(label) !== Number(actualPage),
            "bg-link border border-link hover:bg-tertiary text-white":
              Number(label) === Number(actualPage),
          }
        )}
        aria-current="page"
        onClick={onClick}
      >
        {label}
      </a>
    );
  };

  const Pagination = ({ isLoading }: { isLoading: boolean }) => (
    <div className="py-0 px-1 flex justify-end">
      <nav className="flex items-center justify-end space-x-1">
        <PaginationIcon
          label="«"
          isLoading={isLoading}
          onClick={() => handlePagination?.(1)}
        />
        <PaginationIcon
          label={actualPage}
          isLoading={isLoading}
          onClick={() => handlePagination?.(actualPage)}
        />
        {pagesTotal > Number(actualPage) + 1 && (
          <PaginationIcon
            label={Number(actualPage) + 1}
            isLoading={isLoading}
            onClick={() => handlePagination?.(Number(actualPage) + 1)}
          />
        )}
        {pagesTotal > Number(actualPage) + 1 && (
          <PaginationIcon
            label={Number(actualPage) + 2}
            isLoading={isLoading}
            onClick={() => handlePagination?.(Number(actualPage) + 2)}
          />
        )}
        {pagesTotal > Number(actualPage) + 1 && (
          <PaginationIcon
            label="»"
            isLoading={isLoading}
            onClick={() => handlePagination?.(pagesTotal)}
          />
        )}
      </nav>
    </div>
  );

  const THeader = ({ columns }: { columns: TColumn[] }) => (
    <thead>
      <tr>
        {columns?.map(({ label, align, width }: TColumn) => (
          <th
            scope="col"
            style={{ width: width ? width : "auto" }}
            className={`px-5 py-3 text-${align} text-xs font-medium uppercase`}
          >
            {label}
          </th>
        ))}
      </tr>
    </thead>
  );

  const TBody = ({
    isLoading,
    data,
  }: {
    isLoading: boolean;
    data: Object[];
  }) => (
    <tbody>
      {data?.map((row: Object) => {
        const data: string[][] = Object.entries(row); // [column , value]
        return (
          <tr
            className={classnames(
              "text-primary odd:bg-white even:bg-slate-100",
              {
                "hover:bg-gray-200 hover:text-link": !isLoading,
              }
            )}
          >
            {data?.map((item, index) => (
              <td className={`px-5 whitespace-nowrap text-sm font-medium`}>
                <div
                  style={{
                    width: columns[index].width ? columns[index].width : "auto",
                  }}
                  className="h-10 flex items-center truncate"
                >
                  {isLoading ? <div className="w-1/2 h-4 skeleton" /> : item[1]}
                </div>
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <div className="flex flex-col mb-5">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <div className="grid grid-cols-2 my-5 items-center gap-5">
              {search && (
                <>
                  <InputSearch isLoading={isLoading} onSearch={onSearch} />
                  {searchFilters}
                </>
              )}
              {pagesTotal > 1 && <Pagination isLoading={isLoading} />}
            </div>

            <table className="min-w-full divide-y bg-primary text-white divide-gray-200">
              <THeader columns={columns} />
              <TBody data={data} isLoading={isLoading} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
