import { useSelector } from "react-redux";
import { getAllTabelas } from "@/store/Tabelas/selectors";

export const getCategoriaNameHooks = (categoriaId: number): string => {
  const { allCategorias } = useSelector(getAllTabelas);
  
  const Categoria = allCategorias?.find(({ id }) => Number(categoriaId) === id)?.nome;

  return Categoria ?? '';
};
