const ReprintFooter = ({
  isLoading,
  nome,
  numero,
}: {
  isLoading: boolean;
  nome: string;
  numero: number;
}) => (
  <div className="mt-3 flex justify-between items-end px-1">
    {isLoading ? (
      <div className="skeleton w-1/2 h-4" />
    ) : (
      <div className="w-full text-xs text-tertiary">{nome}</div>
    )}

    {isLoading ? (
      <div className="skeleton w-6 h-4" />
    ) : (
      <div className="w-16 text-sm text-right text-link font-bold">
        nº {numero}
      </div>
    )}
  </div>
);

export default ReprintFooter;
