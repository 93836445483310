// import { getUserInfoHooks } from "@/hooks/Auth";

import {
  BookOpenIcon,
  TableCellsIcon,
  Cog6ToothIcon,
  // CurrencyDollarIcon,
  MapIcon,
  // UsersIcon,
  ChatBubbleLeftEllipsisIcon,
} from "@heroicons/react/20/solid";

import HomeItem from "./homeItem";
import SingleItem from "./singleItem";
import DropdownItem from "./dropdownItem";

const Sidebar = () => {
  // const { isAdmin } = getUserInfoHooks();

  return (
    <div
      id="docs-sidebar"
      className="hidden lg:visible w-auto border-r border-gray-300 pb-10 pt-4 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:right-auto lg:bottom-0"
    >
      <nav
        className="hs-accordion-group p-6 w-full flex flex-col flex-wrap overflow-x-hidden"
        data-hs-accordion-always-open
      >
        <ul className="space-y-1.5">
          <HomeItem sidebarKey="sidebar-home" />

          <DropdownItem
            sidebarKey="sidebar-account"
            label="Cadastro"
            icon={<BookOpenIcon className="h-5" />}
            subitens={[
              { id: 1, label: "Mangás", route: "/manga/visualizar" },
              { id: 2, label: "Volumes", route: "/volume/visualizar" },
              {
                id: 4,
                label: "Reimpressões",
                route: "/reimpressao/visualizar",
              },
            ]}
          />

          <DropdownItem
            sidebarKey="sidebar-tables"
            label="Tabelas"
            icon={<TableCellsIcon className="h-5" />}
            subitens={[
              { id: 1, label: "Artistas", route: "/tabelas/artistas" },
              { id: 2, label: "Editoras", route: "/tabelas/editoras" },
              {
                id: 3,
                label: "Faixas-Etárias",
                route: "/tabelas/faixas-etarias",
              },
              { id: 4, label: "Formatos", route: "/tabelas/formatos" },
              { id: 5, label: "Gêneros", route: "/tabelas/generos" },
              {
                id: 6,
                label: "Nacionalidades",
                route: "/tabelas/nacionalidades",
              },
            ]}
          />

          {/*
            isAdmin && (
              <SingleItem
                sidebarKey="sidebar-amazon"
                label="Amazon"
                icon={<CurrencyDollarIcon className="h-5" />}
              />
            )
          */}

          <SingleItem
            sidebarKey="sidebar-roadmap"
            label="Roadmap"
            icon={<MapIcon className="h-5" />}
            route="/roadmap"
          />

          {/* 
            <SingleItem
              sidebarKey="sidebar-equipe"
              label="Equipe"
              icon={<UsersIcon className="h-5" />}
              route="/equipe"
            /> 
          */}

          <SingleItem
            sidebarKey="sidebar-equipe"
            label="Suporte"
            icon={<ChatBubbleLeftEllipsisIcon className="h-5" />}
            route="/suporte"
          />

          <SingleItem
            sidebarKey="sidebar-config"
            label="Configurações"
            icon={<Cog6ToothIcon className="h-5" />}
            route="/configuracoes"
          />
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
