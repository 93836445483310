import React from "react";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";

import { ImageGrid } from "@/components";
import { useFakeLoading } from "@/hooks";
import { FormWithContent } from "@/layouts";
import { getUserInfoHooks } from "@/hooks/Auth";
import { getSearchAllVolumesHooks } from "@/hooks/Cadastro";
import breadcrumbData from "./breadcrumbData.json";
import { TSchema } from "./schema";
import Form from "./form";

const Content = () => {
  const { id } = useParams();

  const { isAdmin } = getUserInfoHooks();
  const { values, setFieldValue } = useFormikContext<TSchema>();
  const { isLoading: isFakeLoading } = useFakeLoading();
  const { selectedVolumeId } = values;

  const { searchAllVolumes, searchAllVolumesStatus } = getSearchAllVolumesHooks(
    { id: Number(id) }
  );

  const isLoading = searchAllVolumesStatus !== "success" || isFakeLoading;

  const handleSelectVolume = () => {
    const volume = searchAllVolumes?.volumes?.find(
      ({ id }) => id === selectedVolumeId
    );

    setFieldValue("isUpdate", true);
    setFieldValue("id", volume?.id);

    setFieldValue("mangaId", Number(id));
    setFieldValue("preco", volume?.preco);
    setFieldValue("paginas", volume?.paginas);
    setFieldValue("mes", volume?.publicacao?.substring(4, 6));
    setFieldValue("ano", volume?.publicacao?.substring(0, 4));
    setFieldValue("oldValue", volume?.publicacao);

    setFieldValue("numero", volume?.numero);
    setFieldValue("quantidade", 0);
    setFieldValue("periodicidade", 0);
    setFieldValue("amazon", volume?.urlAmazon ?? "");

    setFieldValue("flagCapaVariante", volume?.flagCapaAlternativa);
    setFieldValue("flagBatchRequest", false);
    setFieldValue("flagIndividualRequest", true);
  };

  React.useEffect(() => {
    if (selectedVolumeId > 0) handleSelectVolume();
  }, [selectedVolumeId]);

  return (
    <FormWithContent
      isAdmin={isAdmin}
      isLoading={isLoading}
      breadcrumbData={breadcrumbData}
      title="Cadastro de volumes"
      formSubtitle="Qual método você gostaria de cadastrar um volume?"
      contentSubtitle="Ou escolha um volume abaixo para alterar seus dados."
      form={<Form isLoading={isLoading} />}
      content={
        <ImageGrid
          disabledClick={!isAdmin}
          isLoading={isLoading}
          data={searchAllVolumes?.volumes}
          getVolumeId
          formikField="selectedVolumeId"
        />
      }
    />
  );
};

export default Content;
