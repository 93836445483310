import React from "react";
import classnames from "classnames";
import Label from "../Label";

type TTextArea = {
  isLoading: boolean;
  label: string;
  labelColor?: "default" | "white";
  error?: boolean;
  required?: boolean;
  numberOfRows?: number;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = ({
  isLoading,
  error,
  label,
  labelColor = "default",
  required,
  numberOfRows = 3,
  ...params
}: TTextArea): JSX.Element => (
  <div className="mb-4 sm:mb-8">
    <Label
      labelColor={labelColor}
      isLoading={isLoading}
      label={label}
      error={error}
      id={params.id}
      required={required}
    />
    <div>
      <textarea
        {...params}
        rows={numberOfRows}
        className={classnames(
          "py-3 px-4 block w-full rounded-md text-sm focus:border-link focus:ring-link sm:p-4 sm:pb-5",
          {
            "disabled-input": isLoading,
            "border border-gray-500": required,
            "border border-gray-300": !required,
            "border-2 border-red-400": !isLoading && required && error,
          }
        )}
        placeholder={params.placeholder ?? label}
      />
    </div>
  </div>
);

export default TextArea;
