import React from "react";

import { Modal, Textarea, Select, Badge, ImageGrid, Input } from "@/components";
import { fetchSaveSuporte, fetchSuporteList } from "@/store/Suporte/thunks";
import { fetchSearchAllMangas } from "@/store/Cadastro/thunks";
import { getSearchAllMangasHooks } from "@/hooks/Cadastro";
import { getUserInfoHooks } from "@/hooks/Auth";
import { TicketLine } from "@/components";
import store from "@/store";

import {
  suportStatusOptions,
  handleSiteName,
  handlePageName,
  suportType,
} from "./utils";

import { TSchema } from "./schema";

type TModalResposta = {
  isVisible: boolean;
  setIsVisible: (p: boolean) => void;
  data: TSchema;
};

const ModalResposta = ({ isVisible, setIsVisible, data }: TModalResposta) => {
  const { user, token, config } = getUserInfoHooks();
  const limit = config?.cadastro?.totalItens;

  const { searchAllMangas } = getSearchAllMangasHooks({
    page: 1,
    limit,
    initialStart: false,
  });

  const [resposta, setResposta] = React.useState<null | string>(null);
  const [status, setStatus] = React.useState<null | number>(null);

  const sendRequest = async () => {
    const method = "PUT";

    const normalized: TSchema = {
      ...data,
      descricao: data.descricao ?? "",
      manga: data.manga ?? "",
      pagina: data.pagina ?? "",
      site: data.site ?? "",
      resposta,
      status,
      finishedByUserId: user.id,
    };

    await store.dispatch(
      fetchSaveSuporte({ values: normalized, method, token, config })
    );
    await store.dispatch(
      fetchSuporteList({ page: 1, limit: config.tabelas.totalItens })
    );

    setIsVisible(false);
  };

  React.useEffect(() => {
    setResposta(data?.resposta ?? "");
    setStatus(data?.status ?? 0);
  }, [data]);

  const DataItem = ({
    label,
    text,
    colspan = 1,
  }: {
    label: string;
    text: null | string;
    colspan?: number;
  }) => (
    <p className={`col-span-${colspan} text-sm text-gray-400 py-2`}>
      <b>{label}:</b> {text}
    </p>
  );

  const Summary = () => (
    <div className="grid grid-cols-2">
      {data?.tipo < 3 && (
        <>
          <DataItem
            colspan={data?.tipo === 1 ? 2 : 1}
            label="Descrição"
            text={data?.descricao}
          />
          <DataItem label="Site" text={handleSiteName(data?.site as string)} />
        </>
      )}

      {data?.tipo === 1 && (
        <DataItem
          label="Página"
          text={
            handlePageName(
              data?.site as string,
              data?.pagina as string
            ) as string
          }
        />
      )}

      {data?.tipo === 3 && (
        <>
          <DataItem label="Mangá" text={data?.manga as string} />
          <DataItem label="Editora" text={data?.editora as string} />
        </>
      )}

      <DataItem label="Solicitante" text={data?.userAdd as string} />
      <TicketLine
        align="start"
        text={suportType[data?.tipo]}
        id={data?.id}
        tipo={data?.tipo}
      />
    </div>
  );

  const Badges = () => (
    <div className="flex items-center justify-center gap-5 pb-5 mb-5 border-b border-gray-300">
      <Badge
        text="Irei avaliar o caso."
        color="green"
        onClick={() => {
          setResposta("Irei avaliar o caso.");
          setStatus(2);
        }}
      />
      <Badge
        text="Mangá Cadastrado!"
        color="blue"
        onClick={() => {
          setResposta("Mangá Cadastrado!");
          setStatus(4);
        }}
      />
      <Badge
        text="Esse ticket foi cancelado porque"
        color="red"
        onClick={() => {
          setResposta("Esse ticket foi cancelado porque");
          setStatus(5);
        }}
      />
    </div>
  );

  const handleSearch = (p: string) => {
    store.dispatch(fetchSearchAllMangas({ page: 1, limit, search: p }));
  };

  return (
    <Modal
      title={`Solicitação de suporte`}
      isCenter
      size="large"
      noChildrenPadding
      isVisible={isVisible}
      labels={["Cancelar", "Salvar"]}
      onSave={() => sendRequest()}
      onClose={() => setIsVisible(false)}
    >
      <div className="py-5">
        <div className="grid grid-cols-3 gap-5 mb-5 border-b border-gray-300">
          {data?.tipo === 3 && (
            <div className="col-span-3">
              <ImageGrid
                isLoading={false}
                data={searchAllMangas?.itens}
                search
                onSearch={handleSearch}
                isModal
                suporteModal
                formikField="mangaId"
              />
            </div>
          )}
          <div className="col-span-2">
            <Textarea
              required
              label="Resposta"
              isLoading={false}
              numberOfRows={6}
              value={resposta as string}
              onChange={({ target }) => setResposta(target.value)}
            />
          </div>

          <div className="flex flex-col">
            <Select
              required
              label="Tipo"
              isLoading={false}
              value={status?.toString()}
              onChange={({ target }) => setStatus(Number(target.value))}
              options={suportStatusOptions?.map(({ label, value }) => ({
                label,
                value: value.toString(),
              }))}
            />
            {data?.tipo === 3 && (
              <Input
                disabled
                label="Mangá Id"
                isLoading={false}
                value={data?.mangaId as number}
              />
            )}
          </div>
        </div>
        <Badges />
        <Summary />
      </div>
    </Modal>
  );
};

export default ModalResposta;
