import { Formik } from "formik";
import { getUserInfoHooks } from "@/hooks/Auth";
import Form from "./form";

const Volume = () => {
  const { config } = getUserInfoHooks();

  return (
    <Formik initialValues={config} onSubmit={() => undefined}>
      {() => <Form />}
    </Formik>
  );
};

export default Volume;
