import React from "react";
import classNames from "classnames";

type TContent = {
  debug?: boolean;
  children: JSX.Element | JSX.Element[];
  noPadding?: boolean;
};

const Content = ({ debug = false, children }: TContent) => {
  return (
    <div className="mb-auto h-auto">
      <div
        className={classNames(
          "max-w-[85rem] mx-auto h-full bg-transparent p-0",
          {
            "border border-green-500": debug,
          }
        )}
      >
        {children}
        <div className="fixed w-[85rem] h-full bg-slate-100 top-0 -z-50" />
      </div>
    </div>
  );
};

export default Content;
