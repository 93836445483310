import classNames from "classnames";

type TNavbarItem = {
  id?: string;
  collapse?: boolean;
  items?: JSX.Element | JSX.Element[];
  label: string;
  link?: boolean;
  onClick?: () => void;
};

const NavbarItem = ({
  id = undefined,
  collapse,
  items,
  label,
  link = false,
  onClick,
}: TNavbarItem): JSX.Element => (
  <div className="flex flex-col">
    <a
      className={classNames("font-medium cursor-pointer", {
        "text-secondary hover:text-secondary-hover": link,
        "text-gray-400 hover:text-gray-200": !link,
        "hs-collapse-toggle": collapse,
      })}
      id={id}
      data-hs-collapse={`#${id}-items`}
      onClick={onClick}
    >
      {label}
    </a>
    {collapse && (
      <div
        id={`${id}-items`}
        className="hs-collapse hidden w-full overflow-hidden transition-[height] duration-300"
        aria-labelledby={id}
      >
        <div className="flex flex-col gap-5 pt-5">{items}</div>
      </div>
    )}
  </div>
);

export default NavbarItem;
