import * as P from "@/pages";

export const routesList = [
  { path: "/", component: <P.Login /> },
  { path: "/home", component: <P.Home /> },
  { path: "/roadmap", component: <P.RoadMap /> },
  { path: "/equipe", component: <P.Equipe /> },
  { path: "/suporte", component: <P.Suporte /> },

  { path: "/manga/visualizar", component: <P.MangaView /> },
  { path: "/manga/cadastro", component: <P.MangaAdd /> },
  { path: "/manga/cadastro/:id", component: <P.MangaAdd /> },

  { path: "/volume/visualizar", component: <P.VolumeView /> },
  { path: "/volume/cadastro/:id", component: <P.VolumeAdd /> },

  { path: "/reimpressao/visualizar", component: <P.ReprintView /> },
  { path: "/reimpressao/cadastro/:id", component: <P.ReprintAdd /> },

  { path: "/tabelas/artistas", component: <P.Artista /> },
  { path: "/tabelas/editoras", component: <P.Editora /> },
  { path: "/tabelas/faixas-etarias", component: <P.FaixaEtaria /> },
  { path: "/tabelas/formatos", component: <P.Formato /> },
  { path: "/tabelas/generos", component: <P.Genero /> },
  { path: "/tabelas/nacionalidades", component: <P.Nacionalidade /> },

  { path: "/configuracoes", component: <P.Configuracoes /> },
];
