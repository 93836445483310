import { useFormikContext } from "formik";

import { Select } from "@/components";
import { totalImageGridPaginationOptions } from "@/utils/enuns";
import { TUserAdminConfig } from "@/store/Auth/types";

import SwitchLine from "../switchLine";
import DefaultLine from "../defaultLine";
import DefaultTitle from "../defaultTitle";
import DefaultLabel from "../defaultLabel";

import { BookOpenIcon } from "@heroicons/react/20/solid";

const FormCadastro = ({ isLoading }: { isLoading: boolean }) => {
  const { values, setFieldValue } = useFormikContext<TUserAdminConfig>();

  return (
    <div>
      <DefaultTitle
        isLoading={isLoading}
        text="Cadastro"
        icon={<BookOpenIcon className="h-7" />}
      />
      <DefaultLine>
        <div className="flex items-center gap-5">
          <DefaultLabel isLoading={isLoading} text="Total de itens exibidos" />
          <Select
            className="mt-8"
            small
            isLoading={isLoading}
            value={values?.cadastro?.totalItens}
            onChange={({ target }) =>
              setFieldValue("cadastro.totalItens", Number(target.value))
            }
            options={totalImageGridPaginationOptions}
          />
        </div>
      </DefaultLine>
      <SwitchLine
        isLoading={isLoading}
        id={0}
        label="Carregar mangás ao entrar"
        value={values?.cadastro?.carregarMangas}
        onChange={({ target }) =>
          setFieldValue("cadastro.carregarMangas", target.checked)
        }
      />
    </div>
  );
};

export default FormCadastro;
