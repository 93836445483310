import React from 'react';
import { useSelector } from "react-redux";
import { getEditorasList } from "@/store/Tabelas/selectors";
import { fetchGetEditorasList } from "@/store/Tabelas/thunks";
import { TEditorasPayload } from "@/store/Tabelas/types";
import store from '@/store';
import { TStatus, TDefaultPagination } from '@/store/types';

export type TEditorasListHooks = {
  editoras: TEditorasPayload
  editorasStatus: TStatus
}

export const getEditorasListHooks = ({ page, limit }: TDefaultPagination): TEditorasListHooks => {
  const { editoras, editorasStatus } = useSelector(getEditorasList);
  
  React.useEffect(() => {
    store.dispatch(fetchGetEditorasList({ page, limit }));
  }, []);

  return { editoras, editorasStatus };
};
