import { Button } from "@/components";

const ReprintHeader = ({
  isLoading,
  onClick,
}: {
  isLoading: boolean;
  onClick: () => void;
}) => (
  <h3 className="text-lg font-bold text-primary flex items-center justify-between mb-5">
    {isLoading ? <div className="skeleton w-1/2 h-5" /> : "Data de publicação"}
    <Button
      isLoading={isLoading}
      small
      soft
      label="Cadastrar"
      onClick={onClick}
    />
  </h3>
);

export default ReprintHeader;
