import * as yup from "yup";
import { TSuportItem } from "@/store/Suporte/types";

export type TSchema = TSuportItem & {
  isUpdate?: boolean;
};

export const validationSchema = yup.object().shape({
  id: yup.number(),
  tipo: yup.number(),

  descricao: yup
    .string()
    .when("tipo", { is: 1, then: yup.string().required() })
    .when("tipo", { is: 2, then: yup.string().required() }),
  site: yup
    .string()
    .when("tipo", { is: 1, then: yup.string().required() })
    .when("tipo", { is: 2, then: yup.string().required() }),
  pagina: yup.string().when("tipo", { is: 1, then: yup.string().required() }),

  manga: yup.string().when("tipo", { is: 3, then: yup.string().required() }),
  editora: yup.string(),

  flagBugfix: yup.boolean(),
  flagSugestions: yup.boolean(),
  flagNewManga: yup.boolean(),

  userId: yup.number().min(0),
  isUpdate: yup.boolean(),
});
