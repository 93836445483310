import React from "react";
import classNames from "classnames";
import kaneda from "@/assets/kaneda.png";

type TAvatar = {
  src?: string;
  rounded?: boolean;
  size?: "small" | "medium" | "large" | "ultra";
};

const Avatar = ({ src, rounded = false, size = "small" }: TAvatar) => (
  <img
    className={classNames("inline-block", {
      "rounded-full": rounded,
      "h-8 w-8": size === "small",
      "h-[2.375rem] w-[2.375rem]": size === "medium",
      "h-[2.875rem] w-[2.875rem]": size === "large",
      "h-[3.875rem] w-[3.875rem]": size === "ultra",
    })}
    src={src ?? kaneda}
    alt="Avatar User"
  />
);

export default Avatar;
