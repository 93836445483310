import classNames from "classnames";
import ModalTitle from "./title";
import ModalFooter from "./footer";
import ModalBackdrop from "./backdrop";

type TModal = {
  title: string;
  isVisible: boolean;
  isCenter?: boolean;
  size?: "small" | "medium" | "large";
  onClose: (p?: any) => void;
  onSave?: (p?: any) => void;
  children?: JSX.Element;
  noChildrenPadding?: boolean;
  noChildren?: boolean;
  labels?: string[];
};

const Modal = ({
  title,
  isVisible,
  isCenter,
  size = "medium",
  onClose,
  onSave,
  noChildrenPadding = false,
  noChildren = false,
  children,
  labels,
}: TModal) => {
  return (
    <>
      <div
        className={classNames(
          "hs-overlay w-full h-full flex justify-center fixed z-40 top-0 left-0 overflow-hidden m-0",
          {
            "hidden ": !isVisible,
            "open fadeIn": isVisible,
            "pt-8": !isCenter,
            "pt-52": isCenter,
          }
        )}
      >
        <div
          className={classNames(
            "opacity-100 transition-all m-3 sm:mx-auto p-5 lg:p-0 w-full sm:w-5/6 absolute z-30",
            {
              "md:w-[400px]": size === "small",
              "md:w-[600px]": size === "medium",
              "md:w-[800px]": size === "large",
            }
          )}
        >
          <div className="flex flex-col bg-slate-100 shadow-lg border-none rounded-xl -mt-10">
            <ModalTitle
              onClose={onClose}
              title={title}
              noChildren={noChildren}
            />
            {!noChildren && (
              <div
                className={classNames("overflow-y-auto", {
                  "p-5": !noChildrenPadding,
                  "px-5": noChildrenPadding,
                })}
              >
                {children}
              </div>
            )}
            <ModalFooter
              labels={labels}
              onClose={onClose}
              onSave={onSave}
              noChildren={noChildren}
            />
          </div>
        </div>
        <div
          className="absolute top-0 w-full h-full z-20"
          onClick={onClose}
        ></div>
      </div>
      <ModalBackdrop isVisible={isVisible} />
    </>
  );
};

export default Modal;
