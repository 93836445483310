import image from "@/assets/logo_chapado.png";

const NavbarLogo = (): JSX.Element => (
  <div className="flex items-start justify-between h-full">
    <a className="flex-none text-lg font-semibold inline-flex gap-4 text-gray-400">
      <img src={image} alt="Meus Mangás Logo" className="h-8 " /> Painel
      Administrativo
    </a>
  </div>
);

export default NavbarLogo;
