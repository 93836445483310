import React from "react";
import classNames from "classnames";

type TData = {
  id: number;
  title: string;
  content: JSX.Element | JSX.Element[];
};

const TabsWithUnderlineContent = ({
  data,
  debug,
  activeTab,
}: {
  data: TData[];
  debug?: boolean;
  activeTab: number;
}) => (
  <div
    className={classNames("my-5", {
      "border border-green-500": debug,
    })}
  >
    {data?.map(({ id, content }) => (
      <div
        id={`tabs-with-underline-${id}`}
        role="tabpanel"
        className={classNames("", {
          " hidden": activeTab !== id,
        })}
        aria-labelledby={`tabs-with-underline-item-${id}`}
      >
        {content}
      </div>
    ))}
  </div>
);

export default TabsWithUnderlineContent;
