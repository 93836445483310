import { Sidebar } from "@/layouts";
import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import breadcrumbData from "./breadcrumbData.json";
import Carousel from "./carousel";

const Roadmap = () => {
  const { isLoading } = useFakeLoading();
  return (
    <>
      <Sidebar>
        <HeaderSection
          isLoading={isLoading}
          align="left"
          title="Roadmap"
          breadcrumbData={breadcrumbData}
          subtitle="Confira os planos para o futuro do Meus Mangás."
        />
        <Carousel />
      </Sidebar>
    </>
  );
};

export default Roadmap;
