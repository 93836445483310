import { Default } from "@/layouts";
import Cadastro from "./cadastro";
import Tabelas from "./tabelas";
import Banner from "./banner";
import Outros from "./outros";

const Home = () => {
  return (
    <Default>
      <Banner />
      <div className="p-5">
        <Cadastro />
        <Tabelas />
        <Outros />
      </div>
    </Default>
  );
};

export default Home;
