import React from "react";
import classNames from "classnames";
import "./style.css";

type TButton = {
  isLoading: boolean;
  label: string;
  small?: boolean;
  large?: boolean;
  outline?: boolean;
  ghost?: boolean;
  soft?: boolean;
  danger?: boolean;
  link?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  isLoading,
  label,
  small = false,
  large = false,
  outline = false,
  ghost = false,
  soft = false,
  link = false,
  danger = false,
  ...params
}: TButton): JSX.Element => {
  return (
    <button
      {...params}
      type="button"
      className={classNames("base-components-default-button", {
        "skeleton-button": isLoading,
        "focus:outline-none focus:ring-2 focus:ring-offset-2": !isLoading,
        "size-small": small,
        "size-large": large,
        "size-default": !small && !large,
        "bg-red-400 hover:bg-red-600 text-white": !isLoading && danger,
        "border border-transparent": !isLoading && !outline,
        "border-2 border-gray-300 text-link hover:text-white hover:bg-link hover:border-link":
          !isLoading && outline,
        "base-components-ghost-button text-link bg-transparent hover:bg-blue-100 focus:ring-link":
          !isLoading && ghost,
        "bg-blue-100 text-link hover:bg-link hover:text-white focus:ring-link":
          !isLoading && soft,
        "border border-transparent bg-transparent text-link hover:text-link hover:bg-transparent":
          !isLoading && link,
        "bg-link text-white hover:bg-blue-600 focus:ring-link":
          !isLoading && !outline && !ghost && !soft && !link && !danger,
      })}
    >
      {label}
    </button>
  );
};

export default Button;
