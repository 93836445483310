import React from "react";
import { Formik } from "formik";

import { fetchLogin } from "@/store/Auth/thunks";
import { Input, Button } from "@/components";
import { useToast } from "@/hooks";
import store from "@/store";

import { validationSchema, TSchema } from "./schema";
import initialValues from "./initialValues.json";

const Form = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const sendRequest = async (body: TSchema) => {
    await store.dispatch(fetchLogin({ body, type: "email" }));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => await sendRequest(values)}
    >
      {({ errors, values, setFieldValue, handleSubmit }) => (
        <div className="">
          <p className="font-semibold text-gray-400 text-center pb-6">
            Faça o seu login abaixo :)
          </p>
          <div className="w-full">
            <Input
              small
              labelColor="white"
              label="Email"
              isLoading={false}
              className="mb-4"
              value={values?.email}
              error={isSubmitting && !!errors?.email}
              onChange={({ target }) => setFieldValue("email", target.value)}
            />
            <Input
              type="password"
              small
              labelColor="white"
              label="Senha"
              isLoading={false}
              className="mb-6"
              value={values?.senha}
              error={isSubmitting && !!errors?.senha}
              onChange={({ target }) => setFieldValue("senha", target.value)}
            />
            <div className="flex items-center justify-between gap-12 pb-6">
              <a
                className="tracking-wider text-link cursor-pointer hover:text-blue-600"
                onClick={() =>
                  useToast({
                    type: "error",
                    msg: "Ops, ocorreu um erro. Favor tentar novamente.",
                    time: 5000,
                    showNotification: true,
                  })
                }
              >
                Esqueceu sua senha?
              </a>
              <Button
                small
                isLoading={false}
                label="Entrar"
                onClick={() => {
                  setIsSubmitting(true);
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Form;
