import React from "react";
import Cover from "@/assets/kaneda_final_nohud.jpg";
import { useHTTPSprotocol } from "@/hooks";
import GoogleAuth from "./googleAuth";
// import NewAccount from "./newAccount";
import Footer from "./footer";
import Header from "./header";
import Form from "./form";

const Login = () => {
  const { isHttps, isProd, siteLocation } = useHTTPSprotocol();

  React.useEffect(() => {
    if (!isHttps && isProd) window.location.href = siteLocation;
  }, []);

  return (
    <div className="flex fixed top-0 bottom-0 right-0 left-0">
      <div className="w-1/4 bg-primary flex flex-col justify-center">
        <div className="w-full h-full px-12 flex flex-col justify-between py-10">
          <Header />
          <div className="flex flex-col">
            <Form />
            <GoogleAuth />
          </div>
          <Footer />
        </div>
      </div>
      <img src={Cover} className="w-3/4 h-full" />
    </div>
  );
};

export default Login;
