import { Route, Routes } from "react-router-dom";
import { routesList } from "./routerList";

export const Router = () => (
  <Routes>
    {routesList?.map(({ path, component }) => (
      <Route key={path} path={path} element={component} />
    ))}
  </Routes>
);
