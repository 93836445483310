import React from "react";
import classNames from "classnames";
import "./style.css";

type TImageCover = {
  id?: number;
  isLoading?: boolean;
  disableHover?: boolean;
  small?: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const ImageCover = ({
  id,
  isLoading,
  disableHover = false,
  small = false,
  ...params
}: TImageCover) => {
  const [loaded, setLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setTimeout(() => setLoaded(true), 250);
  };

  return (
    <div
      key={id}
      className={classNames(
        "flex flex-col justify-center items-center bg-transparent",
        {
          "w-full h-56 ": !small,
          "w-full h-28 ": small,
        }
      )}
    >
      {isLoading && (
        <div className="absolute z-10 text-tertiary animate-pulse text-sm tracking-wide">
          Carregando...
        </div>
      )}
      {!isLoading && (
        <img
          key={id}
          {...params}
          crossOrigin="anonymous"
          onLoad={handleImageLoad}
          className={classNames(
            "relative z-10 cursor-pointer rounded-lg shadow-sm border-transparent transition border-4",
            {
              "w-32 h-48 lg:w-36 lg:h-52  ": !small,
              "w-20 h-auto ": small,
              "hover:opacity-100 hover:w-[84px] hover:border-link ": small,
              "opacity-0 ": !loaded,
              "opacity-100 ": loaded && disableHover,
              "opacity-70 transition-opacity ": loaded && !disableHover,
              "hover:opacity-100 hover:w-40 hover:h-56 hover:border-link ":
                loaded && !disableHover && !small,
            }
          )}
          alt="Image Description"
          loading="eager"
        />
      )}
      <div
        className={classNames("absolute z-0 rounded-lg shadow-sm bg-gray-200", {
          "w-32 h-48 lg:w-36 lg:h-52": !small,
          "w-20 h-auto ": small,
          "opacity-50 transition-opacity": !loaded,
          "hidden ": loaded,
        })}
      ></div>
    </div>
  );
};

export default ImageCover;
