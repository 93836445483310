import React from "react";
import classnames from "classnames";
import Label from "../Label";

type TInput = {
  isLoading: boolean;
  label: string;
  labelColor?: "default" | "white";
  small?: boolean;
  large?: boolean;
  error?: boolean | undefined;
  required?: boolean;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = ({
  isLoading,
  label,
  labelColor = "default",
  small = false,
  large = false,
  required,
  error,
  className,
  ...params
}: TInput): JSX.Element => {
  return (
    <div
      className={classnames(`${className}`, {
        "mb-4 sm:mb-8": !className,
      })}
    >
      <Label
        labelColor={labelColor}
        isLoading={isLoading}
        label={label}
        error={error}
        id={params.id}
        required={required}
      />
      <input
        {...params}
        className={classnames(
          "py-3 px-4 block w-full rounded-md text-sm focus:outline-link focus:ring-link sm:p-4",
          {
            "disabled-input": isLoading,
            "border border-gray-500": required,
            "border border-gray-300": !required,
            "border-2 border-red-400": !isLoading && required && error,
            "size-small": small,
            "size-large": large,
            "size-default": !small && !large,
          }
        )}
        placeholder={isLoading ? "" : params.placeholder ?? label}
      />
    </div>
  );
};

export default Input;
