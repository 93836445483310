import { toast, ToastPosition, Theme } from 'react-toastify';

type TUseToast = {
  msg: string
  time?: number
  type?: 'info' | 'success' | 'error' 
  showNotification: boolean
}

const useToast = ({ type = 'info', msg, time = 2000, showNotification }: TUseToast) => {
  if (!showNotification) return; 
  
  const params = {
    position: "top-right" as ToastPosition,
    autoClose: time,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: "colored" as Theme,
  }

  if (type === 'success') toast.success(msg, params);
  if (type === 'error') toast.error(msg, params);
  if (type === 'info') toast.info(msg, params);
}

export default useToast;