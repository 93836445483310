import { TUserAdminConfig } from '@/store/Auth/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TDefaultPagination } from '@/store/types';
import { TSchema } from '@/pages/Suporte/schema';
import { normalizeAuth } from "@/store/utils";
import { useToast } from '@/hooks';
import api from "@/services/api";
import * as T from './types';

export const fetchSuporteList = createAsyncThunk(
	'suporte/list',
	async ({ page, limit, userId, type, status}: any) => {
		const controller = new AbortController();

		const l = limit ? `/${limit}` : ``;
		const u = userId > 0 ? `&userId=${userId}` : ``;
		const t = type > 0 ? `&type=${type}` : ``;
		const s = status > 0 ? `&status=${status}` : ``;

		const params = `${l}?filter=true${u}${t}${s}`;

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/list-suportes/${page ?? 1}${params}`,
		});	
		return data as T.TSuportList;
	}
);

export const fetchSaveSuporte = createAsyncThunk(
	'suporte/add',
	async ({ values, method, token, config }: { values: TSchema, method: 'POST' | 'PUT', token: string, config: TUserAdminConfig }) => {
		try {
			const url = method === 'POST' ? `v2/add-new-suporte` : `v2/edt-suporte`;
			const controller = new AbortController();

			await api({
				url,
				method,	
				signal: controller.signal,
				data: normalizeAuth(values),
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Cadastrado com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes,
			});
		} catch (error) {
			useToast({ 
				msg: 'Falha no cadastro. Tente novamente mais tarde.', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);

export const fetchDeleteSuporte = createAsyncThunk(
	'suporte/del',
	async ({ id, token, config }: { id: number, token: string, config: TUserAdminConfig }) => {
		try {
			const controller = new AbortController();

			await api({
				url: `v2/del-suporte/${id}`,
				method: 'DELETE',	
				signal: controller.signal,
				headers: {
					'Authorization': `Bearer ${token}`
				},
			});	
			useToast({ 
				msg: 'Excluído com sucesso!', 
				type: 'success', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		} catch (error) {
			useToast({ 
				msg: 'Informação não encontrada', 
				type: 'error', 
				showNotification: config.geral.exibirNotificacoes, 
			});
		}
	}
);
