import { ImageCover } from "@/components";

const ReprintCover = ({
  isLoading,
  url,
}: {
  isLoading: boolean;
  url: string;
}) => (
  <div className="w-[10rem] min-h-[12rem] lg:w-[11rem] lg:min-h-[15rem] bg-primary flex-shrink-0 rounded-t-xl sm:rounded-l-xl md:rounded-tr-none flex flex-col justify-center items-center">
    <ImageCover
      isLoading={isLoading}
      disableHover
      src={`https://i.imgur.com/${url}`}
    />
  </div>
);

export default ReprintCover;
