import * as yup from "yup";

export type TSchema = {
  id: number;
  nome?: string | undefined;
  tipo?: number | undefined;

  subtitulo?: string | undefined;
  original?: string | undefined;

  editoraId?: number | undefined;
  categoriaId?: number | undefined;
  statuId?: number | undefined;

  nacionalidadeId?: number | undefined;
  demografiaId?: number | undefined;
  formatoId?: number | undefined;
  faixaEtariaId?: number | undefined;

  roteiro: number[];
  genero: number[];
  arte: number[];

  userId: number;
  isUpdate: boolean;
};

export const validationSchema = yup.object().shape({
  id: yup.number(),
  nome: yup.string().required(),
  tipo: yup.number().min(1).required(),

  subtitulo: yup.string(),
  original: yup.string(),

  editoraId: yup.number().min(1).required(),
  categoriaId: yup.number().min(1).required(),
  statuId: yup.number().min(1).required(),

  nacionalidadeId: yup.number(),
  demografiaId: yup.number(),
  formatoId: yup.number(),
  faixaEtariaId: yup.number(),

  roteiro: yup.array().required().min(1),
  genero: yup.array().required().min(2),
  arte: yup.array().required().min(1),

  userId: yup.number().min(0),
  isUpdate: yup.boolean(),
});
