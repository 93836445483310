import { createAsyncThunk } from '@reduxjs/toolkit';
import { TDefaultItem } from '@/store/types';
import api from "@/services/api";

export const fetchGetAllCategorias = createAsyncThunk(
	'tabelas/categorias/listAll',
	async () => {
		const controller = new AbortController();

		const { data } = await api({
			method: 'GET',
			signal: controller.signal,
			url: `v2/list-all-categorias`,
		});	
		return data as TDefaultItem[];
	}
);
