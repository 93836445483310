import {
  CalendarDaysIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";

import { formatPublishDate } from "@/utils";

const ListItem = ({
  isLoading,
  label,
  fnEdit,
  fnDelete,
}: {
  isLoading: boolean;
  label: string;
  fnEdit: (p?: string) => void;
  fnDelete: (p?: number) => void;
}) => (
  <li className="inline-flex items-center justify-between gap-x-2 py-2 px-1 text-sm text-tertiary">
    {isLoading ? (
      <div className="flex justify-between w-full py-1">
        <div className="skeleton w-1/2 h-4" />
        <div className="flex justify-between w-12">
          <div className="skeleton w-5 h-4" />
          <div className="skeleton w-5 h-4" />
        </div>
      </div>
    ) : (
      <>
        <div className="inline-flex items-center gap-x-2">
          <CalendarDaysIcon className="h-5" />
          {formatPublishDate(label)}
        </div>
        <div className="inline-flex items-center gap-x-2">
          <PencilSquareIcon
            className="cursor-pointer h-[18px] text-link"
            onClick={() => fnEdit()}
          />
          <TrashIcon
            className="cursor-pointer h-[18px] text-red-500"
            onClick={() => fnDelete()}
          />
        </div>
      </>
    )}
  </li>
);

export default ListItem;
