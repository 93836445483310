import React from "react";
import { Avatar, Badge, Modal } from "@/components";
import { InfoContainer, InfoLine, TicketLine } from "@/components";
import { TBadgeColor } from "@/components/BaseComponents/Badge";
import { TSuportItem } from "@/store/Suporte/types";
import {
  statusList,
  statusColorList,
  handleSiteName,
  handlePageName,
} from "./utils";
import moment from "moment";

type TModalInfo = {
  isVisible: boolean;
  setIsVisible: (p: boolean) => void;
  data: TSuportItem | undefined;
};

const ModalInfo = ({ isVisible, setIsVisible, data }: TModalInfo) => (
  <Modal
    title={`Solicitação de suporte`}
    isCenter
    noChildrenPadding
    isVisible={isVisible}
    labels={["Fechar", ""]}
    onClose={() => setIsVisible(false)}
  >
    <>
      {data?.tipo === 1 && (
        <>
          <InfoContainer column={2} breakline={false}>
            <div className="col-span-2">
              <InfoLine title="Descrição" label={data?.descricao} />
            </div>

            <InfoLine
              title="Site"
              label={handleSiteName(data?.site as string)}
            />

            <InfoLine
              title="Página"
              label={handlePageName(
                data?.site as string,
                data?.pagina as string
              )}
            />

            <InfoLine title="Solicitante">
              <div className="inline-flex gap-x-4 items-center py-1">
                <Avatar rounded src={data?.userAddAvatar} />
                {data?.userAdd}
              </div>
            </InfoLine>

            <InfoLine title="Status">
              <Badge
                text={`${statusList[data?.status as number]}`}
                color={
                  `${statusColorList[data?.status as number]}` as TBadgeColor
                }
              />
            </InfoLine>

            <TicketLine
              colspan={2}
              text="Bug fix"
              id={data?.id}
              tipo={data?.tipo}
            />
          </InfoContainer>
        </>
      )}

      {data?.tipo === 2 && (
        <InfoContainer column={3} breakline={false}>
          <div className="col-span-2">
            <InfoLine title="Descrição" label={data?.descricao} />
          </div>

          <InfoLine title="Site" label={handleSiteName(data?.site as string)} />

          <div className="col-span-2">
            <InfoLine title="Solicitante">
              <div className="inline-flex gap-x-4 items-center py-1">
                <Avatar rounded src={data?.userAddAvatar} />
                {data?.userAdd}
              </div>
            </InfoLine>
          </div>

          <InfoLine title="Status">
            <Badge
              text={`${statusList[data?.status as number]}`}
              color={
                `${statusColorList[data?.status as number]}` as TBadgeColor
              }
            />
          </InfoLine>

          <TicketLine
            colspan={3}
            text="Críticas / Sugestões"
            id={data?.id}
            tipo={data?.tipo}
          />
        </InfoContainer>
      )}

      {data?.tipo === 3 && (
        <InfoContainer column={3} breakline={false}>
          <div className="col-span-2">
            <InfoLine title="Mangá" label={data?.manga} />
          </div>

          <InfoLine title="Editora" label={data?.editora} />

          <div className="col-span-2">
            <InfoLine title="Solicitante">
              <div className="inline-flex gap-x-4 items-center py-1">
                <Avatar rounded src={data?.userAddAvatar} />
                {data?.userAdd}
              </div>
            </InfoLine>
          </div>

          <InfoLine title="Status">
            <Badge
              text={`${statusList[data?.status as number]}`}
              color={
                `${statusColorList[data?.status as number]}` as TBadgeColor
              }
            />
          </InfoLine>

          <TicketLine
            colspan={3}
            text="Cadastro de mangás"
            id={data?.id}
            tipo={data?.tipo}
          />
        </InfoContainer>
      )}

      {data?.resposta && (
        <>
          <hr className="border border-gray-300" />
          <InfoContainer column={3} breakline={false}>
            <div className="col-span-3">
              <InfoLine title="Resposta">
                <>
                  <p>{data?.resposta}</p>
                  {data?.mangaId && (
                    <a
                      className="text-base font-semibold text-link underline"
                      href={`https://meusmangas.com.br/info/${
                        data?.mangaId
                      }/${data?.manga?.trim().replaceAll(" ", "-")}`}
                      target="_blank"
                    >
                      Link Meus Mangás
                    </a>
                  )}
                </>
              </InfoLine>
            </div>

            <div className="col-span-2">
              <InfoLine title="Adicionado por">
                <div className="inline-flex gap-x-4 items-center py-1">
                  <Avatar rounded src={data?.finishedAvatar as string} />
                  {data?.finishedBy}
                </div>
              </InfoLine>
            </div>
            <InfoLine
              title="Resposta"
              label={moment(data?.finishedDate)?.format("DD/MM/YYYY")}
            />
          </InfoContainer>
        </>
      )}
    </>
  </Modal>
);

export default ModalInfo;
