import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import { SimpleCardLink, Grid } from "@/components";

const Tabelas = () => {
  const { isLoading } = useFakeLoading(1250);

  return (
    <>
      <HeaderSection
        isLoading={isLoading}
        align="center"
        title="Tabelas"
        subtitle=" Acesse as tabelas secundárias do sistema que servem de base para o
        cadastro principal."
      />
      <Grid>
        <SimpleCardLink
          isLoading={isLoading}
          title="Artistas"
          subtitle="762 itens cadastrados"
          route="/tabelas/artistas"
        />
        <SimpleCardLink
          isLoading={isLoading}
          title="Editoras"
          subtitle="35 itens cadastrados"
          route="/tabelas/editoras"
        />
        <SimpleCardLink
          isLoading={isLoading}
          title="Faixas-Etárias"
          subtitle="762 itens cadastrados"
          route="/tabelas/faixas-etarias"
        />
        <SimpleCardLink
          isLoading={isLoading}
          title="Formatos"
          subtitle="272 itens cadastrados"
          route="/tabelas/formatos"
        />
        <SimpleCardLink
          isLoading={isLoading}
          title="Gêneros"
          subtitle="52 itens cadastrados"
          route="/tabelas/generos"
        />
        <SimpleCardLink
          isLoading={isLoading}
          title="Nacionalidades"
          subtitle="12 itens cadastrados"
          route="/tabelas/nacionalidades"
        />
      </Grid>
    </>
  );
};

export default Tabelas;
