import React from "react";
import { Sidebar } from "@/layouts";
import { useFakeLoading } from "@/hooks";
import { HeaderSection } from "@/structures";
import breadcrumbData from "./breadcrumbData.json";

const Staff = () => {
  const { isLoading } = useFakeLoading();

  return (
    <>
      <Sidebar>
        <HeaderSection
          isLoading={isLoading}
          align="left"
          title="Equipe"
          breadcrumbData={breadcrumbData}
          subtitle="........."
        />
        <div className="grid grid-cols-1 w-full border border-red-500"></div>
      </Sidebar>
    </>
  );
};

export default Staff;
