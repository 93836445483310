import React from 'react';
import { useSelector } from "react-redux";
import { getArtistasList } from "@/store/Tabelas/selectors";
import { fetchGetArtistasList } from "@/store/Tabelas/thunks";
import { TArtistasPayload } from "@/store/Tabelas/types";
import store from '@/store';
import { TStatus, TDefaultPagination } from '@/store/types';

export type TArtistasListHooks = {
  artistas: TArtistasPayload
  artistasStatus: TStatus
}

export const getArtistasListHooks = ({ page, limit }: TDefaultPagination): TArtistasListHooks => {
  const { artistas, artistasStatus } = useSelector(getArtistasList);
  
  React.useEffect(() => {
    store.dispatch(fetchGetArtistasList({ page, limit }));
  }, []);

  return { artistas, artistasStatus };
};
