import { useFormikContext } from "formik";
import { mangaTypeOptions } from "@/utils";
import { Input, Select } from "@/components";
import { TSchema } from "./schema";

const StepA = ({ isLoading }: { isLoading: boolean }) => {
  const { values, errors, isSubmitting, setFieldValue } =
    useFormikContext<TSchema>();

  return (
    <div className="grid grid-cols-2 gap-5 py-5">
      <Input
        required
        label="Mangá"
        isLoading={isLoading}
        value={values.nome}
        error={isSubmitting && !!errors.nome}
        onChange={({ target }) => setFieldValue("nome", target.value)}
      />
      <Select
        required
        label="Tipo"
        isLoading={isLoading}
        value={values.tipo}
        error={isSubmitting && !!errors.tipo}
        onChange={({ target }) => setFieldValue("tipo", target.value)}
        options={mangaTypeOptions?.map(({ id, label }) => ({
          label,
          value: id.toString(),
        }))}
      />
      <Input
        label="Subtitulo"
        isLoading={isLoading}
        value={values.subtitulo}
        error={isSubmitting && !!errors.subtitulo}
        onChange={({ target }) => setFieldValue("subtitulo", target.value)}
      />
      <Input
        label="Nome original"
        isLoading={isLoading}
        value={values.original}
        error={isSubmitting && !!errors.original}
        onChange={({ target }) => setFieldValue("original", target.value)}
      />
    </div>
  );
};

export default StepA;
