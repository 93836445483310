import { Item as NavbarItem, User as NavbarUser } from "..";

const NavbarList = (): JSX.Element => {
  return (
    <div
      id="navbar-dark"
      className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow lg:block"
    >
      <div className="flex flex-col sm:flex-row justify-end gap-5 mt-5 pb-5 lg:items-center lg:justify-end lg:mt-1 lg:pl-5 lg:pb-0">
        <div className="flex flex-col sm:flex-row gap-5 lg:hidden">
          <NavbarItem
            label="Cadastro"
            link
            collapse
            id="collapse-cadastro"
            items={
              <>
                <NavbarItem label="Mangás" />
                <NavbarItem label="Volumes" />
                <NavbarItem label="Reimpressão" />
              </>
            }
          />

          <NavbarItem
            label="Tabelas"
            link
            collapse
            id="collapse-tabelas"
            items={
              <>
                <NavbarItem label="Artistas" />
                <NavbarItem label="Editoras" />
                <NavbarItem label="Faixas-Etárias" />
                <NavbarItem label="Formatos" />
                <NavbarItem label="Gêneros" />
                <NavbarItem label="Nacionalidades" />
              </>
            }
          />
        </div>

        <NavbarUser />
      </div>
    </div>
  );
};

export default NavbarList;
