import React from 'react'
import { useSelector } from "react-redux";

import { getSearchAllMangas } from "@/store/Cadastro/selectors";
import { TSearchAllMangasPayload } from "@/store/Cadastro/types";
import { fetchSearchAllMangas } from "@/store/Cadastro/thunks";
import { TStatus, TDefaultPagination } from "@/store/types";
import store from "@/store";

export type TSearchAllMangasHooks = {
  searchAllMangas: TSearchAllMangasPayload | null
  searchAllMangasStatus: TStatus
}

export const getSearchAllMangasHooks = ({ page, limit, initialStart = true }: TDefaultPagination): TSearchAllMangasHooks => { 
  const { searchAllMangas, searchAllMangasStatus } = useSelector(getSearchAllMangas);

  React.useEffect(() => {
    store.dispatch({ type: "reset/searchAllMangas" });
  },[])
  
  if (searchAllMangasStatus === 'empty' && initialStart) store.dispatch(fetchSearchAllMangas({ page, limit }));

  return { searchAllMangas, searchAllMangasStatus };
};

