import classNames from "classnames";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";

import { TSearchAllMangasItem, TVolumeInfo } from "@/store/Cadastro/types";
import { InputSearch, ImageCover } from "@/components";
import { useScrollTop } from "@/hooks";
import store from "@/store";

type TImageGrid = {
  isLoading: boolean;
  data?: TSearchAllMangasItem[] | TVolumeInfo[];
  onSearch?: (p: string) => void;
  search?: boolean;
  route?: string;
  isModal?: boolean;
  suporteModal?: boolean;
  getVolumeId?: boolean;
  formikField?: string;
  disabledClick?: boolean;
};

const ImageGrid = ({
  isLoading,
  data,
  onSearch,
  search = false,
  route,
  isModal,
  suporteModal,
  getVolumeId,
  formikField,
  disabledClick = false,
}: TImageGrid) => {
  const navigate = useNavigate();
  const formikContext = useFormikContext();

  const handleClick = (id: number) => {
    if (disabledClick) return;

    if (route) navigate(`${route}/${id}`);

    if (getVolumeId) {
      useScrollTop();
      formikContext?.setFieldValue?.(formikField as string, id);
      formikContext?.setFieldValue?.("flagIndividualRequest", true);
      formikContext?.setFieldValue?.("flagBatchRequest", false);
    }

    if (suporteModal) {
      formikContext?.setFieldValue?.(formikField as string, id);
    }

    store.dispatch({ type: "reset/searchAllMangas" });
  };

  return (
    <>
      {search && (
        <div className="pt-5">
          <InputSearch
            disabled={false}
            isLoading={isLoading}
            onSearch={onSearch}
          />
        </div>
      )}

      {isLoading ? (
        <div className="w-full text-center py-8">
          <h2 className="text-gray-400">Loading...</h2>
        </div>
      ) : (
        <div
          className={classNames("gap-y-5 pt-2", {
            "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 ":
              !isModal,
            "grid grid-cols-5 h-[260px] overflow-y-auto": isModal,
            "h-0": data === undefined,
          })}
        >
          {data?.map(({ id, url }) => (
            <ImageCover
              small={isModal}
              key={id}
              isLoading={isLoading}
              src={`https://i.imgur.com/${url}`}
              onClick={() => handleClick(id)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ImageGrid;
