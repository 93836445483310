import { useSelector } from "react-redux";
import { getUserInfo } from "@/store/Auth/selectors";
import { TUserInfo, TUserAdminConfig } from "@/store/Auth/types";
import initialConfig from './initialValues.json'

type TUserInfoHooks = {
  user: TUserInfo
  isAdmin: boolean
  token: string
  config: TUserAdminConfig
}

export const getUserInfoHooks = (): TUserInfoHooks => {
  const { user } = useSelector(getUserInfo);

  const adminConfig = JSON.parse(localStorage.getItem("adminConfig") as string)

  return { 
    user, 
    isAdmin: user?.admin,
    token: user?.token, 
    config: adminConfig ?? initialConfig 
  };
};
