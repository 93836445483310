import { createSlice, createAction } from '@reduxjs/toolkit';
import { caseDefaultRegister }from '@/store/utils';
import * as T from './types';
import * as F from './thunks';

const initialState: T.TCadastroStore = {
	searchAllMangas: null,
	searchAllMangasStatus: 'empty',
	mangaInfo: { id: 0 },
	mangaInfoStatus: 'empty',

	searchAllVolumes: {
		manga: {},
		volumes: [],
		totalVolumes: 0
	},
	searchAllVolumesStatus: 'empty',
	allVolumesReprints: {
		manga: null,
		volumes: [],
		totalVolumes: 0
	},
	allVolumesReprintsStatus: 'empty',
};

const resetSearchAllMangas = createAction('reset/searchAllMangas');

const slice = createSlice({
	name: 'cadastro',
	initialState,
	reducers: {
	},
	extraReducers: ({ addCase }) => {
		addCase(resetSearchAllMangas, (state) => {
			state.searchAllMangas = null;
			state.searchAllMangasStatus = 'empty';
		}),
		caseDefaultRegister({addCase, fetch: F.fetchGetMangaInfo, fields: ['mangaInfoStatus', 'mangaInfo']});
		caseDefaultRegister({addCase, fetch: F.fetchSearchAllMangas, fields: ['searchAllMangasStatus', 'searchAllMangas']});
		caseDefaultRegister({addCase, fetch: F.fetchSearchAllVolumes, fields: ['searchAllVolumesStatus', 'searchAllVolumes']});
		caseDefaultRegister({addCase, fetch: F.fetchAllVolumesReprints, fields: ['allVolumesReprintsStatus', 'allVolumesReprints']});
	},
});

export default slice;
