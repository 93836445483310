import React from 'react';
import { useSelector } from "react-redux";
import { getAllCategorias } from "@/store/Tabelas/selectors";
import { fetchGetAllCategorias } from "@/store/Tabelas/thunks";
import { TStatus, TDefaultItem } from '@/store/types';
import store from '@/store';

export type TAllCategoriasHooks = {
  allCategorias: TDefaultItem[]
  allCategoriasStatus: TStatus
}

export const getAllCategoriasHooks = (): TAllCategoriasHooks => {
  const { allCategorias, allCategoriasStatus } = useSelector(getAllCategorias);
  
  React.useEffect(() => {
    store.dispatch(fetchGetAllCategorias());
  },[]);

  return { allCategorias, allCategoriasStatus };
};
