import Logo from "@/assets/logo_chapado.png";

const Header = () => (
  <div className="flex flex-col items-center">
    <img src={Logo} alt="Meus Mangás logotipo" className="w-40" />
    <p className="text-base text-secondary pt-4">Painel Administrativo</p>
  </div>
);

export default Header;
