import React from "react";
import { Content, Footer, Header } from "@/structures";
import Container from "@/layouts/Container";

type TDefault = {
  debug?: boolean;
  children: JSX.Element | JSX.Element[];
};

const Default = ({ debug = false, children }: TDefault) => {
  return (
    <Container>
      <Header debug={debug} />
      <Content debug={debug}>{children}</Content>
      <Footer />
    </Container>
  );
};

export default Default;
