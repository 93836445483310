import * as yup from "yup";

export type TSchema = {
  id: number;

  mangaId: number;
  preco: number | null;
  paginas: number | null;
  mes: string | null;
  ano: string | null;
  oldValue: string | null;

  numero: number | null;
  quantidade: number | null;
  periodicidade: number | null;
  amazon: string;

  selectedVolumeId: number;
  flagCapaVariante: boolean;
  flagBatchRequest: boolean;
  flagIndividualRequest: boolean;

  image: string;
  userId: number;
  isUpdate: boolean;
};

export const validationSchema = yup.object().shape({
  id: yup.number(),

  mangaId: yup.number().required().min(1),
  preco: yup.number().required().min(1),
  paginas: yup.number().required().min(1),
  mes: yup.string().required().length(2),
  ano: yup.string().required().length(4),
  oldValue: yup.string(),

  numero: yup.number().min(0),
  quantidade: yup.number().min(0),
  periodicidade: yup.number().min(0),
  amazon: yup.string().max(255),

  selectedVolumeId: yup.number(),
  flagCapaVariante: yup.boolean().required(),
  flagBatchRequest: yup.boolean().required(),
  flagIndividualRequest: yup.boolean().required(),

  image: yup.string(),
  userId: yup.number().min(0),
  isUpdate: yup.boolean(),
});
