import classNames from "classnames";

export type TBadgeColor =
  | "gray"
  | "red"
  | "yellow"
  | "green"
  | "blue"
  | "indigo"
  | "purple"
  | "pink"
  | "white";

export type TBadge = {
  text: string;
  color: TBadgeColor;
  onClick?: () => void;
};

const Badge = ({ text, color = "gray", onClick = undefined }: TBadge) => (
  <span
    onClick={onClick}
    className={classNames(
      "inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium cursor-pointer",
      {
        "bg-gray-500 hover:bg-gray-600 text-white": color === "gray",
        "bg-red-500 hover:bg-red-600 text-white": color === "red",
        "bg-yellow-500 hover:bg-yellow-600 text-white": color === "yellow",
        "bg-green-500 hover:bg-green-600 text-white": color === "green",
        "bg-blue-500 hover:bg-blue-600 text-white": color === "blue",
        "bg-indigo-500 hover:bg-indigo-600 text-white": color === "indigo",
        "bg-purple-500 hover:bg-purple-600 text-white": color === "purple",
        "bg-pink-500 hover:bg-pink-600 text-white": color === "pink",
        "bg-white hover:bg-gray-200 text-gray-600": color === "white",
        "cursor-pointer": onClick !== undefined,
      }
    )}
  >
    {text}
  </span>
);

export default Badge;
