import React from "react";
import { useFormikContext } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { getUserInfoHooks } from "@/hooks/Auth";
import { useToast } from "@/hooks";
import store from "@/store";

import { resetSearch, isObjectEmpty } from "@/utils";
import { Button, Modal } from "@/components";
import { fetchDeleteManga } from "@/store/Cadastro/thunks";
import { TSchema } from "./schema";

const Content = ({ isLoading }: { isLoading: boolean }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditing = Number(id) > 0;

  const { token, config } = getUserInfoHooks();
  const { validateForm, handleSubmit, setSubmitting } =
    useFormikContext<TSchema>();

  const [isVisible, setIsVisible] = React.useState(false);

  const handleSave = async () => {
    setSubmitting(true);
    const error = await validateForm();
    if (isObjectEmpty(error)) handleSubmit();
    else
      useToast({
        type: "error",
        msg: "Favor checar os campos obrigatórios.",
        time: 3000,
        showNotification: config.geral.exibirNotificacoes,
      });
  };

  const handleDelete = async () => {
    resetSearch();
    await store.dispatch(fetchDeleteManga({ id: Number(id), token, config }));
    navigate("/manga/visualizar");
  };

  const handleModal = () => {
    config.geral.confirmarAoExcluir ? setIsVisible(true) : handleDelete();
  };

  return (
    <>
      {config.geral.confirmarAoExcluir && (
        <Modal
          title="Tem certeza que gostaria excluir?"
          isCenter
          noChildren
          size="small"
          isVisible={isVisible}
          labels={["Cancelar", "Sair"]}
          onSave={handleDelete}
          onClose={() => setIsVisible(false)}
        />
      )}
      <div className="flex justify-end gap-4 text-right mb-5">
        {isEditing && (
          <Button
            danger
            isLoading={isLoading}
            small
            label="Excluir mangá"
            onClick={handleModal}
          />
        )}
        <Button
          isLoading={isLoading}
          small
          label="Cancelar"
          outline
          onClick={() => navigate("/manga/visualizar")}
        />
        <Button
          isLoading={isLoading}
          small
          label="Salvar"
          onClick={() => handleSave()}
        />
      </div>
    </>
  );
};

export default Content;
