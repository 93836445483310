import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "@/store/Auth/selectors";
import { useScrollTop, useHTTPSprotocol } from "@/hooks";

type TContainer = {
  children: JSX.Element | JSX.Element[];
};

const Container = ({ children }: TContainer) => {
  const navigate = useNavigate();
  const { user } = useSelector(getUserInfo);

  const { isHttps, isProd, siteLocation } = useHTTPSprotocol();

  React.useEffect(() => {
    if (!isHttps && isProd) window.location.href = siteLocation;
  }, []);

  React.useEffect(() => {
    if (user?.id === 0) navigate("/");
  }, [user]);

  React.useEffect(() => {
    useScrollTop();
  }, []);

  return (
    <div className="fixed h-full w-full flex flex-col justify-between overflow-y-auto">
      {children}
    </div>
  );
};

export default Container;
